import '../../settingApi'
import { api } from "../../settingApi";


const VERSION = 'v1'


class AboutUsService {


findAll() {
  return api({
    method: 'get',
    url: `${VERSION}/page/about-us`,
  })
  
}

update(id:number,featuresinfo:boolean,teaminfo:boolean) {
  return api({
    method: 'patch',
    url: `${VERSION}/page/about-us/${id}`,
    data: {
      featuresinfo,
      teaminfo
    }
  })
}

findAllMembers() {
  return api({
    method: 'get',
    url: `${VERSION}/page/about-us/team`,
  })
  
}

getImageMember(id:number) {
  return api({
    method: 'get',
    url: `${VERSION}/page/about-us/team/image/${id}`,
    responseType: 'blob'
  })
  
}


createMember(name:string,surname:string,role:string,facebookinfo:boolean,facebooklink:string,instagraminfo:boolean,instagramlink:string,twitterinfo:boolean,twitterlink:string,linkedininfo:boolean,linkedinlink:string) {
  return api({
    method: 'post',
    url: `${VERSION}/page/about-us/team`,
    data: {
      name,
      surname,
      role,
      facebookinfo,
      facebooklink,
      instagraminfo,
      instagramlink,
      twitterinfo,
      twitterlink,
      linkedininfo,
      linkedinlink
    }
  })
}

createImageMember(image,id) {
  const formData = new FormData();
  formData.append("file", image);

  if(id == undefined){
    id = ''
  }

  return api({
    method: 'post',
    url: `${VERSION}/page/about-us/team/image/${id}`,
    data:formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  
}

updateMember(id:number,name:string,surname:string,role:string,facebookinfo:boolean,facebooklink:string,instagraminfo:boolean,instagramlink:string,twitterinfo:boolean,twitterlink:string,linkedininfo:boolean,linkedinlink:string) {
  return api({
    method: 'patch',
    url: `${VERSION}/page/about-us/team/${id}`,
    data: {
      name,
      surname,
      role,
      facebookinfo,
      facebooklink,
      instagraminfo,
      instagramlink,
      twitterinfo,
      twitterlink,
      linkedininfo,
      linkedinlink
    }
  })
}

updateImageMember(image,id) {
  const formData = new FormData();
  formData.append("file", image);

  if(id == undefined){
    id = ''
  }

  return api({
    method: 'patch',
    url: `${VERSION}/page/about-us/team/image/update/${id}`,
    data:formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  
}

deleteMember(id:number) {
  return api({
    method: 'delete',
    url: `${VERSION}/page/about-us/team/${id}`,
  })
  
}



}



export default new AboutUsService();