import React from "react";
import { Navigate, NavLink, redirect } from 'react-router-dom';
import { Component } from "react";
import MemberImage from "./elements/memberImage";
import { withTranslation, WithTranslation } from "react-i18next";
import aboutusService from "../api/page/aboutus/aboutus.service";

interface Props extends WithTranslation {
    prop: any
}



type State = {
    members: any[],
    settingItems: {
        featuresinfo: boolean,
        teaminfo: boolean
    }
    successful: boolean,
    message: string
};

class AboutUs extends Component<Props, State> {

    constructor(props) {
        super(props);


        this.state = {
            members: [],
            settingItems: {
                featuresinfo: true,
                teaminfo: true
            },
            successful: false,
            message: ""
        };
    }


    getAllSettings = async () => {

        aboutusService.findAll(
        ).then(
            response => {

                if (response.data[0]) {
                    this.setState({
                        settingItems: {
                            featuresinfo: response.data[0].featuresinfo,
                            teaminfo: response.data[0].teaminfo
                        }
                    })
                }

            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

            }

        );
    }

    getAllMembers = async () => {

        aboutusService.findAllMembers(
        ).then(
            response => {


                this.setState({
                    members: response.data,
                })


            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

            }

        );
    }


    componentDidMount() {
        this.getAllMembers()
        this.getAllSettings()
    }


    render() {

        const { successful, message } = this.state;

        const { t } = this.props

        return (

            <>

                <section className="bg-half-170 bg-light d-table w-100">
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-lg-12 text-center">
                                <div className="pages-heading">
                                    <h4 className="title mb-0"> {t('landing.about_us.title_page')} </h4>
                                </div>
                            </div>
                        </div>

                        <div className="position-breadcrumb">

                        </div>
                    </div>
                </section>

                <div className="position-relative">
                    <div className="shape overflow-hidden text-color-white">
                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>



                <section className="section">

                    {this.state.settingItems.featuresinfo ? <div className="container mt-100 mt-60">
                        <div className="row justify-content-center">
                            <div className="col-12 text-center">
                                <div className="section-title mb-4 pb-2">
                                    <h4 className="title mb-4">{t('landing.about_us.first_section.title')}</h4>
                                    <p className="text-muted para-desc mx-auto mb-0">{t('landing.about_us.first_section.description')}</p>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-md-4 col-12 mt-5 pt-4">
                                    <div className="features feature-primary">
                                        <div className="image position-relative d-inline-block">
                                            <i className="uil uil-file-search-alt h2 text-primary"></i>
                                        </div>

                                        <div className="content mt-4">
                                            <h5>{t('landing.about_us.first_section.services.title_first')}</h5>
                                            <p className="text-muted mb-0">{t('landing.about_us.first_section.services.text_first')}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 col-12 mt-5 pt-4">
                                    <div className="features feature-primary">
                                        <div className="image position-relative d-inline-block">
                                            <i className="uil uil-edit-alt h2 text-primary"></i>
                                        </div>

                                        <div className="content mt-4">
                                            <h5>{t('landing.about_us.first_section.services.title_second')}</h5>
                                            <p className="text-muted mb-0">{t('landing.about_us.first_section.services.text_second')}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 col-12 mt-5 pt-4">
                                    <div className="features feature-primary">
                                        <div className="image position-relative d-inline-block">
                                            <i className="uil uil-airplay h2 text-primary"></i>
                                        </div>

                                        <div className="content mt-4">
                                            <h5>{t('landing.about_us.first_section.services.title_third')}</h5>
                                            <p className="text-muted mb-0">{t('landing.about_us.first_section.services.text_third')}</p>
                                        </div>
                                    </div>
                                </div>



                                {/*<div className="col-md-4 col-12 mt-5 pt-4">
                                    <div className="features feature-primary">
                                        <div className="image position-relative d-inline-block">
                                            <i className="uil uil-vector-square h2 text-primary"></i>
                                        </div>

                                        <div className="content mt-4">
                                            <h5>Management & Marketing</h5>
                                            <p className="text-muted mb-0">Allegedly, a Latin scholar established the origin of the text by established compiling unusual word.</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-4 col-12 mt-5 pt-4">
                                    <div className="features feature-primary">
                                        <div className="image position-relative d-inline-block">
                                            <i className="uil uil-calendar-alt h2 text-primary"></i>
                                        </div>

                                        <div className="content mt-4">
                                            <h5>Daily Reports</h5>
                                            <p className="text-muted mb-0">Allegedly, a Latin scholar established the origin of the established text by compiling unusual word.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 col-12 mt-5 pt-4">
                                    <div className="features feature-primary">
                                        <div className="image position-relative d-inline-block">
                                            <i className="uil uil-clock h2 text-primary"></i>
                                        </div>

                                        <div className="content mt-4">
                                            <h5>Real Time Zone</h5>
                                            <p className="text-muted mb-0">It seems that only fragments of the original text remain in only fragments the Lorem Ipsum texts used today.</p>
                                        </div>
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                    </div> : null}
                </section>




                <section className="section ">
                    {this.state.settingItems.teaminfo ?
                        <div className="container">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <div className="section-title mb-4 pb-2">
                                        <h4 className="title mb-4">{t('landing.about_us.second_section.title')}</h4>
                                        <p className="text-muted para-desc mx-auto mb-0"><span className="text-primary fw-bold">{t('landing.about_us.second_section.company_name')}</span> {t('landing.about_us.second_section.sub_title')}</p>
                                    </div>
                                </div>
                            </div>



                            <div className="row align-items-center justify-content-center">

                                {this.state.members.map((item, index) => {
                                    return (
                                        <>
                                            <div className="col-lg-3 col-md-6 mt-4 pt-2" key={index}>
                                                <div className="card team team-primary text-center border-0 row align-items-center justify-content-center">
                                                    <div className="position-relative" style={{ width: '270px' }}>
                                                        <MemberImage id={item.id}></MemberImage>
                                                        <ul className="list-unstyled mb-0 team-icon">
                                                            {item.facebookinfo ?
                                                                <li className="list-inline-item">
                                                                    <a href={item.facebooklink} className="btn btn-primary btn-pills btn-sm btn-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-facebook icons">
                                                                            <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                                                                        </svg>
                                                                    </a>
                                                                </li> : null}
                                                            {item.instagraminfo ?
                                                                <li className="list-inline-item">
                                                                    <a href={item.instagramlink} style={{ marginLeft: '5px' }} className="btn btn-primary btn-pills btn-sm btn-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-instagram icons">
                                                                            <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z">
                                                                            </path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                                                                        </svg>
                                                                    </a>
                                                                </li> : null}
                                                            {item.twitterinfo ?
                                                                <li className="list-inline-item">
                                                                    <a href={item.twitterlink} style={{ marginLeft: '5px' }} className="btn btn-primary btn-pills btn-sm btn-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-twitter icons">
                                                                            <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z">
                                                                            </path>
                                                                        </svg>
                                                                    </a>
                                                                </li> : null}
                                                            {item.linkedininfo ?
                                                                <li className="list-inline-item">
                                                                    <a href={item.linkedinlink} style={{ marginLeft: '5px' }} className="btn btn-primary btn-pills btn-sm btn-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-linkedin icons">
                                                                            <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                                                                            <rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2">
                                                                            </circle>
                                                                        </svg>
                                                                    </a>
                                                                </li> : null}
                                                        </ul>
                                                    </div>
                                                    <div className="content pt-3 pb-3">
                                                        <h5 className="mb-0"><a href="javascript:void(0)" className="name text-dark">{item.name + ' ' + item.surname}</a></h5>
                                                        <small className="designation text-muted">{item.role}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}


                            </div>
                        </div> : null}

                    <div className="container mt-100 mt-60">
                        <div className="row justify-content-center">
                            <div className="col-12 text-center">
                                <div className="section-title">
                                    <h4 className="title mb-4">{t('landing.about_us.second_section.second_title')}</h4>
                                    <p className="text-muted para-desc mx-auto">{t('landing.about_us.second_section.sub_second_title')}</p>

                                    <div className="mt-4">
                                        <NavLink to={'../portfolio'} className="btn btn-primary mt-2 me-2">{t('landing.about_us.second_section.text_button')}</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </>

        )
    }
}

export default withTranslation()(AboutUs)