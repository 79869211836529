import React, { useState } from "react";

const PreviewImage = ({file}) => {
    const [preview, setPreview] = useState<any>()

   
    if(file != undefined){
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () =>{
        setPreview(reader.result)
      }
    }
    

    return (
      <div className="row align-items-center justify-content-center">
        <div className="row align-items-center justify-content-center" style={{width:'350px',height:'300px',overflow:'hidden',margin:'10px',position:'relative'}}>
          <img className="img-fluid rounded " src={preview}  style={{maxWidth:'100%',maxHeight:'100%'}} alt="preview" />
        </div>
      </div>
    )
}

export default PreviewImage  