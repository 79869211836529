import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ProjectImage from "./projectImage";
import { Pagination } from "antd";
import { useTranslation } from 'react-i18next';
import portfolioService from "../../api/page/portfolio/portfolio.service";

const Projects = () => {
    const [projects, setProjects] = useState<any[]>([])
    const [total, setTotal] = useState<any>()
    const [page, setPage] = useState<any>(1)
    const [elementPerPage, setElementPerPage] = useState<any>(9)
    const { t, i18n } = useTranslation();

    useEffect(() => {
        getProjects()
    }, [null]);

    async function getProjects() {

        portfolioService.findAll(
        ).then(
            response => {

                setProjects(response.data)
                setTotal(response.data.length)
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

            }

        );
    }

    const indexOfLastPage = page + elementPerPage - 1
    const indexOfFirstPage = indexOfLastPage - elementPerPage
    const currentElement = projects?.slice(indexOfFirstPage, indexOfLastPage)


    const onShowSizeChange = (current, pageSize) => {
        setElementPerPage(pageSize)
    }


    return (
        <>
            <section className="section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 filters-group-wrap">
                            {/*<div className="filters-group">
                            <ul className="container-filter list-inline mb-0 filter-options text-center">
                                <li className="list-inline-item categories-name border text-dark rounded active" data-group="all">All</li>
                                <li className="list-inline-item categories-name border text-dark rounded" data-group="branding">Branding</li>
                                <li className="list-inline-item categories-name border text-dark rounded" data-group="designing">Designing</li>
                                <li className="list-inline-item categories-name border text-dark rounded" data-group="photography">Photography</li>
                                <li className="list-inline-item categories-name border text-dark rounded" data-group="development">Development</li>
                            </ul>
                        </div>*/}
                        </div>
                    </div>



                    <div id="grid" className="row">

                        {currentElement.length == 0 ? <div style={{height:"300px"}}><p>{t('landing.portfolio.no_projects')}</p></div> :
                            <>
                                {currentElement?.map((item, index) => {
                                    return (
                                        <>
                                            <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2 picture-item" data-groups='["branding"]' key={index}>
                                                <div className="card border-0 work-container work-primary work-classic">
                                                    <div className="card-body p-0">
                                                        <NavLink to={`./project/${item.id}`}>
                                                            {/*<img src={Startup} className="img-fluid rounded work-image" alt=""/>*/}
                                                            <ProjectImage id={item.id} />
                                                        </NavLink>
                                                        <div className="content pt-3">
                                                            <h5 className="mb-1"><NavLink to={`./project/${item.id}`} className="text-dark title">{item.project_name}</NavLink></h5>
                                                            <h6 className="text-muted tag mb-0">{item.category}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                            </>
                        }

                    </div>

                    <div className="row">
                        <div className="col-12 mt-4 pt-2">
                            <ul className="pagination justify-content-center mb-0">
                                <Pagination
                                    onChange={(value) => setPage(value)}
                                    pageSize={elementPerPage}
                                    total={total}
                                    current={page}
                                    showSizeChanger
                                    showQuickJumper
                                    onShowSizeChange={onShowSizeChange}
                                    showTotal={total > 1 ? (total) => ` ${total} ${t('landing.portfolio.multiple_projects')}` : (total) => ` ${total} ${t('landing.portfolio.one_project')}`}
                                />
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>


    )
}

export default Projects