import React, { useEffect, useState } from "react";
import portfolioService from "../../api/page/portfolio/portfolio.service";

const ProjectImage = ({ id }) => {
  const [preview, setPreview] = useState<any>()


  useEffect(() => {
    portfolioService.getImage(
      id
    ).then(
      response => {
  
        const reader = new FileReader()
        reader.readAsDataURL(response.data)
        reader.onload = () => {
          setPreview(reader.result)
        }
  
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
      }
  
    );
  
  }, [id]);



  return (
    <>
      <div className="col" style={{ width: '336px', height: '223px', overflow: 'hidden', margin: '0px', position: 'relative', borderRadius: '6px' }}>
        <img src={preview} className="img-fluid  rounded shadow" style={{ maxWidth: '100%', maxHeight: '100%' }} alt="" />
      </div>
      {/* img src={preview} className="img-fluid rounded work-image" alt=""/>*/}
    </>


  )
}

export default ProjectImage