import '../../settingApi'
import { api } from "../../settingApi";


const VERSION = 'v1'


class FooterService {


  findAll() {
    return api({
      method: 'get',
      url: `${VERSION}/page/footer`,
    })
    
}


update(id:number,facebookinfo:boolean,facebooklink:string,instagraminfo:boolean,instagramlink:string,twitterinfo:boolean,twitterlink:string,linkedininfo:boolean,linkedinlink:string,newsletter:boolean,textbelow:string) {
  return api({
    method: 'patch',
    url: `${VERSION}/page/footer/${id}`,
    data: {
      facebookinfo,
      facebooklink,
      instagraminfo,
      instagramlink,
      twitterinfo,
      twitterlink,
      linkedininfo,
      linkedinlink,
      newsletter,
      textbelow
    }
  })
}




}



export default new FooterService();