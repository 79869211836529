import React from "react";
import jwt_decode from "jwt-decode";
import { Route, Navigate, JsonFunction,redirect,useNavigate } from "react-router-dom";
import TokenService from "../token.service";

 
  function ProtectedUser({ children }) {

    if(TokenService.getAccessToken()){


      if(TokenService.getDataAccessToken().type != "user"){
        return  <Navigate to={"../../login"} replace={true}/>
      }else{
        return children
      }
    
    }
      return  <Navigate to={"../../login"} replace={true}/>
    
    
  }

export default ProtectedUser
