import React from "react";
import jwt_decode from "jwt-decode";
import { Route, Navigate, JsonFunction,redirect,useNavigate } from "react-router-dom";
import TokenService from "../token.service";

 
  function ProtectedAdmin({ children }) {
    

    if(TokenService.getAccessToken()){

      
        if(TokenService.getDataAccessToken().type != "admin"){
         
          return  <Navigate to={"../../login"} replace={true}/>
        }else{
          return children
        }
    }else{
      return  <Navigate to={"../../login"} replace={true}/>
    }
  
  }

export default ProtectedAdmin
