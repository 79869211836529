import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useOutlet } from "react-router-dom";

export const AnimatedOutlet: React.FC = () =>{

    const location = useLocation()
    

    return (
    <>
        <AnimatePresence mode="popLayout" key={location.pathname} >
            <motion.div
             initial={{ opacity: 0}}
             animate={{ opacity: 1, transition:{delay:0.1} }}
             exit={{ opacity: 0, transition:{}}}
           >
            <Outlet/>
         </motion.div>
        </AnimatePresence>
    </>
  )
}