import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Project404Error from "../error/project404";
import portfolioService from "../../api/page/portfolio/portfolio.service";

const InfoProject = ({ }) => {
  const { projectid } = useParams()
  const [project, setProject] = useState<any>([])
  const [preview, setPreview] = useState<any>()
  const [error, setError] = useState<boolean>(false)
  const { t, i18n } = useTranslation();
  const re = /^[0-9\b]+$/;


  useEffect(() => {
    console.log()
    if (projectid?.match(re)) {
      getProject(projectid)
    } else {
      setError(true)
    }
  }, [null]);



  async function getProject(projectid) {

    portfolioService.find(
      projectid
    ).then(
      response => {
        setProject(response.data)
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        if (resMessage == "project-not-found") {
           setError(true)
        }

      }

    );

    portfolioService.getImage(
      projectid
    ).then(
      response => {

        const reader = new FileReader()
        reader.readAsDataURL(response.data)
        reader.onload = () => {
          setPreview(reader.result)
        }

      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

      }

    );


  }

  return (
    <>
      {!error ?
        <div className="row">
          <div className="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div className="sticky-bar">
              <div className="row me-lg-4">
                <div className="col-lg-12">
                  <div className="work-details">
                    <h4 className="title mb-3 border-bottom pb-3">{t('landing.portfolio.project_info.name')}{project.project_name}</h4>
                    <p className="text-muted">
                      {project.description}
                    </p>
                  </div>
                </div>

                <div className="col-lg-7 mt-4 pt-2">
                  <div className="card work-details rounded bg-light border-0">
                    <div className="card-body">
                      <h5 className="card-title border-bottom pb-3 mb-3">{t('landing.portfolio.project_info.text_start_list')}</h5>
                      <dl className="row mb-0">
                        <dt className="col-md-4 col-5">{t('landing.portfolio.project_info.customer')}</dt>
                        <dd className="col-md-8 col-7 text-muted">{project.customer}</dd>

                        <dt className="col-md-4 col-5">{t('landing.portfolio.project_info.category')}</dt>
                        <dd className="col-md-8 col-7 text-muted">{project.category}</dd>

                        <dt className="col-md-4 col-5">{t('landing.portfolio.project_info.date')}</dt>
                        <dd className="col-md-8 col-7 text-muted">{project.date}</dd>

                        {/*<dt className="col-md-4 col-5">{t('landing.portfolio.project_info.website')}</dt>
                      <dd className="col-md-8 col-7 text-muted">{project.website}</dd>*/}

                        {project.locationinfo ? <><dt className="col-md-4 col-5">{t('landing.portfolio.project_info.location')}</dt>
                          <dd className="col-md-8 col-7 text-muted">{project.location}</dd></> : null}
                      </dl>
                    </div>
                  </div>
                  <div className="col-md-12 text-center">
                    <a href={project.website} target="_blank" className="btn btn-primary mt-4 ms-2">{t('landing.portfolio.project_info.go_to_website')}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-5 col-md-6 order-1 order-md-2">
            <div className="port-images sticky-sidebar" style={{ width: '400pxpx', height: '300px', overflow: 'hidden', margin: '0px', position: 'relative', borderRadius: '6px' }}>
              <img src={preview} className="img-fluid mx-auto d-block rounded" style={{ maxWidth: '100%', maxHeight: '100%' }} alt="img" />
            </div>
          </div>
          <div style={{ height: '200px' }}></div>
        </div>
        : <Project404Error/>}
    </>
  )
}

export default InfoProject


