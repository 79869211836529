import axios from "axios";
import jwt_decode from "jwt-decode";
import TokenService from "../token.service";
import '../settingApi'
import { api } from "../settingApi";


const VERSION = 'v1'


class MessageService {



  getAllMessage() {
      return api({
        method: 'get',
        url:`${VERSION}/message`
      })
  }

 


   createMessage(hcaptcha_token:string,name:string,surname:string,email:string,subject:string,body:string) {
    return api({
      method: 'post',
      url: `${VERSION}/message`,
      data: {
          hcaptcha_token,
          name,
          surname,
          email,
          subject,
          body
      }
    })
  }

  deleteMessage(id:number){
    return api({
      method: 'delete',
      url:`${VERSION}/message/${id}`
    })
  }

  deleteMultipleMessage(ids){
    return api({
      method: 'delete',
      url:`${VERSION}/message/multiple`,
      data:{
        ids
      }
    })
  }


}


export default new MessageService();