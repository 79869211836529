import React from "react";
import { Navigate, NavLink, redirect } from "react-router-dom";
import { Component } from "react";
import Loginimg from "../../assets/landing/img/login.svg";
import * as yup from "yup";
import { Field, Formik } from "formik";
import pageService from "../../../api/page/page.service";
import { Input, Form, Skeleton, Switch, TabsProps, Button, Tabs } from "antd";
import contactusService from "../../../api/page/contactus/contactus.service";

type Props = {};

type State = {
  successful: boolean;
  message: string;
  loading:boolean,
  showinfophone: boolean,
  showinfoemail: boolean,
  showinfolocation: boolean,
  showcompanymapslocation: boolean,
  updateId: number,
  phoneinfo: boolean,
  textphone: string,
  phone: string,
  emailinfo: boolean,
  textemail: string,
  email: string,
  companyinfo: boolean,
  textlocation: string,
  location: string,
  companylocationmap: boolean,
  mapslink: string

};

export default class InfoContactUs extends Component<Props, State> {
  constructor(props: Props) {
    super(props);


    this.state = {
      successful: false,
      message: "",
      loading:true,
      showinfophone: false,
      showinfoemail: false,
      showinfolocation: false,
      showcompanymapslocation: false,
      updateId: 1,
      phoneinfo: false,
      textphone: '',
      phone: '',
      emailinfo: false,
      textemail: '',
      email: '',
      companyinfo: false,
      textlocation: '',
      location: '',
      companylocationmap: false,
      mapslink: ''
    };
  }



  renderData = async (data) => {

    const array = JSON.parse(data)
    const item = array[0]


    this.setState({
      phoneinfo: item.phoneinfo,
      textphone: item.textphone,
      phone: item.phone,
      emailinfo: item.emailinfo,
      textemail: item.textemail,
      email: item.email,
      companyinfo: item.companyinfo,
      textlocation: item.textlocation,
      location: item.location,
      companylocationmap: item.companylocationmap,
      mapslink: item.mapslink,
      showinfophone: item.phoneinfo,
      showinfoemail: item.emailinfo,
      showinfolocation: item.companyinfo,
      showcompanymapslocation: item.companylocationmap
    })

  }


  getAllSettings = async () => {

    contactusService.findAll(
    ).then(
      response => {


        this.renderData(JSON.stringify(response.data))

        setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 500);

      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

      }

    );
  }


  updateSettings = async (values) => {


    this.setState({
      message: '',
      successful: false
    })


    contactusService.update(
      this.state.updateId,
      values.phoneinfo,
      values.textphone,
      values.phone,
      values.emailinfo,
      values.textemail,
      values.email,
      values.companyinfo,
      values.textlocation,
      values.location,
      values.companylocationmap,
      values.mapslink
    ).then(
      response => {

        this.setState({
          message: 'Impostazioni salvate',
          successful: true
        })

      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

      }

    );

  }

  componentDidMount() {
    this.getAllSettings()
  }


  render() {
    const { successful, message } = this.state;

    const items: TabsProps["items"] = [
      {
        key: "1",
        label: `Impostazioni`,
        children: (
          <>
            {this.state.loading ? (
              <Skeleton loading={this.state.loading} active></Skeleton>
            ) : (
              <Form
                onFinish={this.updateSettings}
                initialValues={{
                  phoneinfo: this.state.phoneinfo,
                  textphone: this.state.textphone,
                  phone: this.state.phone,
                  emailinfo: this.state.emailinfo,
                  textemail: this.state.textemail,
                  email: this.state.email,
                  companyinfo: this.state.companyinfo,
                  textlocation: this.state.textlocation,
                  location: this.state.location,
                  companylocationmap: this.state.companylocationmap,
                  mapslink: this.state.mapslink
                }}
              >
                {message && (
                  <div className="form-group">
                    <div
                      className={
                        successful
                          ? "alert alert-success alert-dismissible fade show"
                          : "alert alert-danger alert-dismissible fade show "
                      }
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close btn-close-white"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                      <strong>
                        {successful ? "Successo - " : "Errore - "}
                      </strong>

                      {message}
                    </div>
                  </div>
                )}
                <div className="mb-3">
                  <Form.Item name="phoneinfo" label="Info telefono">
                    <Switch
                      checked={this.state.phoneinfo}
                      onChange={() => {
                        this.setState({
                          phoneinfo: !this.state.phoneinfo,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                {this.state.phoneinfo ? (
                  <>
                    <div className="mb-3" style={{ width: "300px" }}>
                      <label>Testo telefono</label>
                      <Form.Item
                        name="textphone"
                        rules={[
                          {
                            required: true,
                            message: "Inserisci un testo",
                          },
                        ]}
                        hasFeedback
                      >
                        <Input placeholder="Inserisci un testo" />
                      </Form.Item>
                    </div>
                    <div className="mb-3" style={{ width: "300px" }}>
                      <label>Telefono</label>
                      <Form.Item
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: "Inserisci un numero di telefono",
                            pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
                          },
                        ]}
                        hasFeedback
                      >
                        <Input placeholder="Inserisci un numero di telefono" />
                      </Form.Item>
                    </div>
                  </>
                ) : null}
                <div className="mb-3">
                  <Form.Item name="emailinfo" label="Info email">
                    <Switch
                      checked={this.state.emailinfo}
                      onChange={() => {
                        this.setState({
                          emailinfo: !this.state.emailinfo,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                {this.state.emailinfo ? (
                  <>
                    <div className="mb-3" style={{ width: "300px" }}>
                      <label>Testo email</label>
                      <Form.Item
                        name="textemail"
                        rules={[
                          {
                            required: true,
                            message: "Inserisci un testo",
                          },
                        ]}
                        hasFeedback
                      >
                        <Input placeholder="Inserisci un testo" />
                      </Form.Item>
                    </div>
                    <div className="mb-3" style={{ width: "300px" }}>
                      <label>email</label>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Inserisci un'email",
                            pattern: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
                          },
                        ]}
                        hasFeedback
                      >
                        <Input placeholder="Inserisci u'email" />
                      </Form.Item>
                    </div>
                  </>
                ) : null}
                <div className="mb-3">
                  <Form.Item name="companyinfo" label="Info azienda">
                    <Switch
                      checked={this.state.companyinfo}
                      onChange={() => {
                        this.setState({
                          companyinfo: !this.state.companyinfo,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                {this.state.companyinfo ? (
                  <>
                    <div className="mb-3" style={{ width: "300px" }}>
                      <label>testo locazione</label>
                      <Form.Item
                        name="textlocation"
                        rules={[
                          {
                            required: true,
                            message: "Inserisci un testo",
                          },
                        ]}
                        hasFeedback
                      >
                        <Input placeholder="Inserisci un testo" />
                      </Form.Item>
                    </div>
                    <div className="mb-3" style={{ width: "300px" }}>
                      <label>Locazione</label>
                      <Form.Item
                        name="location"
                        rules={[
                          {
                            required: true,
                            message: "Inserisci una locazione",
                          },
                        ]}
                        hasFeedback
                      >
                        <Input placeholder="Inserisci una locazione" />
                      </Form.Item>
                    </div>
                  </>
                ) : null}
                <div className="mb-3">
                  <Form.Item name="companylocationmap" label="Abilita mappa locazione">
                    <Switch
                      checked={this.state.companylocationmap}
                      onChange={() => {
                        this.setState({
                          companylocationmap: !this.state.companylocationmap,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                {this.state.companylocationmap ? (
                  <div className="mb-3" style={{ width: "300px" }}>
                    <label>Link Google maps</label>
                    <Form.Item
                      name="mapslink"
                      rules={[
                        {
                          required: true,
                          message: "Inserisci un link",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="Inserisci un link" />
                    </Form.Item>
                  </div>
                ) : null}
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginTop: "30px" }}
                  >
                    Salva
                  </Button>
                </Form.Item>
              </Form>
            )}
          </>
        ),
      },
    ];

    return (
      <>

        <div className="container-fluid">
          <div className="layout-specing">
            <div className="d-md-flex justify-content-between align-items-center">
              <h5 className="mb-0">Info Contattaci</h5>

              <nav aria-label="breadcrumb" className="d-inline-block mt-2 mt-sm-0">
                <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                  <li className="breadcrumb-item text-capitalize"><a href="index.html">Dashboard</a></li>
                  <li className="breadcrumb-item text-capitalize"><a href="#">Contenuto pagine</a></li>
                  <li className="breadcrumb-item text-capitalize active" aria-current="page">Info Contattaci</li>
                </ul>
              </nav>
            </div>

            <div className="row">
              <Tabs defaultActiveKey="1" items={items} />
            </div>
          </div>
        </div>
      </>
    );
  }
}
