import axios from "axios";
import TokenService from "./token.service"

const VERSION = 'v1'


export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}` ,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true
});


 const refreshAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}${VERSION}` ,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true
});

function callRefresh(){
  refreshAxios({
    method: 'get',
    url: `auth/refresh`,
    headers:{
      'Authorization': `Bearer ${TokenService.getRefreshToken()}`
    }
    
  }).then(
    response =>{
      if (response.data.accessToken) {
        TokenService.setAccessToken(response.data.accessToken)
        TokenService.setRefreshToken(response.data.refreshToken)
        
        return response.data
      }
    },
    error =>{

    }
  )
}
 
 api.interceptors.request.use( 

  async (config)=> {


    var pass = false


     if(TokenService.getAccessToken()){

        if(Date.now() > TokenService.getDataAccessToken().exp * 1000 || pass){
          pass = true
          callRefresh()
        }else{
          config.headers.Authorization = `Bearer ${TokenService.getAccessToken()}`
        }
        
        
     }
    
    return config
  },
  async error => {
    return Promise.reject(error);
 })

 api.interceptors.response.use(
  async res =>{ return res},
  async error =>{

    const config = error?.config;
    const originalReq = error.config

    if(error.response.data.statusCode === 401 && !config?.sent){
      
      config.sent = true;
      
      refreshAxios({
        method: 'get',
        url: "auth/refresh",
        headers:{
          'Authorization': `Bearer ${TokenService.getRefreshToken()}`
        }
        
      }).then(
        response =>{
          if (response.data.accessToken) {
            TokenService.setAccessToken(response.data.accessToken)
            TokenService.setRefreshToken(response.data.refreshToken)
            
            return api(originalReq)
          
          }
        },
        error =>{
  
        }
        
      )
     
    }

    return Promise.reject(error);
  }
 )

   