import axios from "axios";
import jwt_decode from "jwt-decode";
import TokenService from "../token.service";
import '../settingApi'
import { api } from "../settingApi";


const VERSION = 'v1'


class AuthService {



  /*login(user: string, password: string) {
    return axios
      .post(API_URL + "auth/signin", {
        user,
        password
      })
      .then(response => {
        if (response.data.accessToken || response.data.refreshToken) {
          TokenService.setAccessToken(response.data.accessToken)
          TokenService.setRefreshToken(response.data.refreshToken)
        }

        return response.data;
      });
  }*/

  login(email: string, password: string) {
      return api({
        method: 'post',
        url:   `${VERSION}/auth/signin`,
        data: {
            email,
            password
        }
      })
  }

 


   register(nome:string,cognome:string,user: string,email:string,password: string,cpassword:string) {
    return api({
      method: 'post',
      url: `${VERSION}/auth/signup`,
      data: {
          nome,
          cognome,
          user,
          email,
          password,
          cpassword
      }
    })
  }


  logout() {
    return api({
      method: 'get',
      url: `${VERSION}/auth/signout`,
    })
    
  }


  sendEmail(email:string) {
    return api({
       method: 'post',
       url: `${VERSION}/auth/send`,
       data: {
        email
       }
     })
   }


  confirmOtp(otp:string) {
    return api({
       method: 'post',
       url: `${VERSION}/auth/otp`,
       data: {
        otp
       }
     })
   }

  
 resetPassword(password:string,cpassword:string) {
    return api({
       method: 'post',
       url: `${VERSION}/auth/reset`,
       data: {
        password,
        cpassword
    }
     })
   }

 changePassword(oldpassword:string,password:string,cpassword:string) {
    return api({
       method: 'post',
       url: `${VERSION}/auth/change`,
       data: {
        oldpassword,
        password,
        cpassword
    }
     })
   }

   deleteAccount() {
    return api({
       method: 'delete',
       url: `${VERSION}/auth/`,
     })
   }

}



export default new AuthService();