import React from "react";
import { Navigate, NavLink, redirect } from "react-router-dom";
import { Component } from "react";
import Loginimg from "../../assets/landing/img/login.svg";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import authService from "../../api/auth/auth.service";
import TokenService from "../../api/token.service";
import {
  ColumnsType,
  ColumnType,
  FilterConfirmProps,
  SorterResult,
  TableRowSelection,
} from "antd/es/table/interface";
import { Button, Input, InputRef, Space, Table, TableProps } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import SkeletonTable, {
  SkeletonTableColumnsType,
} from "../common/dashboard/animation/skeletonTable";
import messageService from "../../api/message/message.service";
import moment from "moment";
import { Modal } from "react-bootstrap";

type Props = {};

type State = {
  loading:boolean,
  confirm: boolean;
  successful: boolean;
  message: string;
  messages: any[],
  deleteId: number
  modalDelete:boolean,
  modalMultipleMessage:boolean,
  messageSearch: string;
  showButtonDelete:boolean,
  searchText: string;
  searchColumn: string;
  searchTimeText;
  searchTimeRange;
  searchTimeRangeColumn: string;
  selectedRowKeys: React.Key[];
  filteredInfo;
  sortedInfo;
  columnIndex;
};

export default class Messages extends Component<Props, State> {
  private searchInput = React.createRef<InputRef>();

  constructor(props: Props) {
    super(props);

    this.state = {
      loading:true,
      confirm: false,
      successful: false,
      message: "",
      messages: [],
      deleteId: 0,
      modalDelete:false,
      modalMultipleMessage:false,
      messageSearch: "",
      showButtonDelete:false,
      searchText: "",
      searchColumn: "",
      searchTimeText: null,
      searchTimeRange: null,
      searchTimeRangeColumn: "",
      selectedRowKeys: [],
      filteredInfo: null,
      sortedInfo: null,
      columnIndex: null,
    };
  }


  

  validationSchema() {
    return yup.object().shape({
     
    });
  }

  setDeleteId = (id) =>{
    this.setState({
      deleteId:id
    })
  }

  setModalDelete = (state) =>{
    this.setState({
      modalDelete:state
    })
  }

  setModalMultiple = (state) =>{
    this.setState({
      modalMultipleMessage:state
    })
  }

  renderData = (messages) => {
    const messagesState = messages.map((obj) => {
      return { key: obj.id, ...obj };
    });

    this.setState({
      messages: messagesState,
    });
  };

  getAllMessages = async() =>{

    messageService.getAllMessage(
    ).then(
      response => {

        this.renderData(response.data)
        
          setTimeout(() => {
            this.setState({
              loading: false,
            });
          }, 500);
        
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        
    }

    );
  }

  deleteMessage = async() =>{

    messageService.deleteMessage(
        this.state.deleteId
      ).then(
        response => {
          
           this.setModalDelete(false)
           this.getAllMessages()

         
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
          
      }
  
      );
  }

  deleteMultipleMessage = async() =>{

    messageService.deleteMultipleMessage(
      this.state.selectedRowKeys
    ).then(
      response => {
        
         this.setModalMultiple(false)
         this.setState({
          showButtonDelete:false
         })
         this.getAllMessages()

       
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        
    }

    );
  }

  componentDidMount(){
    this.getAllMessages()
  }

  render() {
    const {
      successful,
      message,
      messageSearch,
      selectedRowKeys,
      filteredInfo,
      sortedInfo,
    } = this.state;

    const initialValues = {
     
    };

    interface DataType {
      key: string;
      id: number;
      name: string;
      surname: string;
      email:string,
      subject: string;
      body: string;
      create_date: string,
      messages: any[];
    }

    type DataIndex = keyof DataType;

    const handleChange: TableProps<DataType>["onChange"] = (
      pagination,
      filters,
      sorter
    ) => {
      //console.log('Various parameters', pagination, filters, sorter)
      this.setState({
        filteredInfo: filters,
        sortedInfo: sorter as SorterResult<DataType>,
      });
    };

    const clearAll = () => {
      this.setState({ filteredInfo: {}, sortedInfo: {} });
    };

    const handleSearch = (
      selectedKeys: string[],
      confirm: (param?: FilterConfirmProps) => void,
      dataIndex: DataIndex
    ) => {
      confirm();
      this.setState({ searchText: selectedKeys[0], searchColumn: dataIndex });
    };

    const handleReset = (clearFilters: () => void) => {
      clearFilters();
      this.setState({ searchText: "" });
    };

    const getColumnSearchPropsBody = (dataIndex): ColumnType<DataType> => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={this.searchInput}
            placeholder={`Cerca ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Cerca
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Resetta
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                this.setState({
                  searchText: (selectedKeys as string[])[0],
                  searchColumn: dataIndex,
                });
              }}
            >
              Filtra
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              Chiudi
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filteredValue: filteredInfo != null ? filteredInfo.body || null : null,
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => this.searchInput.current?.select(), 100);
        }
      },
      sortOrder:
        sortedInfo != null
          ? sortedInfo.columnKey === dataIndex
            ? sortedInfo.order
            : null
          : null,
      render: (text) =>
        this.state.searchColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    });

    const getColumnSearchPropsSubject = (dataIndex): ColumnType<DataType> => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={this.searchInput}
            placeholder={`Cerca ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Cerca
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Resetta
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                this.setState({
                  searchText: (selectedKeys as string[])[0],
                  searchColumn: dataIndex,
                });
              }}
            >
              Filtra
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              Chiudi
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filteredValue: filteredInfo != null ? filteredInfo.subject || null : null,
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => this.searchInput.current?.select(), 100);
        }
      },
      sortOrder:
        sortedInfo != null
          ? sortedInfo.columnKey === dataIndex
            ? sortedInfo.order
            : null
          : null,
      render: (text) =>
        this.state.searchColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    });

    
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
      //console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      this.setState({ selectedRowKeys: newSelectedRowKeys });
        if (newSelectedRowKeys.length == 0) {
          this.setState({ showButtonDelete: false });
        } else {
          this.setState({ showButtonDelete: true });
        }
    };

    const rowSelection: TableRowSelection<DataType> = {
      selectedRowKeys,
      onChange: onSelectChange,
      selections: [
        Table.SELECTION_ALL,
        Table.SELECTION_INVERT,
        Table.SELECTION_NONE,
      ],
    };

    const columns: ColumnsType<DataType> = [
      {
        key: "name",
        title: "Nome",
        dataIndex: "name",
        align: "center",
        responsive: ["md", "sm", "xs"],
        sorter: (a, b) => a.name.length - b.name.length,
        sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "name"  ? sortedInfo.order : null : null,
      },
      {
        key:"surname",
        title: "Cognome",
        dataIndex: "surname",
        align: "center",
        responsive: ["md", "sm", "xs"],
        sorter: (a, b) => a.surname.length - b.surname.length,
        sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "surname"  ? sortedInfo.order : null : null,
      },
      {
        key:"email",
        title: "Email",
        dataIndex: "email",
        align: "center",
        responsive: ["md", "sm", "xs"],
        sorter: (a, b) => a.email.length - b.email.length,
        sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "email"  ? sortedInfo.order : null : null,
      },
      {
        key:"subject",
        title: "Oggetto",
        dataIndex: "subject",
        align: "center",
        responsive: ["md", "sm", "xs"],
        ...getColumnSearchPropsSubject("subject"),
      },
      {
        key:"body",
        title: "Corpo",
        dataIndex: "body",
        align: "center",
        responsive: ["md", "sm", "xs"],
        ...getColumnSearchPropsBody("body"),
      },
      {
        key:"create_date",
        title: "Creato il",
        dataIndex: "create_date",
        align: "center",
        responsive: ["md", "sm", "xs"],
        sorter: (a, b) => moment(a.create_date).unix() - moment(b.create_date).unix(),
        sortOrder:  sortedInfo != null ? sortedInfo.columnKey === "create_date"  ? sortedInfo.order  : null  : null,
        
      },
      {
        title: "Azione",
        dataIndex: "azione",
        align: "center",
        responsive: ["md", "sm", "xs"],
        render: (_, record) => {
          return <>
          <Button
          onClick={() => [
            this.setModalDelete(true),
            this.setDeleteId(record.id),
          ]}
          danger
          type="primary"
          style={{
            width: "40px",
            height: "40px",
            alignContent: "center",
          }}
          icon={<DeleteOutlined />}
        /></>;
        },
      },
    ];

    return (
      <>
        <div className="container-fluid">
          <div className="layout-specing">
            <div className="d-md-flex justify-content-between align-items-center">
              <h5 className="mb-0">Messaggi</h5>

              <nav aria-label="breadcrumb" className="d-inline-block">
                <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                  <li className="breadcrumb-item text-capitalize">
                    <a href="index.html">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item text-capitalize">
                    <a href="#">Messaggi</a>
                  </li>
                  <li
                    className="breadcrumb-item text-capitalize active"
                    aria-current="page"
                  >
                    lista
                  </li>
                </ul>
              </nav>
            </div>

            <div className="row">
              <div className="col-12 mt-4"></div>
            </div>

            <div className="row text-center">

            <Space style={{ marginBottom: 16 }}>
              <div className="mobile-sm">
                <Button onClick={clearAll}>
                  Resetta ordinamenti,filtri e ricerca
                </Button>
              </div>
              <div className="mobile-sm">
              {this.state.showButtonDelete ? (
                <Button
                  type="primary"
                  onClick={() => this.setModalMultiple(true)}
                  danger
                >
                  Elimina messaggi selezionati
                </Button>
              ) : null}
              </div>
             
          </Space>
              <SkeletonTable
                loading={this.state.loading}
                columns={columns as SkeletonTableColumnsType[]}
              >
                <Table
                  columns={columns}
                  dataSource={this.state.messages}
                  onChange={handleChange}
                  rowKey={(record) => record.id}
                  rowSelection={rowSelection}
                  pagination={{
                    pageSize: 10,
                  }}
                  scroll={{ x: 600 }}
                />
              </SkeletonTable>
            </div>

            <Modal
             show={this.state.modalDelete}
             onHide={() => this.setModalDelete(false)}
           >
          <Modal.Header
            className="modal-header modal-colored-header bg-danger text-white"
            closeButton
          >
            <h5 className="modal-title" id="danger-header-modalLabel">
              Elimina messaggio
            </h5>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <p>Sei sicuro di voler eliminare questo messaggio?</p>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <Button onClick={() => this.setModalDelete(false)}>Annulla</Button>
            <Button danger onClick={() => this.deleteMessage()}>
              Elimina messaggio
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
             show={this.state.modalMultipleMessage}
             onHide={() => this.setModalMultiple(false)}
           >
          <Modal.Header
            className="modal-header modal-colored-header bg-danger text-white"
            closeButton
          >
            <h5 className="modal-title" id="danger-header-modalLabel">
              Elimina messaggi selezionati
            </h5>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <p>Sei sicuro di voler eliminare i messaggi selezionati?</p>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <Button onClick={() => this.setModalMultiple(false)}>Annulla</Button>
            <Button danger onClick={() => this.deleteMultipleMessage()}>
              Elimina messaggi
            </Button>
          </Modal.Footer>
        </Modal>
          </div>
        </div>
      </>
    );
  }
}
