import '../../settingApi'
import { api } from "../../settingApi";


const VERSION = 'v1'


class HomeService {


  findAll() {
    return api({
      method: 'get',
      url: `${VERSION}/page/home`,
    })
    
  }
  
  update(id:number,frameworksinfo:boolean) {
    return api({
      method: 'patch',
      url: `${VERSION}/page/home/${id}`,
      data: {
        frameworksinfo
      }
    })
  }

  findAllFramework() {
    return api({
      method: 'get',
      url: `${VERSION}/page/home/framework`,
    })
    
  }
  
  getImageFramework(id:number) {
    return api({
      method: 'get',
      url: `${VERSION}/page/home/framework/image/${id}`,
      responseType: 'blob'
    })
    
  }
  
  
  createFramework(name:string,language:string,linkdocsinfo:boolean,linkdocs:string) {
    return api({
      method: 'post',
      url: `${VERSION}/page/home/framework`,
      data: {
        name,
        language,
        linkdocsinfo,
        linkdocs
      }
    })
  }


  createImageFramework(image,id) {
    const formData = new FormData();
    formData.append("file", image);
  
    if(id == undefined){
      id = ''
    }
  
    return api({
      method: 'post',
      url: `${VERSION}/page/home/framework/image/${id}`,
      data:formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    
  }
  
  updateFramework(id:number,name:string,language:string,linkdocsinfo:boolean,linkdocs:string) {
    return api({
      method: 'patch',
      url: `${VERSION}/page/home/framework/${id}`,
      data: {
        name,
        language,
        linkdocsinfo,
        linkdocs
      }
    })
  }
  
  updateImageFramework(image,id) {
    const formData = new FormData();
    formData.append("file", image);
  
    if(id == undefined){
      id = ''
    }
  
    return api({
      method: 'patch',
      url: `${VERSION}/page/home/framework/image/update/${id}`,
      data:formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    
  }
  
  deleteFramework(id:number) {
    return api({
      method: 'delete',
      url: `${VERSION}/page/home/framework/${id}`,
    })
    
  }



}



export default new HomeService();