import React from 'react';
import { Counter } from './features/counter/Counter';
import { createBrowserRouter, useRoutes } from 'react-router-dom';
import AppLayout from './components/common/appLayout';
import Index from './components';
import ContactUs from './components/contactUs';
import Login from './components/authenticate/login';
import DashboardLayout from './components/common/dashboard/dashboardLayout';
import Protected from './api/auth/protectedUser';
import BypassLogin from './api/auth/bypass.login';
import ForgotPassword from './components/authenticate/forgotPassword';
import VerifyOtp from './components/authenticate/verifyOtp';
import ProtectResetPassword from './api/auth/protectResetpassword';
import ResetPassword from './components/authenticate/resetPassword';
import AboutUs from './components/aboutUs';
import ConfirmAccount from './components/authenticate/confirmAccount';
import Messages from './components/admin/messages';
import ProtectedAdmin from './api/auth/protectedAdmin';
import Void from './components/common/dashboard/void';
import ProtectedEmployee from './api/auth/protectedEmployee';
import ProtectedUser from './api/auth/protectedUser';
import './assets/dashboard/css/modals.css'
import InfoContactUs from './components/admin/pages/infoContactUs';
import InfoFooter from './components/admin/pages/infoFooter';
import Profile from './components/user/profile';
import InfoAboutUs from './components/admin/pages/infoAboutUs';
import Portfolio from './components/portfolio';
import InfoPortfolio from './components/admin/pages/portfolio';
import InfoProject from './components/infoProject';
import PrivacyPolicy from './components/utility/privacyPolicy';
import TermsOfSrvices from './components/utility/termsOfServices';
import InfoHome from './components/admin/pages/infoHome';
import Landing404error from './components/error/landing404';
import Dashboard404error from './components/error/dashboard404';
import Redirect from './components/common/dashboard/authorization/redirect';
import Users from './components/admin/users';



  const element = createBrowserRouter([
    {
      path: "*",
      element: <Landing404error/>,
    },
    {
      path: "/login",
      element: <BypassLogin><Login/></BypassLogin>
      
    },
    {
      path: "/confirm-account",
      element: <ConfirmAccount/>
    },
    {
      path: "/forgot",
      element: <ForgotPassword/>
    },
    {
     path: "/verify",
     element: <VerifyOtp/>
    },
    {
     path: "/reset-password",
     element: <ProtectResetPassword><ResetPassword/></ProtectResetPassword>
    },
    {
      path: "/",
      element:<AppLayout/>,
      children: [
        {
          index:true,
          path: "",
          element: <Index/>,
          
        },
        {
          path: "contact-us",
          element: <ContactUs/>,
        },
        {
          path: "about-us",
          element: <AboutUs/>
        },
        {
          path: "portfolio",
          element: <Portfolio/>,
        },
        {
          path:  "portfolio/project/:projectid",
          element: <InfoProject/>
        },
        {
          path: "terms-of-services",
          element: <TermsOfSrvices/>,
        },
        {
          path: "privacy-policy",
          element: <PrivacyPolicy/>,
        },
      ],
    },
    {
      path: "/dashboard",
      element: <Redirect><DashboardLayout/></Redirect>,
      children: [
        {
          path: "*",
          element: <Dashboard404error/>,
        },
        {
          path:  "admin",
          element: <ProtectedAdmin><Void/></ProtectedAdmin>,
          children: [
            {
              path:  "messages",
              element: <Messages/>
            },
            {
              path:  "users",
              element: <Users/>
            },
            {
              path:  "info-home",
              element: <InfoHome/>
            },
            {
              path:  "info-contact-us",
              element: <InfoContactUs/>
            },
            {
              path:  "info-about-us",
              element: <InfoAboutUs/>
            },
            {
              path:  "info-portfolio",
              element: <InfoPortfolio/>
            },
            {
              path:  "info-footer",
              element: <InfoFooter/>
            },
            {
              path:  "profile",
              element: <Profile/>
            }
          ]
        },
        {
          path:  "employee",
          element: <ProtectedEmployee><Void/></ProtectedEmployee>,
          children: [
            {
              path:  "profile",
              element: <Profile/>
            }
          ]
        },
        {
          path:  "user",
          element: <ProtectedUser><Void/></ProtectedUser>,
          children: [
            {
              path:  "profile",
              element: <Profile/>
            }
          ]
        },
      ],
    },
    
  ])

   export default element


