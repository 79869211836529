import React from "react";
import { Component } from "react";
import Contactimg from "../assets/landing/img/contact.svg";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import messageService from "../api/message/message.service";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { WithTranslation, withTranslation } from "react-i18next";
import contactusService from "../api/page/contactus/contactus.service";

interface Props extends WithTranslation {
  prop: any
}



type State = {
  successful: boolean;
  message: string;
  showphone: boolean,
  showemail: boolean,
  showlocation: boolean,
  showmap: boolean,
  showRecaptcha: boolean,
  sitekey: string,
  info: {
    textphone: string,
    textemail: string,
    textlocation: string,
    phone: string,
    email: string,
    location: string,
    maplink: string
  }

};

class ContactUs extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      successful: false,
      message: "",
      showphone: false,
      showemail: false,
      showlocation: false,
      showmap: false,
      showRecaptcha: false,
      sitekey: '',
      info: {
        textphone: '',
        textemail: '',
        textlocation: '',
        phone: '',
        email: '',
        location: '',
        maplink: ''
      }

    };
  }

  validationSchema() {
    const emailRules = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
    return yup.object().shape({
      hcaptcha: yup.string().required("questo campo è obbligatorio"),
      name: yup.string().required("inserisci un nome"),
      surname: yup.string().required("inserisci un cognome"),
      email: yup.string().required("inserisci una email").matches(emailRules, "Inserisci una email valida"),
      subject: yup.string().required("Inserisci un oggetto"),
      body: yup.string().required("Inserisci un corpo messaggio"),
    });
  }


  renderData = async (data) => {

    const array = JSON.parse(data)
    const item = array[0]

    this.setState({
      info: {
        textphone: item.textphone,
        textemail: item.textemail,
        textlocation: item.location,
        phone: item.phone,
        email: item.email,
        location: item.location,
        maplink: item.mapslink
      },
      showphone: item.phoneinfo,
      showemail: item.emailinfo,
      showlocation: item.companyinfo,
      showmap: item.companylocationmap
    })
  }

  getAllSettings = async () => {

    contactusService.findAll(
    ).then(
      response => {

        if (response.data[0]) {
          this.renderData(JSON.stringify(response.data))
        }

      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

      }

    );
  }

  createMessage = async (formValue: { hcaptcha: string, name: string, surname: string, email: string, subject: string, body: string }) => {
    const { hcaptcha, name, surname, email, subject, body } = formValue

    this.setState({
      message: "",
      successful: false,
    })

    messageService.createMessage(
      hcaptcha,
      name,
      surname,
      email,
      subject,
      body
    ).then(
      response => {

        this.setState({
          message: "Messaggio inviato con successo",
          successful: true,
        })

      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();


      }

    );

  };

  componentDidMount() {
    this.getAllSettings()
    setTimeout(() => {
      this.setState({ showRecaptcha: true })
    }, 0);
    this.setState({ sitekey: `${process.env.REACT_APP_HCAPTCHA_TOKEN}` })

  }

  render() {
    const { successful, message } = this.state;

    const initialValues = {
      hcaptcha: "",
      name: "",
      surname: "",
      email: "",
      subject: "",
      body: "",
    };

    const { t } = this.props

    return (
      <>
        <section className="bg-half-170 bg-light d-table w-100">
          <div className="container">
            <div className="row mt-5 justify-content-center">
              <div className="col-lg-12 text-center">
                <div className="pages-heading">
                  <h4 className="title mb-0">{t('landing.contact_us.title_page')}</h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="position-relative">
          <div className="shape overflow-hidden text-color-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>

        <section className="section pb-0">
          <div className="container">
            <div className="row">
              {this.state.showphone ? <><div className="col-md-4">
                <div className="card border-0 text-center features feature-primary feature-clean">
                  <div className="icons text-center mx-auto">
                    <i className="uil uil-phone d-block rounded h3 mb-0"></i>
                  </div>
                  <div className="content mt-4">
                    <h5 className="fw-bold">{t('landing.contact_us.info.phone')}</h5>
                    <p className="text-muted">
                      {this.state.info.textphone}
                    </p>
                    <a href={`tel:+${this.state.info.phone}`} className="read-more">
                      {this.state.info.phone}
                    </a>
                  </div>
                </div>
              </div></> : null}

              {this.state.showemail ? <><div className="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div className="card border-0 text-center features feature-primary feature-clean">
                  <div className="icons text-center mx-auto">
                    <i className="uil uil-envelope d-block rounded h3 mb-0"></i>
                  </div>
                  <div className="content mt-4">
                    <h5 className="fw-bold">{t('landing.contact_us.info.email')}</h5>
                    <p className="text-muted">
                      {this.state.info.textemail}
                    </p>
                    <a href={`mailto:${this.state.info.email}`} className="read-more">
                      {this.state.info.email}
                    </a>
                  </div>
                </div>
              </div></> : null}

              {this.state.showlocation ? <><div className="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div className="card border-0 text-center features feature-primary feature-clean">
                  <div className="icons text-center mx-auto">
                    <i className="uil uil-map-marker d-block rounded h3 mb-0"></i>
                  </div>
                  <div className="content mt-4">
                    <h5 className="fw-bold">{t('landing.contact_us.info.position')}</h5>
                    <p className="text-muted">
                      {this.state.info.textlocation}
                    </p>
                    <a
                      href={this.state.info.location}
                      data-type="iframe"
                      className="video-play-icon read-more lightbox"
                    >
                      {t('landing.contact_us.info.button_position')}
                    </a>
                  </div>
                </div>
              </div></> : null}
            </div>
          </div>

          <div className="container mt-100 mt-60">
            <div className="row align-items-center">
              <div className="col-lg-5 col-md-6 pt-2 pt-sm-0 order-2 order-md-1">
                <div className="card shadow rounded border-0">
                  <div className="card-body py-5">
                    <h4 className="card-title">{t('landing.contact_us.form.title')}</h4>
                    {message && (
                      <div className="form-group">
                        <div
                          className={
                            successful
                              ? "alert alert-success alert-dismissible fade show"
                              : "alert alert-danger alert-dismissible fade show "
                          }
                          role="alert"
                        >
                          <button
                            type="button"
                            className="btn-close btn-close-white"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                          ></button>
                          <strong>{
                            successful
                              ? "Successo - "
                              : "Errore - "
                          }</strong>

                          {message}
                        </div>
                      </div>
                    )}
                    <div className="custom-form mt-3">
                      <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={this.validationSchema}
                        onSubmit={this.createMessage}
                      >
                        {(formik) => (
                          <Form>
                            <p id="error-msg" className="mb-0"></p>
                            <div id="simple-msg"></div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    {t('landing.contact_us.form.input_name')}{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="name"
                                      type="text"
                                      placeholder={t('landing.contact_us.form.placeholder_name')}
                                      className={(formik.touched.name && formik.errors.name) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                      <div className="invalid-feedback">{formik.errors.name}</div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    {t('landing.contact_us.form.input_surname')}{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="surname"
                                      type="text"
                                      placeholder={t('landing.contact_us.form.placeholder_surname')}
                                      className={(formik.touched.surname && formik.errors.surname) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'}
                                    />
                                    {formik.touched.surname && formik.errors.surname ? (
                                      <div className="invalid-feedback">{formik.errors.surname}</div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="col-12">
                                <div className="mb-3">
                                  <label className="form-label">
                                    {t('landing.contact_us.form.input_email')}{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-mail fea icon-sm icons"
                                    >
                                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                      <polyline points="22,6 12,13 2,6"></polyline>
                                    </svg>
                                    <Field
                                      name="email"
                                      type="email"
                                      placeholder={t('landing.contact_us.form.placeholder_email')}
                                      className={(formik.touched.email && formik.errors.email) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'}
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                      <div className="invalid-feedback">{formik.errors.email}</div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="col-12">
                                <div className="mb-3">
                                  <label className="form-label">{t('landing.contact_us.form.input_title')}</label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-book fea icon-sm icons"
                                    >
                                      <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path>
                                      <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path>
                                    </svg>
                                    <Field
                                      name="subject"
                                      type="text"
                                      placeholder={t('landing.contact_us.form.placeholder_title')}
                                      className={(formik.touched.subject && formik.errors.subject) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'}
                                    />
                                    {formik.touched.subject && formik.errors.subject ? (
                                      <div className="invalid-feedback">{formik.errors.subject}</div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="col-12">
                                <div className="mb-3">
                                  <label className="form-label">
                                    {t('landing.contact_us.form.input_body')}{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-message-circle fea icon-sm icons clearfix"
                                    >
                                      <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
                                    </svg>
                                    <Field
                                      name="body"
                                      component="textarea"
                                      rows="4"
                                      placeholder={t('landing.contact_us.form.placeholder_body')}
                                      className={(formik.touched.body && formik.errors.body) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'}
                                    />
                                    {formik.touched.body && formik.errors.body ? (
                                      <div className="invalid-feedback">{formik.errors.body}</div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="mb-3">
                                  <label className="form-label">
                                  </label>
                                  <div className="form-icon position-relative">
                                    {this.state.showRecaptcha ?
                                      <>
                                        <HCaptcha
                                          sitekey={this.state.sitekey}
                                          onVerify={(event) => {
                                            formik.setFieldValue("hcaptcha", event)
                                          }}
                                        />
                                        {formik.touched.hcaptcha && formik.errors.hcaptcha ? (
                                          <div className="invalid-feedback">{formik.errors.hcaptcha}</div>
                                        ) : null} </>
                                      : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="d-grid">
                                  <button
                                    className="btn btn-primary"
                                  >
                                    {t('landing.contact_us.form.text_button')}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-7 col-md-6 order-1 order-md-2">
                <div className="card border-0">
                  <div className="card-body p-0">
                    <img src={Contactimg} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid mt-100 mt-60">

            {this.state.showmap ? <><div className="row">
              <div className="col-12 p-0">
                <div className="card map border-0">
                  <div className="card-body p-0">
                    <iframe
                      src={this.state.info.maplink}
                      style={{ border: "0" }}
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div></> : null}
          </div>
        </section>
      </>
    );
  }
}

export default withTranslation()(ContactUs)