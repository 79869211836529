import React from "react";
import jwt_decode from "jwt-decode";
import { Route, Navigate, JsonFunction,redirect,useNavigate } from "react-router-dom";
import TokenService from "../token.service";

 
  function ProtectedEmployee({ children }) {

    if(TokenService.getAccessToken()){

        if(TokenService.getDataAccessToken().type != "employee"){
            return  <Navigate to={"../../login"} replace={true}/>
        }else{
            return children
        }
    }
  
  }

export default ProtectedEmployee
