import React, { useEffect, useState } from "react";
import homeService from "../../api/page/home/home.service";

const FrameworkImage = ({ id }) => {
  const [preview, setPreview] = useState<any>()

  useEffect(() => {
    homeService.getImageFramework(
      id
    ).then(
      response => {

        const reader = new FileReader()
        reader.readAsDataURL(response.data)
        reader.onload = () => {
          setPreview(reader.result)
        }

      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

      }

    );
  }, [id]);




  const carousel: React.CSSProperties = {
    //display: 'inline-block',
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '30px'
  }

  return (
    <>
      <div style={{ width: '100px', height: 'auto', overflow: 'hidden', margin: '0px', position: 'relative', display: 'inline-block' }}>
        <img src={preview} className="shadow " style={carousel} />
      </div>
    </>


  )
}

export default FrameworkImage