import React from "react";
import jwt_decode from "jwt-decode";
import { Route, Navigate, JsonFunction,redirect,useNavigate } from "react-router-dom";
import TokenService from "../token.service";

 
  function BypassLogin({ children }) {

    if(TokenService.getAccessToken()){

      if(TokenService.getDataAccessToken().type == "user"){
        return <Navigate to={'../dashboard/user'} replace={true}/>
      }

      if(TokenService.getDataAccessToken().type == "admin"){
        return <Navigate to={'../dashboard/admin/messages'} replace={true}/>
      }

      if(TokenService.getDataAccessToken().type == "employee" ){
        return <Navigate to={'../dashboard/employee'} replace={true}/>
       }
         
     
    }
    return children
  }

export default BypassLogin
