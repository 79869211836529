import React from "react";
import { Navigate, NavLink, redirect} from 'react-router-dom';
import { Component } from "react";
import InfoProject from "./elements/infoProject";

type Props = {};

type State = {
  successful: boolean,
  message: string
};

export default class InfoPortfolio extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    

    this.state = {
      successful: false,
      message: ""
    };
  }

 

  componentDidMount(){
     
  }


  render(){

    const { successful, message } = this.state;


    return (

     <>
        <section className="section" style={{marginBottom: "200px"}}>
            <div className="container">
                <InfoProject/>
            </div>
            
            <div className="container mt-100 mt-60">
                
            </div>
        </section>
            
     </>

    )
  }
}