import React, { createRef, StyleHTMLAttributes } from "react";
import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';
import { Navigate, NavLink, redirect} from 'react-router-dom';
import { Component } from "react";
import Error404 from '../../assets/landing/img/error/404.svg'
import BackButton from "../common/elements/backButton";
import { withTranslation, WithTranslation } from "react-i18next";


interface Props extends WithTranslation {
}


type State = {
  
};

 class Landing404error extends Component<Props, State> {
  

  constructor(props: Props) {
    super(props);
 
    
    this.state = {
      
    };
  }



 

  componentDidMount(){
     
  }

  render(){

    const {} = this.state;


    const { t } = this.props
      
    return (

     <>
            {/*<div className="back-to-home rounded d-none d-sm-block">
              <a href="" className="back-button btn btn-icon btn-primary"><i data-feather="arrow-left" className="icons"></i></a>
            </div>*/}

        
        <section className="bg-home d-flex align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-12 text-center">
                        <img src={Error404} className="img-fluid" alt=""/>
                        <div className="text-uppercase mt-4 display-3">{t('landing_error_page.warning')}</div>
                        <div className="text-capitalize text-dark mb-4 error-page">{t('landing_error_page.message')}</div>
                        {/*<p className="text-muted para-desc mx-auto">Start working with <span className="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>*/}
                    </div>
                </div>

                <BackButton/>
            </div>
        </section>
       
     </>

    )
  }
}

export default withTranslation()(Landing404error)