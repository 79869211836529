import React from "react";
import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation ,Outlet,NavLink,Navigate} from 'react-router-dom';
import { Component } from "react";

type Props = {};

type State = {
  
};

export default class Void extends Component<Props, State> {

  constructor(props:Props) {
    super(props);

    

    this.state = {
   }

  }

  componentDidMount(){
    
  }

  render(){


    return (
      <>
      <Outlet/>
      </>
   
    )
  }
}