import '../../settingApi'
import { api } from "../../settingApi";


const VERSION = 'v1'


class ContactUsService {



    findAll() {
        return api({
          method: 'get',
          url: `${VERSION}/page/contact-us`,
        })
        
    }

   update(id:number,phoneinfo:boolean,textphone:string,phone:string,emailinfo:boolean,textemail:string,email:string,companyinfo:boolean,textlocation:string,location:string,companylocationmap:boolean,mapslink:string) {
    return api({
      method: 'patch',
      url: `${VERSION}/page/contact-us/${id}`,
      data: {
        phoneinfo,
        textphone,
        phone,
        emailinfo,
        textemail,
        email,
        companyinfo,
        textlocation,
        location,
        companylocationmap,
        mapslink
      }
    })
  }


}



export default new ContactUsService();