import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';

export interface SidebarState{
  open:boolean
}

const initialState: SidebarState = {
  open:false
}

 const sidebarSlice = createSlice({
    name:'sidebar',
    initialState,
    reducers:{
        toggleStatus(state){
            state.open = !state.open
        }
    },
})

export const {toggleStatus} = sidebarSlice.actions
export default sidebarSlice.reducer