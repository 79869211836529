import React, { createRef, StyleHTMLAttributes } from "react";
import { Navigate, NavLink, redirect} from 'react-router-dom';
import { Component } from "react";
import { type } from "os";
import * as yup from "yup";
import Highlighter from "react-highlight-words";
import { ColumnsType, ColumnType, FilterConfirmProps, SorterResult, TableRowSelection } from "antd/es/table/interface";
import { Button, Input, Space, Table, TableProps, Tag } from "antd";
import { DeleteOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import { Modal } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import SkeletonTable, { SkeletonTableColumnsType } from "../common/dashboard/animation/skeletonTable";
import userService from "../../api/user/user.service";



type Props = {};

type State = {
    successful: boolean;
    message: string;
    successfulAdd:boolean,
    messageAdd:string,
    successfulDelete:boolean,
    messageDelete:string,
    successfulUpdate:boolean,
    messageUpdate:string,
    userInfo:{
      name:string,
      surname:string,
      user:string,
      email:string,
      password:string,
      type:string,
      status:string
    },
    loading:boolean,
    users:any[],
    file:string,
    deleteId:number,
    updateId:number,
    modalAdd:boolean,
    modalUpdate:boolean,
    modalDelete:boolean,
    searchText:string;
    searchColumn:string;
    searchTimeText;
    searchTimeRange;
    searchTimeRangeColumn: string;
    selectedRowKeys: React.Key[];
    filteredInfo;
    sortedInfo;
    columnIndex;
};

export default class Users extends Component<Props, State> {
  
 searchInput: any;

  constructor(props: Props) {
    super(props);
    

    this.state = {
        successful: false,
        message: "",
        successfulAdd:false,
        messageAdd:'',
        successfulDelete:false,
        messageDelete:'',
        successfulUpdate:false,
        messageUpdate:'',
        userInfo:{
           name:"",
           surname:"",
           user:"",
           email:"",
           password:"",
           type:"",
           status:""
        },
        loading:true,
        users:[],
        file:'',
        deleteId: 0,
        updateId: 0,
        modalAdd:false,
        modalUpdate:false,
        modalDelete:false,
        searchText: "",
        searchColumn: "",
        searchTimeText: null,
        searchTimeRange: null,
        searchTimeRangeColumn: "",
        selectedRowKeys: [],
        filteredInfo: null,
        sortedInfo: null,
        columnIndex: null,
    };
  }


  setDeleteId = (id) =>{
    this.setState({
      deleteId: id
    })
  }

  setUpdateId = (id) =>{
    this.setState({
      updateId: id
    })
  }


  setProjectInfo = (record) =>{
    this.setState({
       userInfo:{
        name:record.name,
        surname:record.surname,
        user:record.user,
        email:record.email,
        password:"",
        type:record.type,
        status:record.status
       }
    })
  }



  setModalAdd = (state) =>{
    this.setState({
      modalAdd:state,
      messageAdd:"",
      successfulAdd:false
    })
  }

  setModalUpdate = (state) =>{
    this.setState({
      modalUpdate:state,
      messageUpdate:"",
      successfulUpdate:false
    })
  }

  setModalDelete = (state) =>{
    this.setState({
      modalDelete:state
    })
  }

 
  validationSchemaAdd() {
    const passwordRules = /^(?=(.*[a-z]){3,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){2,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,}$/;
    const emailRules = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
    return yup.object().shape({
      name: yup.string().required("inserisci un nome"),
      surname: yup.string().required("inserisci un cognome"),
      user: yup.string().required("inserisci un nome utente"),
      email: yup.string().required("inserisci una email").matches(emailRules, "Inserisci una email valida"),
      password: yup
      .string()
      .required("inserisci una password")
      .matches(
        passwordRules,
        "Inserisci una password di almeno 8 caratteri, una maiuscuola , simboli speciali e almeno 2 numeri"
      ),
    cpassword: yup
      .string()
      .oneOf([yup.ref("password")], "le password non corrispondono")
      .required("inserisci la password di conferma"),
      type: yup.string().required("inserisci una ruolo"),
      status: yup.string().required("inserisci uno stato"),
    });
  }

  validationSchemaUpdate() {
    const emailRules = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
    return yup.object().shape({
      name: yup.string().required("inserisci un nome"),
      surname: yup.string().required("inserisci un cognome"),
      user: yup.string().required("inserisci un nome utente"),
      email: yup.string().required("inserisci una email").matches(emailRules, "Inserisci una email valida"),
      type: yup.string().required("inserisci un ruolo"),
      status: yup.string().required("inserisci uno stato"),
    });
  }


  getAllUsers = async() =>{

    userService.getAllUserExceptOne(
      ).then(
        response => {
  

         this.setState({
           users:response.data,
         })

         setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 500);

          
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
      }
  
      );
  }

  addUser = async(formValue:{name:string,surname:string,user:string,email:string,password:string,cpassword:string,type:string,status:string}) =>{
    
    const { 
      name,
      surname,
      user,
      email,
      password,
      cpassword,
      type,
      status
       } = formValue

       this.setState({
        messageAdd:'',
        successfulAdd:false
      })
       
      userService.addUser(
         name,
         surname,
         user,
         email,
         password,
         cpassword,
         type,
         status
        ).then(
          response => {
  
            this.setState({
              messageAdd:'',
              successfulAdd:true
            })

            this.getAllUsers()

            this.setModalAdd(false)
            
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

              if(resMessage == "email-is-exist"){
                this.setState({
                  successfulAdd: false,
                  messageAdd: "Email già esistente"
                });
              } 

              if(resMessage == "this-user-is-already-taken"){
                this.setState({
                  successfulAdd: false,
                  messageAdd: "Lo user è già stato preso"
                });
              } 
    
        }
    
        )
  }


  updateUser = async(formValue:{name:string,surname:string,user:string,email:string,type:string,status:string}) =>{

    const { 
      name,
      surname,
      user,
      email,
      type,
      status
       } = formValue


       this.setState({
        messageUpdate:'',
        successfulUpdate:false
      })

      userService.updateUser(
        this.state.updateId,
        name,
        surname,
        user,
        email,
        type,
        status
       ).then(
         response => {
 
           this.setState({
             messageUpdate:'',
             successfulUpdate:true
           })

           this.getAllUsers()

           this.setModalUpdate(false)
           
         },
         error => {
           const resMessage =
             (error.response &&
               error.response.data &&
               error.response.data.message) ||
             error.message ||
             error.toString();

             if(resMessage == "email-is-exist"){
               this.setState({
                 successfulUpdate: false,
                 messageUpdate: "Email già esistente"
               });
             } 

             if(resMessage == "this-user-is-already-taken"){
               this.setState({
                 successfulUpdate: false,
                 messageUpdate: "Lo user è già stato preso"
               });
             } 
   
       }
   
       )
  }

  deleteUser = async() =>{

    userService.deleteUser(
      this.state.deleteId,
     ).then(
       response => {

         

         this.getAllUsers()

         this.setModalDelete(false)
         
       },
       error => {
         const resMessage =
           (error.response &&
             error.response.data &&
             error.response.data.message) ||
           error.message ||
           error.toString();

     }
 
     )
  }


  componentDidMount(){
    this.getAllUsers()
  }

  render(){


    const {
        successful,
        message,
        successfulAdd,
        messageAdd,
        successfulUpdate,
        messageUpdate,
        selectedRowKeys,
        filteredInfo,
        sortedInfo,
      } = this.state;
  
      const initialValuesAdd = {
        name:"",
        surname:"",
        user:"",
        email:"",
        password:"",
        cpassword:"",
        type:"",
        status:""
      };
  
      const initialValuesUpdate = {
        name:this.state.userInfo.name,
        surname: this.state.userInfo.surname,
        user: this.state.userInfo.user,
        email:this.state.userInfo.email,
        type:this.state.userInfo.type,
        status:this.state.userInfo.status,
      };

      interface DataType {
        key: string;
        id: number;
        name:string,
        surname:string,
        user:string,
        email:string,
        type:string,
        role:string,
        status:string
        state:string,
        users: any[];
      }
  
      type DataIndex = keyof DataType;

      const handleChange: TableProps<DataType>["onChange"] = (
        pagination,
        filters,
        sorter
      ) => {
        //console.log('Various parameters', pagination, filters, sorter)
        this.setState({
          filteredInfo: filters,
          sortedInfo: sorter as SorterResult<DataType>,
        });
      };
  
      const clearAll = () => {
        this.setState({ filteredInfo: {}, sortedInfo: {} });
      };
  
      const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex
      ) => {
        confirm();
        this.setState({ searchText: selectedKeys[0], searchColumn: dataIndex });
      };
  
      const handleReset = (clearFilters: () => void) => {
        clearFilters();
        this.setState({ searchText: "" });
      };
  
      const getColumnSearchProps = (dataIndex): ColumnType<DataType> => ({
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={this.searchInput}
              placeholder={`Cerca ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              style={{ marginBottom: 8, display: "block" }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() =>
                  handleSearch(selectedKeys as string[], confirm, dataIndex)
                }
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Cerca
              </Button>
              <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
              >
                Resetta
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: (selectedKeys as string[])[0],
                    searchColumn: dataIndex,
                  });
                }}
              >
                Filtra
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  close();
                }}
              >
                Chiudi
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered: boolean) => (
          <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        filteredValue: filteredInfo != null ? filteredInfo.body || null : null,
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => this.searchInput.current?.select(), 100);
          }
        },
        sortOrder:
          sortedInfo != null
            ? sortedInfo.columnKey === dataIndex
              ? sortedInfo.order
              : null
            : null,
        render: (text) =>
          this.state.searchColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ""}
            />
          ) : (
            text
          ),
      });
  
      const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        //console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        
      };
  
      const rowSelection: TableRowSelection<DataType> = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
          Table.SELECTION_ALL,
          Table.SELECTION_INVERT,
          Table.SELECTION_NONE,
        ],
      };
  
  
      const columns: ColumnsType<DataType> = [
        {
            key: "name",
            title: "Nome ",
            dataIndex: ['name'],
            align: "center",
            responsive: ["md", "sm", "xs"],
            sorter: (a, b) => a.name.length - b.name.length,
            sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "name"  ? sortedInfo.order : null : null,
        },
        {
            key: "surname",
            title: "Cognome ",
            dataIndex: ['surname'],
            align: "center",
            responsive: ["md", "sm", "xs"],
            sorter: (a, b) => a.surname.length - b.surname.length,
            sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "surname"  ? sortedInfo.order : null : null,
        },
        {
            key: "user",
            title: "User ",
            dataIndex: ['user'],
            align: "center",
            responsive: ["md", "sm", "xs"],
            sorter: (a, b) => a.name.length - b.name.length,
            sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "user"  ? sortedInfo.order : null : null,
        },
        {
            key: "email",
            title: "Email",
            dataIndex: ['email'],
            align: "center",
            responsive: ["md", "sm", "xs"],
            sorter: (a, b) => a.email.length - b.email.length,
            sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "email"  ? sortedInfo.order : null : null,
          },
        
        {
          key:"type",
          title: "Tipo",
          dataIndex: ['type'],
          align: "center",
          responsive: ["md", "sm", "xs"],
          filters: [
            {
              text: 'Utente',
              value: 'user',
            },
            {
              text: 'Impiegato',
              value: 'employee',
            },
            {
              text: 'Amministratore',
              value: 'admin',
            },
          ],
          filterSearch: true,
          sorter: (a, b) => a.type.length - b.type.length,
          sortOrder: sortedInfo != null ? sortedInfo.columnKey === 'type' ? sortedInfo.order : null : null,
          onFilter: (value: any, record) => record.type == value,
          filteredValue: filteredInfo != null ? filteredInfo.type || null : null,
          render: (_, record) => {
            
            var color:string = ''
  
            if(record.type == 'user'){
                record.role = 'utente'
                color = 'green'
             }

             if(record.type == 'employee'){
                record.role = 'impiegato'
                color = 'blue'
              }

              if(record.type == 'admin'){
               record.role = 'amministratore'
               color = 'volcano'
             }

             return (
                 <div>
                 <Tag color={color} >
                  {record.role ? record.role.toUpperCase() : null}
                 </Tag>
               </div>
             )
  
          }
        },
        {
            key: 'status',
            title: 'Stato',
            dataIndex: ['status'],
            align: 'center',
            responsive: ["md", "sm", "xs"],
            filters: [
              {
                text: 'Verificato',
                value: 'verified',
              },
              {
                text: 'Non verificato',
                value: 'notverified',
              },
              {
                text: 'Sospeso',
                value: 'suspended',
              },
              {
                text: 'Bannato',
                value: 'banned',
              },
            ],
            filterSearch: true,
            sorter: (a, b) => a.status.length - b.status.length,
            sortOrder: sortedInfo != null ? sortedInfo.columnKey === 'status' ? sortedInfo.order : null : null,
            onFilter: (value: any, record) => record.status == value,
            filteredValue: filteredInfo != null ? filteredInfo.status || null : null,
            render: (_,record) => {

              
                 var color:string = ''
                 var textColor:string = ''
                 
                if(record.status == 'verified'){
                   record.state = 'verificato'
                   color = '#87d068'
                   textColor = 'white'
               
                }

                if(record.status == 'notverified'){
                  record.state = 'non verificato'
                  color = '#f9ff00'
                  textColor = 'black'
                }

               if(record.status == 'suspended'){
                record.state = 'sospeso'
                color = '#ff6f00'
                textColor = 'white'
               }

               if(record.status == 'banned'){
                record.state = 'bannato'
                color = '#ff0000'
                textColor = 'white'
               }

                
                return (
                  <div>
                  <Tag color={color} style={{color: textColor}}>
                      {record.state ? record.state.toUpperCase() : null}
                    </Tag>
                </div>
                   )
               
            }
        },
        {
          title: "Azione",
          dataIndex: "azione",
          align: "center",
          responsive: ["md", "sm", "xs"],
          render: (_, record) => {
            return <>
            <Button
            onClick={() => [
              this.setModalUpdate(true),
              this.setUpdateId(record.id),
              this.setProjectInfo(record),
            ]}
            type="primary"
            style={{
              width: "40px",
              height: "40px",
              alignContent: "center",
            }}
            icon={<EditOutlined />}
          />
          <Button
            onClick={() => [
              this.setModalDelete(true),
              this.setDeleteId(record.id),
            ]}
            danger
            type="primary"
            style={{
              width: "40px",
              height: "40px",
              marginLeft: "20px",
              alignContent: "center",
            }}
            icon={<DeleteOutlined />}
            />
          </>;
          },
        },
      ];
  
      
    return (

     <>
            <div className="container-fluid">
          <div className="layout-specing">
            <div className="d-md-flex justify-content-between align-items-center">
              <h5 className="mb-0">Utenti</h5>

              <nav aria-label="breadcrumb" className="d-inline-block">
                <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                  <li className="breadcrumb-item text-capitalize">
                    <a>Dashboard</a>
                  </li>
                  <li
                    className="breadcrumb-item text-capitalize active"
                    aria-current="page"
                  >
                    Utenti
                  </li>
                </ul>
              </nav>
            </div>

            <div className="row">
              <div className="col-12 mt-4"></div>
            </div>

            <div className="row text-center">

            <Space style={{ marginBottom: 16 }}>
              <div className="mobile-sm">
                <Button onClick={clearAll}>
                  Resetta ordinamenti,filtri e ricerca
                </Button>
              </div>
              <div className="mobile-sm">
                <Button type="primary" onClick={() => this.setModalAdd(true)}>
                  Aggiungi utente
                </Button>
              </div>
          </Space>

              <SkeletonTable
                loading={this.state.loading}
                columns={columns as SkeletonTableColumnsType[]}
              >
                <Table
                  columns={columns}
                  dataSource={this.state.users}
                  onChange={handleChange}
                  rowKey={(record) => record.id}
                  //rowSelection={rowSelection}
                  pagination={{
                    pageSize: 10,
                  }}
                  scroll={{ x: 600 }}
                />
              </SkeletonTable>
            </div>


     


           <Modal
             show={this.state.modalDelete}
             onHide={() => this.setModalDelete(false)}
           >
          <Modal.Header
            className="modal-header modal-colored-header bg-danger text-white"
          >
            <h5 className="modal-title" id="danger-header-modalLabel">
              Elimina utente
            </h5>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <p>Sei sicuro di voler eliminare questo utente?</p>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <Button onClick={() => this.setModalDelete(false)}>Annulla</Button>
            <Button danger onClick={() => this.deleteUser()}>
              Elimina utente
            </Button>
          </Modal.Footer>
         </Modal> 



            <Modal
                show={this.state.modalAdd}
                onHide={() => this.setModalAdd(false)}
                backdrop="static"
                keyboard={false}
            >
        
          
                <Formik
                    initialValues={initialValuesAdd}
                    validationSchema={this.validationSchemaAdd}
                    onSubmit={this.addUser}
                  >
      
           {formik => (
         

          <Form>
            <Modal.Header >
              <h5 className="modal-title" id="createModalLabel">
                <i className="ri-mark-pen-line align-middle me-2"></i> Aggiungi nuovo utente
              </h5>
            </Modal.Header>
            <Modal.Body className="modal-body">
              <div className="container d-flex align-items-center justify-content-center">
                {messageAdd && (
                  <div className="form-group">
                    <div
                      className={
                        successfulAdd
                          ? "alert alert-success  text-center"
                          : "alert alert-danger text-center "
                      }
                      role="alert"
                    >
                      <strong>
                        {successfulAdd ? "Successo - " : "Errore - "}
                      </strong>
                      {messageAdd}
                    </div>
                  </div>
                )}
              </div>

                              <div className="mb-3">
                                  <label className="form-label">
                                    Nome {" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="name"
                                      type="text"
                                      placeholder="nome"
                                      className={(formik.touched.name && formik.errors.name) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    />
                                  {formik.touched.name && formik.errors.name ? (
                                        <div className="invalid-feedback">{formik.errors.name}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                   Cognome{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="surname"
                                      type="text"
                                      placeholder="Cognome"
                                      className={(formik.touched.surname && formik.errors.surname) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    />
                                  {formik.touched.surname && formik.errors.surname ? (
                                        <div className="invalid-feedback">{formik.errors.surname}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                    User{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="user"
                                      type="text"
                                      placeholder="User"
                                      className={(formik.touched.user && formik.errors.user) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.user && formik.errors.user ? (
                                        <div className="invalid-feedback">{formik.errors.user}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                    Email{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="email"
                                      type="text"
                                      placeholder="Email"
                                      className={(formik.touched.email && formik.errors.email) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.email && formik.errors.email ? (
                                        <div className="invalid-feedback">{formik.errors.email}</div>
                                    ) : null} 
                             </div>
                          </div>

                          <div className="mb-3">
                                  <label className="form-label">
                                    Password{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="password"
                                      type="password"
                                      placeholder="Password"
                                      className={(formik.touched.password && formik.errors.password) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.password && formik.errors.password ? (
                                        <div className="invalid-feedback">{formik.errors.password}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                    Conferma password{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="cpassword"
                                      type="password"
                                      placeholder="Password"
                                      className={(formik.touched.cpassword && formik.errors.cpassword) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.cpassword && formik.errors.cpassword ? (
                                        <div className="invalid-feedback">{formik.errors.cpassword}</div>
                                    ) : null} 
                             </div>
                          </div>

                          <div className="mb-3">
                                  <label className="form-label">
                                    Ruolo{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="type"
                                      type="text"
                                      as="select"
                                      placeholder="ruolo"
                                      className={(formik.touched.type && formik.errors.type) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    >
                                      <option>--seleziona--</option>
                                      <option value="user">Utente</option>
                                      <option value="employee">Impiegato</option>
                                      <option value="admin">Amministratore</option>
                                    </Field> 
                                  {formik.touched.type && formik.errors.type ? (
                                        <div className="invalid-feedback">{formik.errors.type}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                    Stato{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="status"
                                      type="text"
                                      as="select"
                                      placeholder="stato"
                                      className={(formik.touched.status && formik.errors.status) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    >
                                      <option>--seleziona--</option>
                                      <option value="verified">Verificato</option>
                                      <option value="notverified">Non verificato</option>
                                      <option value="suspended">Sospeso</option>
                                      <option value="banned">Bannato</option>
                                    </Field> 
                                  {formik.touched.status && formik.errors.status ? (
                                        <div className="invalid-feedback">{formik.errors.status}</div>
                                    ) : null} 
                             </div>
                          </div>


              
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.setModalAdd(false)}>Chiudi</Button>
              
                <Button type="primary" htmlType="submit">
                  Aggiungi
                </Button>
             
            </Modal.Footer>
          </Form>
        )}
      </Formik>
        </Modal>



        <Modal
                show={this.state.modalUpdate}
                onHide={() => this.setModalUpdate(false)}
                backdrop="static"
                keyboard={false}
            >
        
          
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValuesUpdate}
                    validationSchema={this.validationSchemaUpdate}
                    onSubmit={this.updateUser}
                  >
      
           {formik => (
         

          <Form>
            <Modal.Header >
              <h5 className="modal-title" id="createModalLabel">
                <i className="ri-mark-pen-line align-middle me-2"></i> Modifica utente
              </h5>
            </Modal.Header>
            <Modal.Body className="modal-body">
              <div className="container d-flex align-items-center justify-content-center">
                {messageUpdate && (
                  <div className="form-group">
                    <div
                      className={
                        successfulUpdate
                          ? "alert alert-success  text-center"
                          : "alert alert-danger text-center "
                      }
                      role="alert"
                    >
                      <strong>
                        {successfulUpdate ? "Successo - " : "Errore - "}
                      </strong>
                      {messageUpdate}
                    </div>
                  </div>
                )}
              </div>

              <div className="mb-3">
                                  <label className="form-label">
                                    Nome {" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="name"
                                      type="text"
                                      placeholder="nome"
                                      className={(formik.touched.name && formik.errors.name) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    />
                                  {formik.touched.name && formik.errors.name ? (
                                        <div className="invalid-feedback">{formik.errors.name}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                   Cognome{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="surname"
                                      type="text"
                                      placeholder="Cognome"
                                      className={(formik.touched.surname && formik.errors.surname) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    />
                                  {formik.touched.surname && formik.errors.surname ? (
                                        <div className="invalid-feedback">{formik.errors.surname}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                    User{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="user"
                                      type="text"
                                      placeholder="User"
                                      className={(formik.touched.user && formik.errors.user) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.user && formik.errors.user ? (
                                        <div className="invalid-feedback">{formik.errors.user}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                    Email{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="email"
                                      type="text"
                                      placeholder="Email"
                                      className={(formik.touched.email && formik.errors.email) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.email && formik.errors.email ? (
                                        <div className="invalid-feedback">{formik.errors.email}</div>
                                    ) : null} 
                             </div>
                          </div>

                          <div className="mb-3">
                                  <label className="form-label">
                                    Ruolo{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="type"
                                      type="text"
                                      as="select"
                                      placeholder="ruolo"
                                      className={(formik.touched.type && formik.errors.type) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    >
                                      <option value="">--seleziona--</option>
                                      <option value="user">Utente</option>
                                      <option value="employee">Impiegato</option>
                                      <option value="admin">Amministratore</option>
                                    </Field> 
                                  {formik.touched.type && formik.errors.type ? (
                                        <div className="invalid-feedback">{formik.errors.type}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                    Stato{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="status"
                                      type="text"
                                      as="select"
                                      placeholder="stato"
                                      className={(formik.touched.status && formik.errors.status) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    >
                                      <option value="">--seleziona--</option>
                                      <option value="verified">Verificato</option>
                                      <option value="notverified">Non verificato</option>
                                      <option value="suspended">Sospeso</option>
                                      <option value="banned">Bannato</option>
                                    </Field> 
                                  {formik.touched.status && formik.errors.status ? (
                                        <div className="invalid-feedback">{formik.errors.status}</div>
                                    ) : null} 
                             </div>
                          </div>
              
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => [this.setModalUpdate(false)]}>Chiudi</Button>
              
                <Button type="primary" htmlType="submit">
                  Modifica
                </Button>
             
            </Modal.Footer>
          </Form>
        )}
      </Formik>
        </Modal>

          </div>
        </div>

     </>

    )
  }
}