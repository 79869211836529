import React, { createRef, StyleHTMLAttributes } from "react";
import 'tiny-slider/dist/tiny-slider.css';
import { Navigate, NavLink, redirect} from 'react-router-dom';
import { Component } from "react";
import Startup from '../assets/landing/img/services.svg'
import Web from '../assets/landing/img/analyze_report_4.svg'
import { Carousel } from "antd";
import FrameworkImage from "./elements/frameworkImage";
import { withTranslation, WithTranslation } from 'react-i18next';
import homeService from "../api/page/home/home.service";


interface Props extends WithTranslation{
    prop:any
}


type State = {
  frameworks:any[],
  settingItems:{
    frameworkinfo:boolean
  }
  successful: boolean,
  message: string
};

 class Index extends Component<Props,State> {
  
    private  carouselRef : React.RefObject<any>;

  constructor(props) {
    super(props);
    
    this.carouselRef = React.createRef()

    this.state = {
      frameworks:[],
      settingItems:{
        frameworkinfo:true
      },
      successful: false,
      message: ""
    };
  }



 
  getAllSettings = async() =>{

    homeService.findAll(
      ).then(
        response => {
  

        this.setState({
            settingItems:{
               frameworkinfo:response.data[0].frameworksinfo
            }
        })

          
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
      }
  
      );
  }

  getAllFrameworks = async() =>{

    homeService.findAllFramework(
      ).then(
        response => {
  

         this.setState({
           frameworks:response.data,
         })

          
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
      }
  
      );
  }


  componentDidMount(){
      this.getAllSettings()
      this.getAllFrameworks()
  }

  render(){

    const { successful, message } = this.state;

    const { t } = this.props

    return (

     <>
            
        <section className="bg-half-170 d-table w-100">
            <div className="container">
                <div className="row mt-5 align-items-center">
                    <div className="col-lg-7 col-md-7">
                        <div className="title-heading me-lg-4">
                            <h1 className="heading mb-3">{t('landing.index.first_section.text')} <span className="text-primary">{t('landing.index.first_section.key_word')}</span> </h1>
                            <p className="para-desc text-muted">{t('landing.index.first_section.desc')}</p>
                            <div className="mt-4">
                                <NavLink to={'contact-us'} className="btn btn-primary mt-2 me-2"><i className="uil uil-envelope"></i>{t('landing.index.first_section.text_button')}</NavLink>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <img src={Startup} alt=""/>
                    </div>
                </div>
            </div>
        </section>
        

       
        <section className="py-4 border-bottom border-top">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                        <img src="assets/images/client/amazon.svg" className="avatar avatar-ex-sm" alt=""/>
                    </div>

                    <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                        <img src="assets/images/client/google.svg" className="avatar avatar-ex-sm" alt=""/>
                    </div>
                    
                    <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                        <img src="assets/images/client/lenovo.svg" className="avatar avatar-ex-sm" alt=""/>
                    </div>
                    
                    <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                        <img src="assets/images/client/paypal.svg" className="avatar avatar-ex-sm" alt=""/>
                    </div>
                    
                    <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                        <img src="assets/images/client/shopify.svg" className="avatar avatar-ex-sm" alt=""/>
                    </div>
                    
                    <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                        <img src="assets/images/client/spotify.svg" className="avatar avatar-ex-sm" alt=""/>
                    </div>
                </div>
            </div>
        </section>
       

      
        <section className="section bg-light border-bottom">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 text-center">
                        <div className="section-title mb-4 pb-2">
                            <h4 className="title mb-4">{t('landing.index.second_section.title')}</h4>
                            {/*<p className="text-muted para-desc mb-0 mx-auto">Start working with <span className="text-primary fw-bold">Landrick</span> {t('landing.index.second_section.desc')}</p>*/}
                        </div>
                    </div>
                </div>

                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-6 mt-4 pt-2">
                        {<img src={Web} alt="" />}
                        
                    </div>

                    <div className="col-lg-7 col-md-6 mt-4 pt-2">
                        <div className="section-title ms-lg-5">
                            <h4 className="title mb-4">{t('landing.index.second_section.subtitle_one')}</h4>
                            <p className="text-muted">{t('landing.index.second_section.desc_title_one')}</p>
                            <ul className="list-unstyled text-muted">
                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>{t('landing.index.second_section.point_one')}</li>
                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>{t('landing.index.second_section.point_two')}</li>
                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>{t('landing.index.second_section.point_three')}</li>
                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>{t('landing.index.second_section.point_four')}</li>
                            </ul>
                            <NavLink to={'contact-us'} className="mt-3 h6 text-primary">{t('landing.index.second_section.button_contactus')} <i className="uil uil-angle-right-b align-middle"></i></NavLink>
                        </div>
                    </div>
                </div>
            </div>

            {/*<div className="container mt-100 mt-60">
                <div className="row align-items-center">
                    <div className="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="section-title">
                            <h4 className="title mb-4">Speed up your development <br/> with <span className="text-primary">Landrick.</span></h4>
                            <p className="text-muted">Using Landrick to build your site means never worrying about designing another page or cross browser compatibility. Our ever-growing library of components and pre-designed layouts will make your life easier.</p>
                            <ul className="list-unstyled text-muted">
                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced Marketing Agency</li>
                                <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
                            </ul>
                            <a href="javascript:void(0)" className="mt-3 h6 text-primary">Find Out More <i className="uil uil-angle-right-b align-middle"></i></a>
                        </div>
                    </div>

                    <div className="col-lg-5 col-md-6 order-1 order-md-2">
                        <div className="card rounded border-0 shadow ms-lg-5">
                            <div className="card-body">
                                <img src="assets/images/illustrator/Mobile_notification_SVG.svg" alt=""/>

                                <div className="content mt-4 pt-2">
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label className="form-label">Name : <span className="text-danger">*</span></label>
                                                    <div className="form-icon position-relative">
                                                        <i data-feather="user" className="fea icon-sm icons"></i>
                                                        <input type="text" className="form-control ps-5" placeholder="Name" name="name" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label className="form-label">Email : <span className="text-danger">*</span></label>
                                                    <div className="form-icon position-relative">
                                                        <i data-feather="mail" className="fea icon-sm icons"></i>
                                                        <input type="email" className="form-control ps-5" placeholder="Email" name="email" 
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label className="form-label">Password : <span className="text-danger">*</span></label>
                                                    <div className="form-icon position-relative">
                                                        <i data-feather="key" className="fea icon-sm icons"></i>
                                                        <input type="password" className="form-control ps-5" placeholder="Password" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 mt-2 mb-0">
                                                <div className="d-grid">
                                                    <button className="btn btn-primary">{t('Download')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>*/}
        </section>
       

        
        { this.state.settingItems.frameworkinfo ? <><section className="section pb-0">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 text-center">
                        <div className="section-title mb-4 pb-2">
                            <h6>{t('landing.index.third_section.desc')}</h6>
                            <h4 className="title mb-4">{t('landing.index.third_section.title')}</h4>
                            
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 mt-4 pt-2">
                        <div className="tns-outer" id="tns1-ow">
                            <div className="tns-controls" aria-label="Carousel Navigation" >
                              {/*<button type="button" data-controls="prev"  aria-controls="tns1" onClick={this.carouselRef.current.prev()}><i className="mdi mdi-chevron-left "></i></button>
                              <button type="button" data-controls="next"  aria-controls="tns1" onClick={this.carouselRef.current.next()}><i className="mdi mdi-chevron-right"></i></button>*/}
                           </div>
                       </div>
                          <Carousel  autoplay slidesToShow={this.state.frameworks.length <= 3 ? this.state.frameworks.length : 3} slidesToScroll={1} pauseOnHover={true} >

                            {this.state.frameworks.map ((item, index) => {

                              return(
                                  <>
                             <div className="tiny-slide tns-item tns-slide-active" key={index}>
                                <div className="card nft nft-primary  border-0 rounded-md shadow m-2">
                                    <div className="card-body p-3">
                                        <div className="pb-3 border-bottom">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span className="badge bg-soft-primary rounded-pill">{t('landing.index.third_section.carousel_language')} {item.language}</span>
        
                                                { item.linkdocsinfo ? <a href={'https://'+item.linkdocs} target="_blank" className="text-dark h5 mb-0 read-more"><i className="uil uil-arrow-circle-right"></i></a> : null}
                                            </div>
                                        </div>
        
                                        <div className="content mt-3">
                                            <div className="text-center ">
                                                <FrameworkImage id={item.id}/>
                                                
                                                <div className="author mt-2">
                                                    <a  className="text-dark h6 name">{item.name}</a>
                                                    {/*<small className="d-block fw-bold mt-1">0.75<span className="text-muted">ETH</span></small>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>           
                                  </>
                               )
                          })}

                                
                          </Carousel>
                    </div>
                </div>
            </div>
        </section>
       

        
        <section className="section">
            {/*<div className="container">
                <div className="row mt-lg-4 align-items-center">
                    <div className="col-lg-5 col-md-12 text-center text-lg-start">
                        <div className="section-title mb-4 mb-lg-0 pb-2 pb-lg-0">
                            <h4 className="title mb-4">Our Comfortable Rates</h4>
                            <p className="text-muted para-desc mx-auto mb-0">Start working with <span className="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                            <a href="https://1.envato.market/landrick" target="_blank" className="btn btn-primary mt-4">Buy Now <span className="badge rounded-pill bg-danger ms-2">v4.5.0</span></a>
                        </div>
                    </div>

                    <div className="col-lg-7 col-md-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                        <div className="ms-lg-5">
                            <div className="row align-items-center">
                                <div className="col-md-6 col-12 px-md-0">
                                    <div className="card pricing pricing-primary starter-plan shadow rounded border-0">
                                        <div className="card-body py-5">
                                            <h6 className="title name fw-bold text-uppercase mb-4">Starter</h6>
                                            <div className="d-flex mb-4">
                                                <span className="h4 mb-0 mt-2">$</span>
                                                <span className="price h1 mb-0">39</span>
                                                <span className="h4 align-self-end mb-1">/mo</span>
                                            </div>
    
                                            <ul className="list-unstyled mb-0 ps-0">
                                                <li className="h6 text-muted mb-0"><span className="icon h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Full Access</li>
                                                <li className="h6 text-muted mb-0"><span className="icon h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Source Files</li>
                                                <li className="h6 text-muted mb-0"><span className="icon h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Free Appointments</li>
                                                <li className="h6 text-muted mb-0"><span className="icon h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Enhanced Security</li>
                                            </ul>
                                            <a href="javascript:void(0)" className="btn btn-primary mt-4">Get Started</a>
                                        </div>
                                    </div>
                                </div>
    
                                <div className="col-md-6 col-12 mt-4 pt-2 pt-sm-0 mt-sm-0 px-md-0">
                                    <div className="card pricing pricing-primary bg-light shadow rounded border-0">
                                        <div className="card-body py-5">
                                            <h6 className="title name fw-bold text-uppercase mb-4">Professional</h6>
                                            <div className="d-flex mb-4">
                                                <span className="h4 mb-0 mt-2">$</span>
                                                <span className="price h1 mb-0">59</span>
                                                <span className="h4 align-self-end mb-1">/mo</span>
                                            </div>
    
                                            <ul className="list-unstyled mb-0 ps-0">
                                                <li className="h6 text-muted mb-0"><span className="icon h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Full Access</li>
                                                <li className="h6 text-muted mb-0"><span className="icon h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Source Files</li>
                                                <li className="h6 text-muted mb-0"><span className="icon h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Free Appointments</li>
                                                <li className="h6 text-muted mb-0"><span className="icon h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Enhanced Security</li>
                                            </ul>
                                            <a href="javascript:void(0)" className="btn btn-primary mt-4">Try it now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>*/}
        </section> </> : null}
        <div className="position-relative">
            <div className="shape overflow-hidden text-light">
                <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        

        
        <section className="section bg-light">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-12">
                        <div className="d-flex">
                            <i data-feather="help-circle" className="fea icon-ex-md text-primary me-2 mt-1"></i>
                            <div className="flex-1">
                                <h5 className="mt-0">{t('landing.index.fourth_section.prefix_faq_one')} <span className="text-primary">{t('landing.index.fourth_section.key_faq_one')}</span> {t('landing.index.fourth_section.faq_one')}</h5>
                                <p className="answer text-muted mb-0">{t('landing.index.fourth_section.ans_faq_one')} <span className="text-primary"> <NavLink to={'portfolio'}>{t('landing.index.fourth_section.link_faq_one')}</NavLink></span></p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="d-flex">
                            <i data-feather="help-circle" className="fea icon-ex-md text-primary me-2 mt-1"></i>
                            <div className="flex-1">
                                <h5 className="mt-0"> {t('landing.index.fourth_section.faq_two')}</h5>
                                <p className="answer text-muted mb-0">{t('landing.index.fourth_section.ans_faq_two')}</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-6 col-12 mt-4 pt-2">
                        <div className="d-flex">
                            <i data-feather="help-circle" className="fea icon-ex-md text-primary me-2 mt-1"></i>
                            <div className="flex-1">
                                <h5 className="mt-0">{t('landing.index.fourth_section.faq_three')}</h5>
                                <p className="answer text-muted mb-0">{t('landing.index.fourth_section.ans_faq_three')}</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-6 col-12 mt-4 pt-2">
                        <div className="d-flex">
                            <i data-feather="help-circle" className="fea icon-ex-md text-primary me-2 mt-1"></i>
                            <div className="flex-1">
                                <h5 className="mt-0">{t('landing.index.fourth_section.prefix_faq_four')} <span className="text-primary">{t('landing.index.fourth_section.key_faq_four')}</span> {t('landing.index.fourth_section.faq_four')}</h5>
                                <p className="answer text-muted mb-0">{t('landing.index.fourth_section.ans_faq_four')}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row my-md-5 pt-md-3 my-4 pt-2 pb-lg-4 justify-content-center">
                    <div className="col-12 text-center">
                        <div className="section-title">
                            <h4 className="title mb-4">{t('landing.index.fourth_section.text')}</h4>
                            <p className="text-muted para-desc mx-auto"><span className="text-primary fw-bold">{t('landing.index.fourth_section.company_name')}</span> {t('landing.index.fourth_section.company_desc')}</p>
                            <NavLink to={'contact-us'} className="btn btn-primary mt-4"><i className="uil uil-phone"></i>{t('landing.index.fourth_section.text_button')}</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-footer">
                <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
     </>

    )
  }
}

export default withTranslation()(Index)