import axios from "axios";
import jwt_decode from "jwt-decode";
import TokenService from "../token.service";
import '../settingApi'
import { api } from "../settingApi";


const VERSION = 'v1'


class UserService {



  getAllUser() {
    return api({
       method: 'get',
       url:`${VERSION}/user`,
        
     })
   }

   getAllUserExceptOne() {
    return api({
       method: 'get',
       url:`${VERSION}/user/except`,
        
     })
   }

   getSingleUser() {
    return api({
       method: 'get',
       url: `${VERSION}/user/single`,
        
     })
   }

   getImage() {
    return api({
      method: 'get',
      url: `${VERSION}/user/profile/image`,
      responseType: 'blob'
    })
    
  }

  addUser(name:string,surname:string,user:string,email:string,password:string,cpassword:string,type:string,status:string) {
   return api({
      method: 'post',
      url: `${VERSION}/user`,
      data: {
        name,
        surname,
        user,
        email,
        password,
        cpassword,
        type,
        status
       },
       
    })
  }

  updateUser(id:number,name:string,surname:string,user:string,email:string,type:string,status:string) {
    return api({
       method: 'patch',
       url: `${VERSION}/user/${id}`,
       data: {
          name,
          surname,
          user,
          email,
          type,
          status
        },
        
     })
   }

   updateProfile(name:string,surname:string,user:string,email:string) {
    return api({
       method: 'patch',
       url: `${VERSION}/user/profile`,
       data: {
          name,
          surname,
          user,
          email,
       }
     })
   }

   updateImage(image) {
    const formData = new FormData();
    formData.append("file", image);
  
  
    return api({
      method: 'patch',
      url: `${VERSION}/user/profile/image`,
      data:formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    
  }

  deleteUser(id:number) {
    return api({
       method: 'delete',
       url: `${VERSION}/user/${id}`,
        
     })
   }
}



export default new UserService();