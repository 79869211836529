import { useState } from "react"
import { NavLink } from "react-router-dom"

export default function SidebarItem({item}){
    const [open, setOpen] = useState(false)

    
    if(item.childrens){
        return (
            <li  className={open ? "sidebar-dropdown active" : "sidebar-dropdown"}>
                    <a onClick={() => setOpen(!open)} href="javascript:void(0)"><i className={item.icon}></i>{item.title}</a>
                    <div className={open ? "sidebar-submenu d-block" : "sidebar-submenu"}>
                     <ul>
                       { item.childrens.map((child, index) => <li key={index} ><NavLink to={child.path}>{child.title}</NavLink></li>) }
                     </ul>
                </div>
            </li>
        )
    }else{
        return (
            <li style={{color:"white"}}><NavLink to={item.path}><i className={item.icon}></i>{item.title}</NavLink></li>
        )
    }
}