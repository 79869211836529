import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";


const BackButton = ({ }) => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation();




    return (
        <>
            <div className="row">
                <div className="col-md-12 text-center">
                    {/*<a href="index.html" className="btn btn-outline-primary mt-4">Go Back</a>*/}
                    <NavLink to={'..'} onClick={(e) => { e.preventDefault(); navigate(-1);}} className="btn btn-primary mt-4 ms-2">{t('error_page.back_button')}</NavLink>
                </div>
            </div>
        </>


    )
}

export default BackButton