import React from "react";
import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation ,Outlet,NavLink,Navigate} from 'react-router-dom';
import { Component } from "react";
import ActiveLink from "../elements/activeLink";
import { WithTranslation, withTranslation } from "react-i18next";
import Logo from "../../assets/landing/img/phoenix-sign-dark.png"


interface Props extends WithTranslation{
  prop:any
}



type State = {
   active:boolean,
   mobile:boolean,
   openMenu:boolean;
};

 class Navbar extends Component<Props, State> {

  constructor(props) {
    super(props);

     this.handleLogout = this.handleLogout.bind(this)
     this.sidebarItem = this.sidebarItem.bind(this)
  

    this.state = {
      active:false,
      mobile:false,
      openMenu:false
    }
  }

  handleLogout(){
 
   
}

 setActive = () =>{
   this.setState({
    active:true
   })
 }

 sidebarItem = () =>{

 }

 openMenu = () =>{
   this.setState({openMenu:!this.state.openMenu})
 }



  getCurrentUser = () =>{

  }

  componentDidMount(){
   
  }

  render(){

    const { t } = this.props
    
    return (

        <>
       
        <header id="topnav" className="defaultscroll sticky">
        <div className="container">
        
            <a className="logo" >
                <img src={Logo} height="24" className="logo-light-mode" alt=""/>
                <img src={Logo} height="24" className="logo-dark-mode" alt=""/>
            </a>                
           
    
           
            <div className="menu-extras">
                <div className="menu-item">
                    
                    <a className={this.state.openMenu ? "navbar-toggle open" : "navbar-toggle"} id="isToggle" onClick={this.openMenu}>
                        <div className="lines">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </a>
                 
                </div>
            </div>
    
         
            <ul className="buy-button list-inline mb-0">
                
            </ul>
    
    
     {this.state.openMenu ? 
        (<div id="navigation" style={{display: 'block'}}>      
            <ul className="navigation-menu">
                 <ActiveLink children={<NavLink to={"../"} onClick={this.openMenu} className="sub-menu-item">{t('landing.navbar.home')}</NavLink>} path={'/'}></ActiveLink>
                 <ActiveLink children={<NavLink to={"./contact-us"} onClick={this.openMenu} className="sub-menu-item" >{t('landing.navbar.contact_us')}</NavLink>} path={'/contact-us'}></ActiveLink>
                 <ActiveLink children={<NavLink to={"./about-us"} onClick={this.openMenu} className="sub-menu-item">{t('landing.navbar.about_us')}</NavLink>} path={'/about-us'}></ActiveLink>
                 <ActiveLink children={<NavLink to={"./portfolio"} onClick={this.openMenu} className="sub-menu-item">{t('landing.navbar.portfolio')}</NavLink>} path={'/portfolio'}></ActiveLink>
            </ul>
        </div>)
            : null
        }

    {!this.state.mobile  ? 
      ( <div id="navigation">     
            <ul className="navigation-menu">
                <ActiveLink children={<NavLink to={"../"} className="sub-menu-item">{t('landing.navbar.home')}</NavLink>} path={'/'}></ActiveLink>
                <ActiveLink children={<NavLink to={"./contact-us"} className="sub-menu-item" >{t('landing.navbar.contact_us')}</NavLink>} path={'/contact-us'}></ActiveLink>
                <ActiveLink children={<NavLink to={"./about-us"} className="sub-menu-item">{t('landing.navbar.about_us')}</NavLink>} path={'/about-us'}></ActiveLink>
                <ActiveLink children={<NavLink to={"./portfolio"} className="sub-menu-item">{t('landing.navbar.portfolio')}</NavLink>} path={'/portfolio'}></ActiveLink>
            </ul>
        </div>)
            : null
        }

        

        </div>
    </header>
 </>
    )
  }
}

export default withTranslation()(Navbar)