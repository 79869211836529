import React, { createRef, StyleHTMLAttributes } from "react";
import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';
import { Navigate, NavLink, redirect } from 'react-router-dom';
import { Component } from "react";
import Error404 from '../../assets/landing/img/error/404.svg'
import BackButton from "../common/elements/backButton";
import { withTranslation, WithTranslation } from "react-i18next";


interface Props extends WithTranslation{
    
}


type State = {

};

class Project404error extends Component<Props, State> {


    constructor(props: Props) {
        super(props);


        this.state = {

        };
    }





    componentDidMount() {

    }

    render() {

        const { } = this.state;
        const { t } = this.props


        return (

            <>
                <div className="row">
                    <div className="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="sticky-bar">
                            <div className="row me-lg-4">
                                <div className="col-lg-12">
                                    <div className="work-details">
                                        <h4 className="title mb-3 border-bottom pb-3">{t('project_notfound.title')}</h4>
                                    </div>
                                </div>

                                <div className="col-lg-7 mt-4 pt-2">
                                    <div className="card work-details rounded bg-light border-0">
                                        <div className="card-body">
                                            <h5 className="card-title border-bottom pb-3 mb-3">{t('project_notfound.message')}</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-center">
                                        <BackButton/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*<div className="col-lg-5 col-md-6 order-1 order-md-2">
                        <div className="port-images sticky-sidebar" style={{ width: '400pxpx', height: '300px', overflow: 'hidden', margin: '0px', position: 'relative', borderRadius: '6px' }}>
                            <img src={preview} className="img-fluid mx-auto d-block rounded" style={{ maxWidth: '100%', maxHeight: '100%' }} alt="img" />
                        </div>
                    </div>*/}
                     <div style={{ height: '200px' }}></div>
                </div>

            </>

        )
    }
}

export default withTranslation()(Project404error)