import React from 'react';


  export const SidebarUser = [
    {
        title: "Profilo",
        icon: "ti ti-user me-2",
        path: "../dashboard/user/profile"
    }
  ];


  export const SidebarEmployee = [
    {
        title: "Profilo",
        icon: "ti ti-user me-2",
        path: "../dashboard/employee/profile"
    }
  ];



  export const SidebarAdmin = [
    {
        title: "Profilo",
        icon: "ti ti-user me-2",
        path: "../dashboard/admin/profile"
    },
    {
        title: "Messaggi",
        icon: "ti ti-mail-opened me-2",
        path: "../dashboard/admin/messages"
    },
    {
        title: "Utenti",
        icon: "ti ti-user me-2",
        path: "../dashboard/admin/users"
    },
    {
        title: "Contenuto pagine",
        icon: "ti ti-file-info me-2",
        childrens: [
            {
                title: "Footer",
                path: "../dashboard/admin/info-footer"
            },
            {
                title: "Home",
                path: "../dashboard/admin/info-home"
            },
            {
                title: "Contattaci",
                path: "../dashboard/admin/info-contact-us"
            },
            {
                title: "Chi siamo",
                path: "../dashboard/admin/info-about-us"
            },
            {
                title: "Portfolio",
                path: "../dashboard/admin/info-portfolio"
            }
        ]
    }

  ];


  