import React from "react";
import { Component } from "react";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import { ColumnsType, ColumnType, FilterConfirmProps, SorterResult, TableRowSelection } from "antd/es/table/interface";
import { Button, Input, InputRef, Space, Table, TableProps, Tag } from "antd";
import { DeleteOutlined, EditOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import SkeletonTable, { SkeletonTableColumnsType } from "../../common/dashboard/animation/skeletonTable";
import { Modal } from "react-bootstrap";
import PreviewImage from "./element/previewImage";
import aboutusService from "../../../api/page/aboutus/aboutus.service";


type Props = {};

type State = {
  successful: boolean;
  message: string;
  successfulAdd:boolean,
  messageAdd:string,
  successfulDelete:boolean,
  messageDelete:string,
  successfulUpdate:boolean,
  messageUpdate:string,
  settingItems:{
    featuresinfo:boolean,
    teaminfo:boolean
  },
  memberInfo:{
    image:string,
    name:string,
    surname:string,
    role:string,
    facebookinfo:boolean,
    facebooklink:string,
    instagraminfo:boolean,
    instagramlink:string,
    twitterinfo:boolean,
    twitterlink:string,
    linkedininfo:boolean,
    linkedinlink:string
  },
  loading:boolean,
  members:any[],
  file:string,
  deleteId:number,
  updateId:number,
  modalAdd:boolean,
  modalUpdate:boolean,
  modalDelete:boolean,
  modalSetting:boolean,
  searchText:string;
  searchColumn:string;
  searchTimeText;
  searchTimeRange;
  searchTimeRangeColumn: string;
  selectedRowKeys: React.Key[];
  filteredInfo;
  sortedInfo;
  columnIndex;
};

export default class InfoAboutUs extends Component<Props, State> {
  private searchInput = React.createRef<InputRef>();

  constructor(props: Props) {
    super(props);

    this.state = {
      successful: false,
      message: "",
      successfulAdd:false,
      messageAdd:'',
      successfulDelete:false,
      messageDelete:'',
      successfulUpdate:false,
      messageUpdate:'',
      settingItems:{
        featuresinfo:false,
        teaminfo:false
      },
      memberInfo:{
        image:"",
        name:"",
        surname:"",
        role:"",
        facebookinfo:false,
        facebooklink:"",
        instagraminfo:false,
        instagramlink:"",
        twitterinfo:false,
        twitterlink:"",
        linkedininfo:false,
        linkedinlink:""
      },
      loading:true,
      members:[],
      file:'',
      deleteId: 0,
      updateId: 0,
      modalAdd:false,
      modalUpdate:false,
      modalDelete:false,
      modalSetting:false,
      searchText: "",
      searchColumn: "",
      searchTimeText: null,
      searchTimeRange: null,
      searchTimeRangeColumn: "",
      selectedRowKeys: [],
      filteredInfo: null,
      sortedInfo: null,
      columnIndex: null,
    };
  }


  setDeleteId = (id) =>{
    this.setState({
      deleteId: id
    })
  }

  setUpdateId = (id) =>{
    this.setState({
      updateId: id
    })
  }


  setMemberInfo = (record) =>{
    this.setState({
      memberInfo:{
        image:'',
        name:record.name,
        surname:record.surname,
        role:record.role,
        facebookinfo:record.facebookinfo,
        facebooklink:record.facebooklink,
        instagraminfo:record.instagraminfo,
        instagramlink:record.instagramlink,
        twitterinfo:record.twitterinfo,
        twitterlink:record.twitterlink,
        linkedininfo:record.linkedininfo,
        linkedinlink:record.linkedinlink

      }
    })
  }



  setModalAdd = (state) =>{
    this.setState({
      modalAdd:state
    })
  }

  setModalUpdate = (state) =>{
    this.setState({
      modalUpdate:state
    })
  }

  setModalDelete = (state) =>{
    this.setState({
      modalDelete:state
    })
  }



  setModalSetting = (state) =>{
    this.setState({
      modalSetting:state
    })
  }
 
  validationSchemaAdd() {
    const supported_formats = ["image/jpg" , "image/jpeg" , "image/png" , "image/svg" , "image/ips" , "image/psd" , "image/ai"]
    return yup.object().shape({
      image: yup.mixed().nullable().required("inserisci un file di immagine").test("type","Il file caricato non è un'immaggine", (values) => !values|| (values && supported_formats.includes(values['type']))),
      name: yup.string().required("inserisci un nome"),
      surname: yup.string().required("inserisci un cognome"),
      role: yup.string().required("inserisci un ruolo"),
      facebooklink: yup.string().required("inserisci un link valido"),
      instagramlink: yup.string().required("inserisci un link valido"),
      twitterlink: yup.string().required("inserisci un link valido"),
      linkedinlink: yup.string().required("inserisci un link valido"),
    });
  }

  validationSchemaUpdate() {
    const supported_formats = ["image/jpg" , "image/jpeg" , "image/png" , "image/svg" , "image/ips" , "image/psd" , "image/ai"]
    return yup.object().shape({
      image: yup.mixed().nullable().required("inserisci un file di immagine").test("type","Il file caricato non è un'immaggine", (values) => !values|| (values && supported_formats.includes(values['type']))),
      name: yup.string().required("inserisci un nome"),
      surname: yup.string().required("inserisci un cognome"),
      role: yup.string().required("inserisci un ruolo"),
      facebooklink: yup.string().required("inserisci un link valido"),
      instagramlink: yup.string().required("inserisci un link valido"),
      twitterlink: yup.string().required("inserisci un link valido"),
      linkedinlink: yup.string().required("inserisci un link valido"),
    });
  }



  validationSchemaSetting() {
    return yup.object().shape({
      
    });
  }





  getAllSettings = async() =>{

    aboutusService.findAll(
      ).then(
        response => {
  

         this.setState({
           settingItems:{
            featuresinfo:response.data[0].featuresinfo,
            teaminfo:response.data[0].teaminfo
           }
         })

          
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
      }
  
      );
  }

  updateSetting = async(formValue:{featuresinfo:boolean,teaminfo:boolean}) =>{
    const { 
      featuresinfo,
      teaminfo
       } = formValue

       aboutusService.update(
        1,
        featuresinfo,
        teaminfo
        ).then(
          response => {
    
  
           this.getAllSettings()
           this.setModalSetting(false)
            
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
    
        }
    
        );

  }

  getAllMembers = async() =>{

    aboutusService.findAllMembers(
      ).then(
        response => {
  

         this.setState({
           members:response.data,
         })

         setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 500);

          
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
      }
  
      );
  }

  getImageMember = async(id)=>{

    this.setState({
      file:''
    })


    aboutusService.getImageMember(
      id
      ).then(
        response => {

         this.setState({
           file:response.data
         })

        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
      }
  
      );

  }

  addMember = async(formValue:{image,name:string,surname:string,role:string,facebookinfo:boolean,facebooklink:string,instagraminfo:boolean,instagramlink:string,twitterinfo:boolean,twitterlink:string,linkedininfo:boolean,linkedinlink:string}) =>{
    const { 
      image,
      name,
      surname,
      role,
      facebookinfo,
      facebooklink,
      instagraminfo,
      instagramlink,
      twitterinfo,
      twitterlink,
      linkedininfo,
      linkedinlink,
       } = formValue

       

      this.setState({
        messageAdd:'',
        successfulAdd:false
      })


      aboutusService.createMember(
        name,
        surname,
        role,
        facebookinfo,
        facebooklink,
        instagraminfo,
        instagramlink,
        twitterinfo,
        twitterlink,
        linkedininfo,
        linkedinlink
        ).then(
          response => {
            
  
            aboutusService.createImageMember(
              image,
              response.data.id
             ).then(
               response => {
         
                 this.setState({
                   successfulAdd:true
                 })
     
                 this.getAllMembers()
     
                 this.setModalAdd(false)
                 
               },
               error => {
                 const resMessage =
                   (error.response &&
                     error.response.data &&
                     error.response.data.message) ||
                   error.message ||
                   error.toString();
         
             }
         
             );
            
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
    
        }
    
        )

  }


  updateMember = async(formValue:{image,name:string,surname:string,role:string,facebookinfo:boolean,facebooklink:string,instagraminfo:boolean,instagramlink:string,twitterinfo:boolean,twitterlink:string,linkedininfo:boolean,linkedinlink:string}) =>{

    const { 
      image,
      name,
      surname,
      role,
      facebookinfo,
      facebooklink,
      instagraminfo,
      instagramlink,
      twitterinfo,
      twitterlink,
      linkedininfo,
      linkedinlink,
       } = formValue

       
      this.setState({
        messageUpdate:'',
        successfulUpdate:false
      })


      aboutusService.updateMember(
        this.state.updateId,
        name,
        surname,
        role,
        facebookinfo,
        facebooklink,
        instagraminfo,
        instagramlink,
        twitterinfo,
        twitterlink,
        linkedininfo,
        linkedinlink
        ).then(
          response => {
            
  
            aboutusService.updateImageMember(
              image,
              this.state.updateId,
             ).then(
               response => {
         
                 this.setState({
                   successfulUpdate:true
                 })
     
                 this.getAllMembers()
     
                 this.setModalUpdate(false)
                 
               },
               error => {
                 const resMessage =
                   (error.response &&
                     error.response.data &&
                     error.response.data.message) ||
                   error.message ||
                   error.toString();
         
             }
         
             );
            
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
    
        }
    
        )


  }


  deleteMember = async() => {


    this.setState({
      messageDelete:'',
      successfulDelete:false
    })

          

          aboutusService.deleteMember(
            this.state.deleteId
           ).then(
             response => {
       
               this.setState({
                 successfulDelete:true
               })
   
               this.getAllMembers()
   
               this.setModalDelete(false)
               
             },
             error => {
               const resMessage =
                 (error.response &&
                   error.response.data &&
                   error.response.data.message) ||
                 error.message ||
                 error.toString();
       
           }
       
           );
          
      
  }

  componentDidMount(){
    this.getAllSettings()
    this.getAllMembers()
  }


  render() {
    const {
      successful,
      message,
      successfulAdd,
      messageAdd,
      successfulUpdate,
      messageUpdate,
      selectedRowKeys,
      filteredInfo,
      sortedInfo,
    } = this.state;


    const initialValuesAdd = {
      image:"",
      name:"",
      surname:"",
      role:"",
      facebookinfo:false,
      facebooklink:"",
      instagraminfo:false,
      instagramlink:"",
      twitterinfo:false,
      twitterlink:"",
      linkedininfo:false,
      linkedinlink:""
    };

    const initialValuesUpdate = {
      image:this.state.file,
      name:this.state.memberInfo.name,
      surname:this.state.memberInfo.surname,
      role:this.state.memberInfo.role,
      facebookinfo:this.state.memberInfo.facebookinfo,
      facebooklink:this.state.memberInfo.facebooklink,
      instagraminfo:this.state.memberInfo.instagraminfo,
      instagramlink:this.state.memberInfo.instagramlink,
      twitterinfo:this.state.memberInfo.twitterinfo,
      twitterlink:this.state.memberInfo.twitterlink,
      linkedininfo:this.state.memberInfo.linkedininfo,
      linkedinlink:this.state.memberInfo.linkedinlink,
    };

    const initialValuesSetting = {
      featuresinfo:this.state.settingItems.featuresinfo,
      teaminfo:this.state.settingItems.teaminfo
      
    };

    interface DataType {
      key: string;
      id: number;
      name: string;
      surname: string;
      role:string,
      facebookinfo: boolean;
      facebook:string,
      facebooklink: string;
      instagraminfo: boolean,
      instagram:string,
      instagramlink: string,
      twitterinfo: boolean,
      twitter:string,
      twitterlink: string,
      linkedininfo: boolean,
      linkedin:string,
      linkedinlink: string,
      members: any[];
    }

    type DataIndex = keyof DataType;

    const handleChange: TableProps<DataType>["onChange"] = (
      pagination,
      filters,
      sorter
    ) => {
      //console.log('Various parameters', pagination, filters, sorter)
      this.setState({
        filteredInfo: filters,
        sortedInfo: sorter as SorterResult<DataType>,
      });
    };

    const clearAll = () => {
      this.setState({ filteredInfo: {}, sortedInfo: {} });
    };

    const handleSearch = (
      selectedKeys: string[],
      confirm: (param?: FilterConfirmProps) => void,
      dataIndex: DataIndex
    ) => {
      confirm();
      this.setState({ searchText: selectedKeys[0], searchColumn: dataIndex });
    };

    const handleReset = (clearFilters: () => void) => {
      clearFilters();
      this.setState({ searchText: "" });
    };

    const getColumnSearchProps = (dataIndex): ColumnType<DataType> => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={this.searchInput}
            placeholder={`Cerca ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Cerca
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Resetta
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                this.setState({
                  searchText: (selectedKeys as string[])[0],
                  searchColumn: dataIndex,
                });
              }}
            >
              Filtra
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              Chiudi
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filteredValue: filteredInfo != null ? filteredInfo.body || null : null,
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => this.searchInput.current?.select(), 100);
        }
      },
      sortOrder:
        sortedInfo != null
          ? sortedInfo.columnKey === dataIndex
            ? sortedInfo.order
            : null
          : null,
      render: (text) =>
        this.state.searchColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    });

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
      //console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      
    };

    const rowSelection: TableRowSelection<DataType> = {
      selectedRowKeys,
      onChange: onSelectChange,
      selections: [
        Table.SELECTION_ALL,
        Table.SELECTION_INVERT,
        Table.SELECTION_NONE,
      ],
    };

    const columns: ColumnsType<DataType> = [
      {
        key: "name",
        title: "Nome",
        dataIndex: ['name'],
        align: "center",
        responsive: ["md", "sm", "xs"],
        sorter: (a, b) => a.name.length - b.name.length,
        sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "name"  ? sortedInfo.order : null : null,
      },
      {
        key:"surname",
        title: "Cognome",
        dataIndex: ['surname'],
        align: "center",
        responsive: ["md", "sm", "xs"],
        sorter: (a, b) => a.surname.length - b.surname.length,
        sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "surname"  ? sortedInfo.order : null : null,
      },
      {
        key:"role",
        title: "Ruolo",
        dataIndex: ['role'],
        align: "center",
        responsive: ["md", "sm", "xs"],
        sorter: (a, b) => a.role.length - b.role.length,
        sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "role"  ? sortedInfo.order : null : null,
      },
      {
        key:"facebookinfo",
        title: "Info facebook",
        dataIndex: ['facebookinfo'],
        align: "center",
        responsive: ["md", "sm", "xs"],
        render: (_, record) => {
          
          var color: string = ''

          if(record.facebookinfo){
            record.facebook = 'Attivo'
            color = 'green'
          }else{
            record.facebook = 'Disattivo'
            color = 'red'
          }

          return(
            <>
             <Tag color={color}>{record.facebook}</Tag>
            </>
          )
        }
      },
      {
        key:"facebooklink",
        title: "Link Facebook",
        dataIndex: ['facebooklink'],
        align: "center",
        responsive: ["md", "sm", "xs"],
        sorter: (a, b) => a.facebooklink.length - b.facebooklink.length,
        sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "facebooklink"  ? sortedInfo.order : null : null,
      },
      {
        key:"instagraminfo",
        title: "Info Instagram",
        dataIndex: ['instagraminfo'],
        align: "center",
        responsive: ["md", "sm", "xs"],
        render: (_, record) => {
          
          var color: string = ''

          if(record.instagraminfo){
            record.instagram = 'Attivo'
            color = 'green'
          }else{
            record.instagram = 'Disattivo'
            color = 'red'
          }

          return(
            <>
             <Tag color={color}>{record.instagram}</Tag>
            </>
          )
        }
      },
      {
        key:"instagramlink",
        title: "Link Instagram",
        dataIndex: ['instagramlink'],
        align: "center",
        responsive: ["md", "sm", "xs"],
        sorter: (a, b) => a.instagramlink.length - b.instagramlink.length,
        sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "instagramlink"  ? sortedInfo.order : null : null,
      },
      {
        key:"twitterinfo",
        title: "Info Twitter",
        dataIndex: ['twitterinfo'],
        align: "center",
        responsive: ["md", "sm", "xs"],
        render: (_, record) => {
          
          var color: string = ''

          if(record.twitterinfo){
            record.twitter = 'Attivo'
            color = 'green'
          }else{
            record.twitter = 'Disattivo'
            color = 'red'
          }

          return(
            <>
             <Tag color={color}>{record.twitter}</Tag>
            </>
          )
        }
      },
      {
        key:"twitterlink",
        title: "Link Twitter",
        dataIndex: ['twitterlink'],
        align: "center",
        responsive: ["md", "sm", "xs"],
        sorter: (a, b) => a.twitterlink.length - b.twitterlink.length,
        sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "twitterlink"  ? sortedInfo.order : null : null,
      },
      {
        key:"linkedininfo",
        title: "Info Linkedin",
        dataIndex: ['linkedininfo'],
        align: "center",
        responsive: ["md", "sm", "xs"],
        render: (_, record) => {
          
          var color: string = ''

          if(record.linkedininfo){
            record.linkedin = 'Attivo'
            color = 'green'
          }else{
            record.linkedin = 'Disattivo'
            color = 'red'
          }

          return(
            <>
             <Tag color={color}>{record.linkedin}</Tag>
            </>
          )
        }
      },
      {
        key:"linkedinlink",
        title: "Link Linkedin",
        dataIndex: ['linkedinlink'],
        align: "center",
        responsive: ["md", "sm", "xs"],
        sorter: (a, b) => a.linkedinlink.length - b.linkedinlink.length,
        sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "linkedinlink"  ? sortedInfo.order : null : null,
      },
      {
        title: "Azione",
        dataIndex: "azione",
        align: "center",
        responsive: ["md", "sm", "xs"],
        render: (_, record) => {
          return <>
          <Button
          onClick={() => [
            this.setModalUpdate(true),
            this.setUpdateId(record.id),
            this.setMemberInfo(record),
            this.getImageMember(record.id)
          ]}
          type="primary"
          style={{
            width: "40px",
            height: "40px",
            alignContent: "center",
          }}
          icon={<EditOutlined />}
        />
        <Button
          onClick={() => [
            this.setModalDelete(true),
            this.setDeleteId(record.id),
          ]}
          danger
          type="primary"
          style={{
            width: "40px",
            height: "40px",
            marginLeft: "20px",
            alignContent: "center",
          }}
          icon={<DeleteOutlined />}
          />
        </>;
        },
      },
    ];



    return (
      <>

<div className="container-fluid">
          <div className="layout-specing">
            <div className="d-md-flex justify-content-between align-items-center">
              <h5 className="mb-0">Info Chi siamo</h5>

              <nav aria-label="breadcrumb" className="d-inline-block">
                <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                  <li className="breadcrumb-item text-capitalize">
                    <a href="index.html">Contenuto pagine</a>
                  </li>
                  <li
                    className="breadcrumb-item text-capitalize active"
                    aria-current="page"
                  >
                    Info Chi siamo
                  </li>
                </ul>
              </nav>
            </div>

            <div className="row">
              <div className="col-12 mt-4"></div>
            </div>

            <div className="row text-center">

            <Space style={{ marginBottom: 16 }}>
              <div className="mobile-sm">
                <Button onClick={clearAll}>
                  Resetta ordinamenti,filtri e ricerca
                </Button>
              </div>
              <div className="mobile-sm">
                <Button type="dashed" icon={<SettingOutlined />} onClick={() => this.setModalSetting(true)}>
                  impostazioni pagina
                </Button>
              </div>
              <div className="mobile-sm">
                <Button type="primary" onClick={() => this.setModalAdd(true)}>
                  Aggiungi membro
                </Button>
              </div>
          </Space>

              <SkeletonTable
                loading={this.state.loading}
                columns={columns as SkeletonTableColumnsType[]}
              >
                <Table
                  columns={columns}
                  dataSource={this.state.members}
                  onChange={handleChange}
                  rowKey={(record) => record.id}
                  //rowSelection={rowSelection}
                  pagination={{
                    pageSize: 10,
                  }}
                  scroll={{ x: 600 }}
                />
              </SkeletonTable>
            </div>


            <Modal
                show={this.state.modalSetting}
                onHide={() => this.setModalSetting(false)}
                backdrop="static"
                keyboard={false}
            >
        
          
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValuesSetting}
                    validationSchema={this.validationSchemaSetting}
                    onSubmit={this.updateSetting}
                  >
      
           {formik => (
         

          <Form>
            <Modal.Header >
              <h5 className="modal-title" id="createModalLabel">
                <i className="ri-mark-pen-line align-middle me-2"></i> Impostazioni pagina
              </h5>
            </Modal.Header>
            <Modal.Body className="modal-body">
              <div className="container d-flex align-items-center justify-content-center">
                {messageAdd && (
                  <div className="form-group">
                    <div
                      className={
                        successful
                          ? "alert alert-success  text-center"
                          : "alert alert-danger text-center "
                      }
                      role="alert"
                    >
                      <strong>
                        {successful ? "Successo - " : "Errore - "}
                      </strong>
                      {messageAdd}
                    </div>
                  </div>
                )}
              </div>

                          <div style={{marginTop:'10px'}} className="form-check form-switch">
                                            <div >
                                              <Field 
                                                name="featuresinfo"
                                                className="form-check-input" 
                                                type="checkbox"
                                                />
                                              </div>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Abilita info skills</label>
                          </div>

                          
                          <div style={{marginTop:'25px'}} className="form-check form-switch">
                                            <div >
                                              <Field 
                                                name="teaminfo"
                                                className="form-check-input" 
                                                type="checkbox"
                                                />
                                              </div>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Abilita info membri team</label>
                          </div>

                        
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.setModalSetting(false)}>Chiudi</Button>
              
                <Button type="primary" htmlType="submit">
                  Salva
                </Button>
             
            </Modal.Footer>
          </Form>
        )}
      </Formik>
        </Modal>


            <Modal
             show={this.state.modalDelete}
             onHide={() => this.setModalDelete(false)}
           >
          <Modal.Header
            className="modal-header modal-colored-header bg-danger text-white"
          >
            <h5 className="modal-title" id="danger-header-modalLabel">
              Elimina membro
            </h5>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <p>Sei sicuro di voler eliminare questo membro del team?</p>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <Button onClick={() => this.setModalDelete(false)}>Annulla</Button>
            <Button danger onClick={() => this.deleteMember()}>
              Elimina membro
            </Button>
          </Modal.Footer>
        </Modal>



            <Modal
                show={this.state.modalAdd}
                onHide={() => this.setModalAdd(false)}
                backdrop="static"
                keyboard={false}
            >
        
          
                <Formik
                    initialValues={initialValuesAdd}
                    validationSchema={this.validationSchemaAdd}
                    onSubmit={this.addMember}
                  >
      
           {formik => (
         

          <Form>
            <Modal.Header >
              <h5 className="modal-title" id="createModalLabel">
                <i className="ri-mark-pen-line align-middle me-2"></i> Aggiungi nuovo membro
              </h5>
            </Modal.Header>
            <Modal.Body className="modal-body">
              <div className="container d-flex align-items-center justify-content-center">
                {messageAdd && (
                  <div className="form-group">
                    <div
                      className={
                        successfulAdd
                          ? "alert alert-success  text-center"
                          : "alert alert-danger text-center "
                      }
                      role="alert"
                    >
                      <strong>
                        {successfulAdd ? "Successo - " : "Errore - "}
                      </strong>
                      {messageAdd}
                    </div>
                  </div>
                )}
              </div>

                                <div className="d-grid me-md-4">
                                    <p className="text-muted">Carica un'immagine qui. Clicca sul pulsante "Carica immagine".</p>
                                    {formik.values.image != '' ? <PreviewImage file={formik.values.image}/> : null}
                                    <input type="file" id="input-file" name="image" accept="image/*"  hidden
                                    onChange={(event) => {
                                      //console.log(event.target.files)
                                      formik.setFieldValue("image", event.target.files![0] )
                                    }}
                                    className={(formik.touched.image && formik.errors.image) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.image && formik.errors.image ? (
                                        <div className="invalid-feedback">{formik.errors.image}</div>
                                    ) : null}
                                    <label className="btn-upload btn btn-primary mt-4" htmlFor="input-file">Carica immagine</label>
                                </div>

                              <div className="mb-3">
                                  <label className="form-label">
                                    Nome{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="name"
                                      type="text"
                                      placeholder="nome"
                                      className={(formik.touched.name && formik.errors.name) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.name && formik.errors.name ? (
                                        <div className="invalid-feedback">{formik.errors.name}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                    Cognome{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="surname"
                                      type="text"
                                      placeholder="Cognome"
                                      className={(formik.touched.surname && formik.errors.surname) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.surname && formik.errors.surname ? (
                                        <div className="invalid-feedback">{formik.errors.surname}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                    Ruolo{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="role"
                                      type="text"
                                      placeholder="Ruolo"
                                      className={(formik.touched.role && formik.errors.role) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.role && formik.errors.role ? (
                                        <div className="invalid-feedback">{formik.errors.role}</div>
                                    ) : null} 
                             </div>
                          </div>

                          <div style={{marginTop:'25px'}} className="form-check form-switch">
                                            <div >
                                              <Field 
                                                name="facebookinfo"
                                                className="form-check-input" 
                                                type="checkbox"
                                                />
                                              </div>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Abilita profilo facebook</label>
                          </div>

                          <div className="col-12">
                                                <label  className="form-label">Link</label>
                                                <Field
                                                name="facebooklink"
                                                type="text" 
                                                placeholder="inserisci un link  valido" 
                                                className={(formik.touched.facebooklink && formik.errors.facebooklink) ? 'form-control is-invalid' : 'form-control'} 
                                                /> 
                                              {formik.touched.facebooklink && formik.errors.facebooklink ? (
                                                   <div className="invalid-feedback">{formik.errors.facebooklink}</div>
                                                ) : null} 
                          </div>

                         

                          <div style={{marginTop:'25px'}} className="form-check form-switch">
                                            <div >
                                              <Field 
                                                name="instagraminfo"
                                                className="form-check-input" 
                                                type="checkbox"
                                                />
                                              </div>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Abilita profilo instagram</label>
                          </div>

                          <div className="col-12">
                                                <label  className="form-label">Link</label>
                                                <Field
                                                name="instagramlink"
                                                type="text" 
                                                placeholder="inserisci un link  valido" 
                                                className={(formik.touched.instagramlink && formik.errors.instagramlink) ? 'form-control is-invalid' : 'form-control'} 
                                                /> 
                                              {formik.touched.instagramlink && formik.errors.instagramlink ? (
                                                   <div className="invalid-feedback">{formik.errors.instagramlink}</div>
                                                ) : null} 
                          </div>

                          <div style={{marginTop:'25px'}} className="form-check form-switch">
                                            <div >
                                              <Field 
                                                name="twitterinfo"
                                                className="form-check-input" 
                                                type="checkbox"
                                                />
                                              </div>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Abilita profilo twitter</label>
                          </div>

                          <div className="col-12">
                                                <label  className="form-label">Link</label>
                                                <Field
                                                name="twitterlink"
                                                type="text" 
                                                placeholder="inserisci un link  valido" 
                                                className={(formik.touched.twitterlink && formik.errors.twitterlink) ? 'form-control is-invalid' : 'form-control'} 
                                                /> 
                                              {formik.touched.twitterlink && formik.errors.twitterlink ? (
                                                   <div className="invalid-feedback">{formik.errors.twitterlink}</div>
                                                ) : null} 
                          </div>

                          <div style={{marginTop:'25px'}} className="form-check form-switch">
                                            <div >
                                              <Field 
                                                name="linkedininfo"
                                                className="form-check-input" 
                                                type="checkbox"
                                                />
                                              </div>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Abilita profilo linkedin</label>
                          </div>

                          <div className="col-12">
                                                <label  className="form-label">Link</label>
                                                <Field
                                                name="linkedinlink"
                                                type="text" 
                                                placeholder="inserisci un link  valido" 
                                                className={(formik.touched.linkedinlink && formik.errors.linkedinlink) ? 'form-control is-invalid' : 'form-control'} 
                                                /> 
                                              {formik.touched.linkedinlink && formik.errors.linkedinlink ? (
                                                   <div className="invalid-feedback">{formik.errors.linkedinlink}</div>
                                                ) : null} 
                          </div>
              
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.setModalAdd(false)}>Chiudi</Button>
              
                <Button type="primary" htmlType="submit">
                  Aggiungi
                </Button>
             
            </Modal.Footer>
          </Form>
        )}
      </Formik>
        </Modal>








             <Modal
                show={this.state.modalUpdate}
                onHide={() => this.setModalUpdate(false)}
                backdrop="static"
                keyboard={false}
            >
        
          
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValuesUpdate}
                    validationSchema={this.validationSchemaUpdate}
                    onSubmit={this.updateMember}
                  >
      
           {formik => (
         

          <Form>
            <Modal.Header >
              <h5 className="modal-title" id="createModalLabel">
                <i className="ri-mark-pen-line align-middle me-2"></i> Modifica membro
              </h5>
            </Modal.Header>
            <Modal.Body className="modal-body">
              <div className="container d-flex align-items-center justify-content-center">
                {messageUpdate && (
                  <div className="form-group">
                    <div
                      className={
                        successfulUpdate
                          ? "alert alert-success  text-center"
                          : "alert alert-danger text-center "
                      }
                      role="alert"
                    >
                      <strong>
                        {successfulUpdate ? "Successo - " : "Errore - "}
                      </strong>
                      {messageUpdate}
                    </div>
                  </div>
                )}
              </div>

                                <div className="d-grid me-md-4">
                                    <p className="text-muted">Carica un'immagine qui. Clicca sul pulsante "Carica immagine".</p>
                                    {formik.values.image != '' ? <PreviewImage file={formik.values.image}/> : null}
                                    <input type="file" id="input-file" name="image" accept="image/*"  hidden
                                    onChange={(event) => {
                                      //console.log(event.target.files)
                                      formik.setFieldValue("image", event.target.files![0] )
                                    }}
                                    className={(formik.touched.image && formik.errors.image) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.image && formik.errors.image ? (
                                        <div className="invalid-feedback">{formik.errors.image}</div>
                                    ) : null}
                                    <label className="btn-upload btn btn-primary mt-4" htmlFor="input-file">Carica immagine</label>
                                </div>

                              <div className="mb-3">
                                  <label className="form-label">
                                    Nome{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="name"
                                      type="text"
                                      placeholder="nome"
                                      className={(formik.touched.name && formik.errors.name) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.name && formik.errors.name ? (
                                        <div className="invalid-feedback">{formik.errors.name}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                    Cognome{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="surname"
                                      type="text"
                                      placeholder="Cognome"
                                      className={(formik.touched.surname && formik.errors.surname) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.surname && formik.errors.surname ? (
                                        <div className="invalid-feedback">{formik.errors.surname}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                    Ruolo{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="role"
                                      type="text"
                                      placeholder="Ruolo"
                                      className={(formik.touched.role && formik.errors.role) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.role && formik.errors.role ? (
                                        <div className="invalid-feedback">{formik.errors.role}</div>
                                    ) : null} 
                             </div>
                          </div>

                          <div style={{marginTop:'25px'}} className="form-check form-switch">
                                            <div >
                                              <Field 
                                                name="facebookinfo"
                                                className="form-check-input" 
                                                type="checkbox"
                                                />
                                              </div>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Abilita profilo facebook</label>
                          </div>

                          <div className="col-12">
                                                <label  className="form-label">Link</label>
                                                <Field
                                                name="facebooklink"
                                                type="text" 
                                                placeholder="inserisci un link  valido" 
                                                className={(formik.touched.facebooklink && formik.errors.facebooklink) ? 'form-control is-invalid' : 'form-control'} 
                                                /> 
                                              {formik.touched.facebooklink && formik.errors.facebooklink ? (
                                                   <div className="invalid-feedback">{formik.errors.facebooklink}</div>
                                                ) : null} 
                          </div>

                         

                          <div style={{marginTop:'25px'}} className="form-check form-switch">
                                            <div >
                                              <Field 
                                                name="instagraminfo"
                                                className="form-check-input" 
                                                type="checkbox"
                                                />
                                              </div>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Abilita profilo instagram</label>
                          </div>

                          <div className="col-12">
                                                <label  className="form-label">Link</label>
                                                <Field
                                                name="instagramlink"
                                                type="text" 
                                                placeholder="inserisci un link  valido" 
                                                className={(formik.touched.instagramlink && formik.errors.instagramlink) ? 'form-control is-invalid' : 'form-control'} 
                                                /> 
                                              {formik.touched.instagramlink && formik.errors.instagramlink ? (
                                                   <div className="invalid-feedback">{formik.errors.instagramlink}</div>
                                                ) : null} 
                          </div>

                          <div style={{marginTop:'25px'}} className="form-check form-switch">
                                            <div >
                                              <Field 
                                                name="twitterinfo"
                                                className="form-check-input" 
                                                type="checkbox"
                                                />
                                              </div>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Abilita profilo twitter</label>
                          </div>

                          <div className="col-12">
                                                <label  className="form-label">Link</label>
                                                <Field
                                                name="twitterlink"
                                                type="text" 
                                                placeholder="inserisci un link  valido" 
                                                className={(formik.touched.twitterlink && formik.errors.twitterlink) ? 'form-control is-invalid' : 'form-control'} 
                                                /> 
                                              {formik.touched.twitterlink && formik.errors.twitterlink ? (
                                                   <div className="invalid-feedback">{formik.errors.twitterlink}</div>
                                                ) : null} 
                          </div>

                          <div style={{marginTop:'25px'}} className="form-check form-switch">
                                            <div >
                                              <Field 
                                                name="linkedininfo"
                                                className="form-check-input" 
                                                type="checkbox"
                                                />
                                              </div>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Abilita profilo linkedin</label>
                          </div>

                          <div className="col-12">
                                                <label  className="form-label">Link</label>
                                                <Field
                                                name="linkedinlink"
                                                type="text" 
                                                placeholder="inserisci un link  valido" 
                                                className={(formik.touched.linkedinlink && formik.errors.linkedinlink) ? 'form-control is-invalid' : 'form-control'} 
                                                /> 
                                              {formik.touched.linkedinlink && formik.errors.linkedinlink ? (
                                                   <div className="invalid-feedback">{formik.errors.linkedinlink}</div>
                                                ) : null} 
                          </div>
              
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => [this.setModalUpdate(false)]}>Chiudi</Button>
              
                <Button type="primary" htmlType="submit">
                  Modifica
                </Button>
             
            </Modal.Footer>
          </Form>
        )}
      </Formik>
        </Modal>


        
          </div>
        </div>

      </>
    );
  }
}
