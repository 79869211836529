import React, { StyleHTMLAttributes } from "react";
import * as yup from "yup";
import { Component } from "react";
import { ColumnType, ColumnsType, FilterConfirmProps, SorterResult, TableRowSelection } from "antd/es/table/interface";
import { Button, Input, Space, Table, TableProps, Tag } from "antd";
import { DeleteOutlined, EditOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Modal } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import PreviewImage from "./element/previewImage";
import SkeletonTable, { SkeletonTableColumnsType } from "../../common/dashboard/animation/skeletonTable";
import homeService from "../../../api/page/home/home.service";


type Props = {};

type State = {
    successful: boolean;
    message: string;
    successfulAdd:boolean,
    messageAdd:string,
    successfulDelete:boolean,
    messageDelete:string,
    successfulUpdate:boolean,
    messageUpdate:string,
    settingItems:{
      frameworkinfo:boolean
    },
    frameworkInfo:{
      image:string,
      name:string,
      language:string,
      linkdocsinfo:boolean,
      linkdocs:string
    },
    loading:boolean,
    frameworks:any[],
    file:string,
    deleteId:number,
    updateId:number,
    modalAdd:boolean,
    modalUpdate:boolean,
    modalDelete:boolean,
    modalSetting:boolean,
    searchText:string;
    searchColumn:string;
    searchTimeText;
    searchTimeRange;
    searchTimeRangeColumn: string;
    selectedRowKeys: React.Key[];
    filteredInfo;
    sortedInfo;
    columnIndex;
};

export default class InfoHome extends Component<Props, State> {
  searchInput: any;

  constructor(props: Props) {
    super(props);
    

    this.state = {
      successful: false,
      message: "",
      successfulAdd:false,
      messageAdd:'',
      successfulDelete:false,
      messageDelete:'',
      successfulUpdate:false,
      messageUpdate:'',
      settingItems:{
        frameworkinfo:true
      },
      frameworkInfo:{
        image:"",
        name:"",
        language:"",
        linkdocsinfo:true,
        linkdocs:""
      },
      loading:true,
      frameworks:[],
      file:'',
      deleteId: 0,
      updateId: 0,
      modalAdd:false,
      modalUpdate:false,
      modalDelete:false,
      modalSetting:false,
      searchText: "",
      searchColumn: "",
      searchTimeText: null,
      searchTimeRange: null,
      searchTimeRangeColumn: "",
      selectedRowKeys: [],
      filteredInfo: null,
      sortedInfo: null,
      columnIndex: null,
    };
  }


  setDeleteId = (id) =>{
    this.setState({
      deleteId: id
    })
  }

  setUpdateId = (id) =>{
    this.setState({
      updateId: id
    })
  }


  setFrameworkInfo = (record) =>{
    this.setState({
       frameworkInfo:{
        image:'',
        name:record.name,
        language:record.language,
        linkdocsinfo:record.linkdocsinfo,
        linkdocs:record.linkdocs
       }
    })
  }



  setModalAdd = (state) =>{
    this.setState({
      modalAdd:state
    })
  }

  setModalUpdate = (state) =>{
    this.setState({
      modalUpdate:state
    })
  }

  setModalDelete = (state) =>{
    this.setState({
      modalDelete:state
    })
  }



  setModalSetting = (state) =>{
    this.setState({
      modalSetting:state
    })
  }
 
  validationSchemaAdd() {
    const supported_formats = ["image/jpg" , "image/jpeg" , "image/png" , "image/svg" , "image/ips" , "image/psd" , "image/ai"]
    return yup.object().shape({
      image: yup.mixed().nullable().required("inserisci un file di immagine").test("type","Il file caricato non è un'immaggine", (values) => !values|| (values && supported_formats.includes(values['type']))),
      name: yup.string().required("inserisci un nome"),
      language: yup.string().required("inserisci un linguaggio di programmazione"),
      linkdocs: yup.string().required("inserisci un link documentazione ufficiale"),
    });
  }

  validationSchemaUpdate() {
    const supported_formats = ["image/jpg" , "image/jpeg" , "image/png" , "image/svg" , "image/ips" , "image/psd" , "image/ai"]
    return yup.object().shape({
      image: yup.mixed().nullable().required("inserisci un file di immagine").test("type","Il file caricato non è un'immaggine", (values) => !values|| (values && supported_formats.includes(values['type']))),
      name: yup.string().required("inserisci un nome"),
      language: yup.string().required("inserisci un linguaggio di programmazione"),
      linkdocs: yup.string().required("inserisci un link documentazione ufficiale"),
    });
  }

  validationSchemaSetting() {
    return yup.object().shape({
      
    });
  }

  
  getAllSettings = async() =>{

    homeService.findAll(
      ).then(
        response => {
  

         this.setState({
           settingItems:{
            frameworkinfo:response.data[0].frameworksinfo,
           }
         })

          
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
      }
  
      );
  }

  updateSetting = async(formValue:{frameworkinfo:boolean}) =>{
    const { 
      frameworkinfo,
       } = formValue

       homeService.update(
        1,
        frameworkinfo
        ).then(
          response => {
    
  
           this.getAllSettings()
           this.setModalSetting(false)
            
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
    
        }
    
        );

  }

  getAllFramework = async() =>{

    homeService.findAllFramework(
      ).then(
        response => {
  

         this.setState({
           frameworks:response.data,
         })

         setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 500);

          
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
      }
  
      );
  }

  getImageFramework = async(id)=>{

    this.setState({
      file:''
    })


    homeService.getImageFramework(
      id
      ).then(
        response => {

         this.setState({
           file:response.data
         })

        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
      }
  
      );

  }

  addFramework = async(formValue:{image,name:string,language:string,linkdocsinfo:boolean,linkdocs:string}) =>{
    const { 
      image,
      name,
      language,
      linkdocsinfo,
      linkdocs
       } = formValue

       

      this.setState({
        messageAdd:'',
        successfulAdd:false
      })


      homeService.createFramework(
        name,
        language,
        linkdocsinfo,
        linkdocs
        ).then(
          response => {
            
  
            homeService.createImageFramework(
              image,
              response.data.id
             ).then(
               response => {
         
                 this.setState({
                   successfulAdd:true
                 })
     
                 this.getAllFramework()
     
                 this.setModalAdd(false)
                 
               },
               error => {
                 const resMessage =
                   (error.response &&
                     error.response.data &&
                     error.response.data.message) ||
                   error.message ||
                   error.toString();
         
             }
         
             );
            
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
    
        }
    
        )

  }


  updateFramework = async(formValue:{image,name:string,language:string,linkdocsinfo:boolean,linkdocs:string}) =>{

    const { 
      image,
      name,
      language,
      linkdocsinfo,
      linkdocs
       } = formValue

       
      this.setState({
        messageUpdate:'',
        successfulUpdate:false
      })


      homeService.updateFramework(
        this.state.updateId,
        name,
        language,
        linkdocsinfo,
        linkdocs
        ).then(
          response => {
            
  
            homeService.updateImageFramework(
              image,
              this.state.updateId,
             ).then(
               response => {
         
                 this.setState({
                   successfulUpdate:true
                 })
     
                 this.getAllFramework()
     
                 this.setModalUpdate(false)
                 
               },
               error => {
                 const resMessage =
                   (error.response &&
                     error.response.data &&
                     error.response.data.message) ||
                   error.message ||
                   error.toString();
         
             }
         
             );
            
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
    
        }
    
        )


  }


  deleteFramework = async() => {


    this.setState({
      messageDelete:'',
      successfulDelete:false
    })

          

          homeService.deleteFramework(
            this.state.deleteId
           ).then(
             response => {
       
               this.setState({
                 successfulDelete:true
               })
   
               this.getAllFramework()
   
               this.setModalDelete(false)
               
             },
             error => {
               const resMessage =
                 (error.response &&
                   error.response.data &&
                   error.response.data.message) ||
                 error.message ||
                 error.toString();
       
           }
       
           );
          
      
  }

  componentDidMount(){
      this.getAllSettings()
      this.getAllFramework()
  }


  render(){

    const {
        successful,
        message,
        successfulAdd,
        messageAdd,
        successfulUpdate,
        messageUpdate,
        selectedRowKeys,
        filteredInfo,
        sortedInfo,
      } = this.state;



      
    const initialValuesAdd = {
        image:"",
        name:"",
        language:"",
        linkdocsinfo:true,
        linkdocs:""
      };
  
      const initialValuesUpdate = {
        image:this.state.file,
        name:this.state.frameworkInfo.name,
        language:this.state.frameworkInfo.language,
        linkdocsinfo:this.state.frameworkInfo.linkdocsinfo,
        linkdocs:this.state.frameworkInfo.linkdocs
      };
  
      const initialValuesSetting = {
        frameworkinfo:this.state.settingItems.frameworkinfo,
      };

      interface DataType {
        key: string;
        id: number;
        name:string,
        language:string,
        linkdocsinfo:boolean,
        docs:string,
        linkdocs:string,
        frameworks: any[];
      }
  
      type DataIndex = keyof DataType;
  
      const handleChange: TableProps<DataType>["onChange"] = (
        pagination,
        filters,
        sorter
      ) => {
        //console.log('Various parameters', pagination, filters, sorter)
        this.setState({
          filteredInfo: filters,
          sortedInfo: sorter as SorterResult<DataType>,
        });
      };
  
      const clearAll = () => {
        this.setState({ filteredInfo: {}, sortedInfo: {} });
      };
  
      const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex
      ) => {
        confirm();
        this.setState({ searchText: selectedKeys[0], searchColumn: dataIndex });
      };
  
      const handleReset = (clearFilters: () => void) => {
        clearFilters();
        this.setState({ searchText: "" });
      };
  
      const getColumnSearchProps = (dataIndex): ColumnType<DataType> => ({
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={this.searchInput}
              placeholder={`Cerca ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              style={{ marginBottom: 8, display: "block" }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() =>
                  handleSearch(selectedKeys as string[], confirm, dataIndex)
                }
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Cerca
              </Button>
              <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
              >
                Resetta
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: (selectedKeys as string[])[0],
                    searchColumn: dataIndex,
                  });
                }}
              >
                Filtra
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  close();
                }}
              >
                Chiudi
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered: boolean) => (
          <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        filteredValue: filteredInfo != null ? filteredInfo.body || null : null,
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => this.searchInput.current?.select(), 100);
          }
        },
        sortOrder:
          sortedInfo != null
            ? sortedInfo.columnKey === dataIndex
              ? sortedInfo.order
              : null
            : null,
        render: (text) =>
          this.state.searchColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ""}
            />
          ) : (
            text
          ),
      });
  
      const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        //console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        
      };
  
      const rowSelection: TableRowSelection<DataType> = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
          Table.SELECTION_ALL,
          Table.SELECTION_INVERT,
          Table.SELECTION_NONE,
        ],
      };


      const columns: ColumnsType<DataType> = [
        {
          key: "name",
          title: "Nome",
          dataIndex: ['name'],
          align: "center",
          responsive: ["md", "sm", "xs"],
          sorter: (a, b) => a.name.length - b.name.length,
          sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "name"  ? sortedInfo.order : null : null,
        },
        {
          key:"language",
          title: "Linguaggio",
          dataIndex: ['language'],
          align: "center",
          responsive: ["md", "sm", "xs"],
          sorter: (a, b) => a.language.length - b.language.length,
          sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "language"  ? sortedInfo.order : null : null,
        },
        {
          key:"linkdocsinfo",
          title: "Info documentazione ufficiale",
          dataIndex: ['linkdocsinfo'],
          align: "center",
          responsive: ["md", "sm", "xs"],
          render: (_, record) => {
            
            var color: string = ''
  
            if(record.linkdocsinfo){
              record.docs = 'Attivo'
              color = 'green'
            }else{
              record.docs = 'Disattivo'
              color = 'red'
            }
  
            return(
              <>
               <Tag color={color}>{record.docs}</Tag>
              </>
            )
          }
        },
        {
          key:"linkdocs",
          title: "Link documentazione",
          dataIndex: ['linkdocs'],
          align: "center",
          responsive: ["md", "sm", "xs"],
          sorter: (a, b) => a.linkdocs.length - b.linkdocs.length,
          sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "linkdocs"  ? sortedInfo.order : null : null,
        },
        {
          title: "Azione",
          dataIndex: "azione",
          align: "center",
          responsive: ["md", "sm", "xs"],
          render: (_, record) => {
            return <>
            <Button
            onClick={() => [
              this.setModalUpdate(true),
              this.setUpdateId(record.id),
              this.setFrameworkInfo(record),
              this.getImageFramework(record.id)
            ]}
            type="primary"
            style={{
              width: "40px",
              height: "40px",
              alignContent: "center",
            }}
            icon={<EditOutlined />}
          />
          <Button
            onClick={() => [
              this.setModalDelete(true),
              this.setDeleteId(record.id),
            ]}
            danger
            type="primary"
            style={{
              width: "40px",
              height: "40px",
              marginLeft: "20px",
              alignContent: "center",
            }}
            icon={<DeleteOutlined />}
            />
          </>;
          },
        },
      ];
  
      
    return (

     <>
            <div className="container-fluid">
          <div className="layout-specing">
            <div className="d-md-flex justify-content-between align-items-center">
              <h5 className="mb-0">Info Home</h5>

              <nav aria-label="breadcrumb" className="d-inline-block">
                <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                  <li className="breadcrumb-item text-capitalize">
                    <a href="index.html">Contenuto pagine</a>
                  </li>
                  <li
                    className="breadcrumb-item text-capitalize active"
                    aria-current="page"
                  >
                    Info Home
                  </li>
                </ul>
              </nav>
            </div>

            <div className="row">
              <div className="col-12 mt-4"></div>
            </div>

            <div className="row text-center">

            <Space style={{ marginBottom: 16 }}>
              <div className="mobile-sm">
                <Button onClick={clearAll}>
                  Resetta ordinamenti,filtri e ricerca
                </Button>
              </div>
              <div className="mobile-sm">
                <Button type="dashed" icon={<SettingOutlined />} onClick={() => this.setModalSetting(true)}>
                  impostazioni pagina
                </Button>
              </div>
              <div className="mobile-sm">
                <Button type="primary" onClick={() => this.setModalAdd(true)}>
                  Aggiungi framework
                </Button>
              </div>
          </Space>

              <SkeletonTable
                loading={this.state.loading}
                columns={columns as SkeletonTableColumnsType[]}
              >
                <Table
                  columns={columns}
                  dataSource={this.state.frameworks}
                  onChange={handleChange}
                  rowKey={(record) => record.id}
                  //rowSelection={rowSelection}
                  pagination={{
                    pageSize: 10,
                  }}
                  scroll={{ x: 600 }}
                />
              </SkeletonTable>
            </div>


            <Modal
                show={this.state.modalSetting}
                onHide={() => this.setModalSetting(false)}
                backdrop="static"
                keyboard={false}
            >
        
          
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValuesSetting}
                    validationSchema={this.validationSchemaSetting}
                    onSubmit={this.updateSetting}
                  >
      
           {formik => (
         

          <Form>
            <Modal.Header >
              <h5 className="modal-title" id="createModalLabel">
                <i className="ri-mark-pen-line align-middle me-2"></i> Impostazioni pagina
              </h5>
            </Modal.Header>
            <Modal.Body className="modal-body">
              <div className="container d-flex align-items-center justify-content-center">
                {messageAdd && (
                  <div className="form-group">
                    <div
                      className={
                        successful
                          ? "alert alert-success  text-center"
                          : "alert alert-danger text-center "
                      }
                      role="alert"
                    >
                      <strong>
                        {successful ? "Successo - " : "Errore - "}
                      </strong>
                      {messageAdd}
                    </div>
                  </div>
                )}
              </div>

                          <div style={{marginTop:'10px'}} className="form-check form-switch">
                                            <div >
                                              <Field 
                                                name="frameworkinfo"
                                                className="form-check-input" 
                                                type="checkbox"
                                                />
                                              </div>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Abilita info framework</label>
                          </div>

                        
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.setModalSetting(false)}>Chiudi</Button>
              
                <Button type="primary" htmlType="submit">
                  Salva
                </Button>
             
            </Modal.Footer>
          </Form>
        )}
      </Formik>
        </Modal>


            <Modal
             show={this.state.modalDelete}
             onHide={() => this.setModalDelete(false)}
           >
          <Modal.Header
            className="modal-header modal-colored-header bg-danger text-white"
          >
            <h5 className="modal-title" id="danger-header-modalLabel">
              Elimina framework
            </h5>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <p>Sei sicuro di voler eliminare questo framework?</p>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <Button onClick={() => this.setModalDelete(false)}>Annulla</Button>
            <Button danger onClick={() => this.deleteFramework()}>
              Elimina framework
            </Button>
          </Modal.Footer>
        </Modal>



            <Modal
                show={this.state.modalAdd}
                onHide={() => this.setModalAdd(false)}
                backdrop="static"
                keyboard={false}
            >
        
          
                <Formik
                    initialValues={initialValuesAdd}
                    validationSchema={this.validationSchemaAdd}
                    onSubmit={this.addFramework}
                  >
      
           {formik => (
         

          <Form>
            <Modal.Header >
              <h5 className="modal-title" id="createModalLabel">
                <i className="ri-mark-pen-line align-middle me-2"></i> Aggiungi nuovo framework
              </h5>
            </Modal.Header>
            <Modal.Body className="modal-body">
              <div className="container d-flex align-items-center justify-content-center">
                {messageAdd && (
                  <div className="form-group">
                    <div
                      className={
                        successfulAdd
                          ? "alert alert-success  text-center"
                          : "alert alert-danger text-center "
                      }
                      role="alert"
                    >
                      <strong>
                        {successfulAdd ? "Successo - " : "Errore - "}
                      </strong>
                      {messageAdd}
                    </div>
                  </div>
                )}
              </div>

                                <div className="d-grid me-md-4">
                                    <p className="text-muted">Carica un'immagine qui. Clicca sul pulsante "Carica immagine".</p>
                                    {formik.values.image != '' ? <PreviewImage file={formik.values.image}/> : null}
                                    <input type="file" id="input-file" name="image" accept="image/*"  hidden
                                    onChange={(event) => {
                                      //console.log(event.target.files)
                                      formik.setFieldValue("image", event.target.files![0] )
                                    }}
                                    className={(formik.touched.image && formik.errors.image) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.image && formik.errors.image ? (
                                        <div className="invalid-feedback">{formik.errors.image}</div>
                                    ) : null}
                                    <label className="btn-upload btn btn-primary mt-4" htmlFor="input-file">Carica immagine</label>
                                </div>

                              <div className="mb-3">
                                  <label className="form-label">
                                    Nome{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="name"
                                      type="text"
                                      placeholder="nome"
                                      className={(formik.touched.name && formik.errors.name) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.name && formik.errors.name ? (
                                        <div className="invalid-feedback">{formik.errors.name}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                    Linguaggio{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="language"
                                      type="text"
                                      placeholder="linguaggio programmazione"
                                      className={(formik.touched.language && formik.errors.language) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.language && formik.errors.language ? (
                                        <div className="invalid-feedback">{formik.errors.language}</div>
                                    ) : null} 
                             </div>
                          </div>
                       

                          <div style={{marginTop:'25px'}} className="form-check form-switch">
                                            <div >
                                              <Field 
                                                name="linkdocsinfo"
                                                className="form-check-input" 
                                                type="checkbox"
                                                />
                                              </div>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Abilita info framework</label>
                          </div>

                          <div className="col-12">
                                                <label  className="form-label">Link</label>
                                                <Field
                                                name="linkdocs"
                                                type="text" 
                                                placeholder="inserisci un link  documentazione" 
                                                className={(formik.touched.linkdocs && formik.errors.linkdocs) ? 'form-control is-invalid' : 'form-control'} 
                                                /> 
                                              {formik.touched.linkdocs && formik.errors.linkdocs ? (
                                                   <div className="invalid-feedback">{formik.errors.linkdocs}</div>
                                                ) : null} 
                          </div>

                         
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.setModalAdd(false)}>Chiudi</Button>
              
                <Button type="primary" htmlType="submit">
                  Aggiungi
                </Button>
             
            </Modal.Footer>
          </Form>
        )}
      </Formik>
        </Modal>








             <Modal
                show={this.state.modalUpdate}
                onHide={() => this.setModalUpdate(false)}
                backdrop="static"
                keyboard={false}
            >
        
          
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValuesUpdate}
                    validationSchema={this.validationSchemaUpdate}
                    onSubmit={this.updateFramework}
                  >
      
           {formik => (
         

          <Form>
            <Modal.Header >
              <h5 className="modal-title" id="createModalLabel">
                <i className="ri-mark-pen-line align-middle me-2"></i> Modifica framework
              </h5>
            </Modal.Header>
            <Modal.Body className="modal-body">
              <div className="container d-flex align-items-center justify-content-center">
                {messageUpdate && (
                  <div className="form-group">
                    <div
                      className={
                        successfulUpdate
                          ? "alert alert-success  text-center"
                          : "alert alert-danger text-center "
                      }
                      role="alert"
                    >
                      <strong>
                        {successfulUpdate ? "Successo - " : "Errore - "}
                      </strong>
                      {messageUpdate}
                    </div>
                  </div>
                )}
              </div>

                                <div className="d-grid me-md-4">
                                    <p className="text-muted">Carica un'immagine qui. Clicca sul pulsante "Carica immagine".</p>
                                    {formik.values.image != '' ? <PreviewImage file={formik.values.image}/> : null}
                                    <input type="file" id="input-file" name="image" accept="image/*"  hidden
                                    onChange={(event) => {
                                      //console.log(event.target.files)
                                      formik.setFieldValue("image", event.target.files![0] )
                                    }}
                                    className={(formik.touched.image && formik.errors.image) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.image && formik.errors.image ? (
                                        <div className="invalid-feedback">{formik.errors.image}</div>
                                    ) : null}
                                    <label className="btn-upload btn btn-primary mt-4" htmlFor="input-file">Carica immagine</label>
                                </div>

                              
                                <div className="mb-3">
                                  <label className="form-label">
                                    Nome{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="name"
                                      type="text"
                                      placeholder="nome"
                                      className={(formik.touched.name && formik.errors.name) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.name && formik.errors.name ? (
                                        <div className="invalid-feedback">{formik.errors.name}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                    Linguaggio{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="language"
                                      type="text"
                                      placeholder="linguaggio programmazione"
                                      className={(formik.touched.language && formik.errors.language) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.language && formik.errors.language ? (
                                        <div className="invalid-feedback">{formik.errors.language}</div>
                                    ) : null} 
                             </div>
                          </div>
                       

                          <div style={{marginTop:'25px'}} className="form-check form-switch">
                                            <div >
                                              <Field 
                                                name="linkdocsinfo"
                                                className="form-check-input" 
                                                type="checkbox"
                                                />
                                              </div>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Abilita info framework</label>
                          </div>

                          <div className="col-12">
                                                <label  className="form-label">Link</label>
                                                <Field
                                                name="linkdocs"
                                                type="text" 
                                                placeholder="inserisci un link  documentazione" 
                                                className={(formik.touched.linkdocs && formik.errors.linkdocs) ? 'form-control is-invalid' : 'form-control'} 
                                                /> 
                                              {formik.touched.linkdocs && formik.errors.linkdocs ? (
                                                   <div className="invalid-feedback">{formik.errors.linkdocs}</div>
                                                ) : null} 
                          </div>
              
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => [this.setModalUpdate(false)]}>Chiudi</Button>
              
                <Button type="primary" htmlType="submit">
                  Modifica
                </Button>
             
            </Modal.Footer>
          </Form>
        )}
      </Formik>
        </Modal>


        
          </div>
        </div>


     </>

    )
  }
}