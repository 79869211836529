import React from "react";
import { Navigate, NavLink, redirect } from "react-router-dom";
import { Component } from "react";
import Loginimg from "../../assets/landing/img/login.svg";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import authService from "../../api/auth/auth.service";
import TokenService from "../../api/token.service";
import Recovery from "../../assets/landing/img/recovery.svg";

type Props = {};

type State = {
  confirm: boolean;
  successful: boolean;
  message: string;
};

export default class ForgotPassword extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      confirm: false,
      successful: false,
      message: "",
    };
  }

  validationSchema() {
    return yup.object().shape({
      email: yup.string().required("inserisci un'email"),
    });
  }

  sendEmail = async (formValue: { email: string }) => {
    const { email } = formValue;
    console.log(formValue);

    authService.sendEmail(
        email
    ).then(
      response => {
        
        this.setState({
          successful: true,
        });
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();


      }
    );
  };

  render() {
    const { successful, message } = this.state;

    const initialValues = {
      email: "",
    };

    return (
      <>

        {this.state.successful == true ? <Navigate to='../verify' /> : null}

        <div className="back-to-home">
          <NavLink
            to={"../"}
            className="back-button btn btn-icon btn-primary"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="feather feather-arrow-left icons"
            >
              <line x1="19" y1="12" x2="5" y2="12"></line>
              <polyline points="12 19 5 12 12 5"></polyline>
            </svg>
          </NavLink>
        </div>

        <section className="bg-home d-flex align-items-center">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-6">
                <div className="me-lg-5">
                  <img
                    src={Recovery}
                    className="img-fluid d-block mx-auto"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-5 col-md-6">
                <div className="card shadow rounded border-0">
                  <div className="card-body">
                    <h4 className="card-title text-center">Recupera account</h4>
                    {message && (
                      <div className="form-group">
                        <div
                          className={
                            successful
                              ? "alert alert-success alert-dismissible fade show"
                              : "alert alert-danger alert-dismissible fade show "
                          }
                          role="alert"
                        >
                          <button
                            type="button"
                            className="btn-close btn-close-white"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                          ></button>
                          <strong>Errore - </strong>

                          {message}
                        </div>
                      </div>
                    )}

                   <Formik
                      initialValues={initialValues}
                      validationSchema={this.validationSchema}
                      onSubmit={this.sendEmail}
                    >

                {formik => (

                    <Form className="login-form mt-4">
                      <div className="row">
                        <div className="col-lg-12">
                          <p className="text-muted">
                            Per favore inserisci il tuo indirizzo email.
                            Riceverai un codice via email per ripristino password.
                          </p>
                          <div className="mb-3">
                            <label className="form-label">
                              Email{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="form-icon position-relative">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-user fea icon-sm icons"
                                >
                                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                  <circle cx="12" cy="7" r="4"></circle>
                                </svg>
                                <Field
                                  name="email"
                                  type="email"
                                  placeholder="Email"
                                  className={(formik.touched.email && formik.errors.email) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                  /> 
                                  {formik.touched.email && formik.errors.email ? (
                                        <div className="invalid-feedback">{formik.errors.email}</div>
                                    ) : null} 
                              </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="d-grid">
                            <button className="btn btn-primary">Invia</button>
                          </div>
                        </div>
                        <div className="mx-auto">
                          <p className="mb-0 mt-3">
                            <small className="text-dark me-2">
                              Ricordi la tua password ?
                            </small>{" "}
                            <NavLink
                              to={"../login"}
                              className="text-dark fw-bold"
                            >
                              Accedi
                            </NavLink>
                          </p>
                        </div>
                      </div>
                    </Form>
                     )}
                  </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
