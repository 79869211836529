import jwt_decode from "jwt-decode";

class TokenService{

    checkAccessToken(){
        return localStorage.getItem("accessToken")     
    }

    getAccessToken(){
        const token = JSON.parse(localStorage.getItem("accessToken") || 'null');
        return token
    }

    getRefreshToken(){
        const rtToken = JSON.parse(localStorage.getItem("refreshToken") || 'null');
        return rtToken
    }

    getNewAccessToken(){
        const token = JSON.parse(localStorage.getItem("newaccessToken") || 'null');
        return token
    }

    getDataAccessToken(){
        try {
            const token = JSON.parse(localStorage.getItem("accessToken") || '{}');
            var auth : string = JSON.stringify(jwt_decode(token))
            const decoded = JSON.parse(auth)
            return decoded
        } catch (error) {
            return false
        }
        
    }


    getDataRefreshToken(){
        const token = JSON.parse(localStorage.getItem("refreshToken") || '{}');
        var auth : string = JSON.stringify(jwt_decode(token))
        const decoded = JSON.parse(auth)
        return decoded

    }

    setAccessToken(token){
        localStorage.setItem("accessToken", JSON.stringify(token));
    }

    setRefreshToken(token){
        localStorage.setItem("refreshToken", JSON.stringify(token));
    }



    clear(){
        localStorage.clear()
    }

}

export default new TokenService()