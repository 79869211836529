import React from "react";
import { Navigate, NavLink, redirect } from "react-router-dom";
import { Component } from "react";
import Loginimg from "../../assets/landing/img/login.svg";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import authService from "../../api/auth/auth.service";
import TokenService from "../../api/token.service";
import { Modal } from "react-bootstrap";
import { Button, Tooltip } from "antd";
import userService from "../../api/user/user.service";
import PreviewImage from "../admin/pages/element/previewImage";
import UserImage from "./elements/userImage";

type Props = {};

type State = {
  file:string,
  checkBeforeEmail:string,
  logout:boolean,
  showPassword: boolean;
  modal:boolean,
  modalOtp:boolean
  modalUpdateImage:boolean,
  confirm: boolean;
  successful:boolean;
  message:string,
  successfulInfo:boolean,
  messageInfo:string,
  successfulOtp:boolean,
  messageOtp:string,
  successfulUpdateImage:boolean,
  messageUpdateImage:string,
  delete:boolean,
  profile:{
    name:string,
    surname:string,
    email:string,
    user:string
  }
};

export default class Profile extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      file:"",
      checkBeforeEmail:"",
      logout:false,
      showPassword: false,
      modal:false,
      modalOtp:false,
      modalUpdateImage:false,
      confirm: false,
      successful: false,
      message: "",
      successfulInfo:false,
      messageInfo:"",
      successfulOtp:false,
      messageOtp:"",
      successfulUpdateImage:false,
      messageUpdateImage:"",
      delete:false,
      profile:{
        name:"",
        surname:"",
        email:"",
        user:""
      }
    };
  }


  togglePassword = () =>{
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  setModal = (state) =>{
    this.setState({
      modal:state
    })
  }

  setModalOtp = (state) =>{
    this.setState({
      modalOtp:state
    })
  }

  setModalUpdateImage = (state) =>{
    this.setState({
      modalUpdateImage:state
    })
  }


  validationSchemaPassword() {
    const passwordRules = /^(?=(.*[a-z]){3,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){2,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,}$/;
    return yup.object().shape({
      oldpassword:  yup
      .string()
      .required("inserisci la vecchia password"),
      password: yup
        .string()
        .required("inserisci una password")
        .matches(
          passwordRules,
          "Inserisci una password di almeno 8 caratteri, una maiuscuola , simboli speciali e almeno 2 numeri"
        ),
      cpassword: yup
        .string()
        .oneOf([yup.ref("password")], "le password non corrispondono")
        .required("inserisci la password di conferma"),
    });
  }

  validationSchemaInfo(){
    const emailRules = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
    return yup.object().shape({
      name: yup.string().required("inserisci un nome"),
      surname: yup.string().required("inserisci un cognome"),
      email: yup.string().required("inserisci una email").matches(emailRules, "Inserisci una email valida"),
      user: yup.string().required("inserisci un nome utente"),
    });
  }


  validationSchemaOtp() {
    return yup.object().shape({
      otp: yup.string().required("inserisci il codice otp"),
    });
  }


  validationSchemaUpdateImage() {
    const supported_formats = ["image/jpg" , "image/jpeg" , "image/png" , "image/svg" , "image/ips" , "image/psd" , "image/ai"]
    return yup.object().shape({
      image: yup.mixed().nullable().required("inserisci un file di immagine").test("type","Il file caricato non è un'immaggine", (values) => !values|| (values && supported_formats.includes(values['type']))),
    });
  }

  changePassword = async (formValue: {oldpassword:string, password: string, cpassword: string }) => {
    const { oldpassword,password,cpassword } = formValue;

    this.setState({
        message:'',
        successful:false
      })

    authService.changePassword(
        oldpassword,
        password,
        cpassword
    ).then(
        response => {

          this.setState({
            message:'Password cambiata',
            successful:true
          })
          
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
    
            if(resMessage == "pass-not-match"){
              this.setState({
                successful: false,
                message: "La password inserita non corrisponde all'attuale"
              });
            } 

      }
  
        
      );
  };

  deleteAccount = async() =>{

    this.setState({
      message:'',
      successful:false
    })

  authService.deleteAccount(
  ).then(
      response => {

        TokenService.clear()

        this.setState({
          delete:true,
          successful:true
        })
        
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();



    }

      
    );
  }

  getUser = async() =>{

    userService.getSingleUser(
      ).then(
          response => {
    
         
            this.setState({
              profile:{
                name:response.data.name,
                surname:response.data.surname,
                email:response.data.email,
                user:response.data.user
              },
              checkBeforeEmail:response.data.email
            })
            
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
    
    
    
        }
    
          
        );

  }


  updateProfileInfo = async(formValue:{name:string,surname:string,email:string,user:string}) =>{
    const {name,surname,email,user} = formValue

    this.setState({
      messageInfo:'',
      successfulInfo:true
    })

    userService.updateProfile(
      name,
      surname,
      user,
      email
      ).then(
          response => {
    
         
            this.setState({
              messageInfo:'Profilo aggiornato',
              successfulInfo:true
            })

            if(this.state.checkBeforeEmail != email){
              this.setModalOtp(true)
            }
            
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
    
     
              if(resMessage == "email-is-exist"){
                this.setState({
                  successfulInfo: false,
                  messageInfo: "L'email inserita è giò stata utilizzata da un'altro account"
                });
              } 

              if(resMessage == "this-user-is-already-taken"){
                this.setState({
                  successfulInfo: false,
                  messageInfo: "Il nome utente inserito è già stato utilizzato da un'altro account"
                });
              } 
    
        }
    
          
        );

  }

  updateImage = async(formValue:{image}) =>{

    const {image} = formValue

    userService.updateImage(
       image
      ).then(
          response => {
    
         
          this.setModalUpdateImage(false)
           
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
    
    
        }
      
    );

  }


  verify = async (formValue: { otp: string }) => {
    const { otp } = formValue;
   

     this.setState({
        message: "",
        successful:false,
      })

    authService.confirmOtp(
        otp
    ).then(
        response => {
         
            this.setState({
              successful:true,
            })

            this.setModalOtp(false)
            
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
    
            if(resMessage == "otp-code-not-found"){
                this.setState({
                  successfulOtp: false,
                  messageOtp: "Il codice inserito è errato"
                });
              } 
      }
  
        
      );

    
  };


  logout(){

    authService.logout(
    ).then(
      response => {
      
           TokenService.clear()

          this.setState({
            logout:true,
          })
        
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

    }
    );

  }

  componentDidMount(){
    this.getUser()
  }

  render() {
    const { successful, message, messageInfo, successfulInfo, messageOtp, successfulOtp, successfulUpdateImage, messageUpdateImage } = this.state;

    const initialValuesPassword = {
      oldpassword: "",
      password: "",
      cpassword: "",
    };

    const initialValuesInfo = {
      name: this.state.profile.name,
      surname:this.state.profile.surname,
      email:this.state.profile.email,
      user: this.state.profile.user
    }

    const initialValuesOtp = {
      otp:''
    }

    const initialValuesUpdateImage = {
      image:''
    }


    return (
      <>

    {this.state.delete ? <Navigate to='../../../login' replace={true} /> : null}

    {this.state.logout ? <Navigate to='../../../login' replace={true}/> : null}

        <div className="container-fluid">
          <div className="layout-specing">
            <div className="d-md-flex justify-content-between align-items-center">
              <h5 className="mb-0">Profilo</h5>

              <nav
                aria-label="breadcrumb"
                className="d-inline-block mt-2 mt-sm-0"
              >
                <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                  <li className="breadcrumb-item text-capitalize">
                    <a href="index.html">Dashboard</a>
                  </li>
                  <li
                    className="breadcrumb-item text-capitalize active"
                    aria-current="page"
                  >
                    Profilo
                  </li>
                </ul>
              </nav>
            </div>

            <div className="row">
              <div className="col-lg-4 mt-4">
                <div className="card border-0 rounded shadow">
                  <div className="card-body">
                    <h5 className="text-md-start text-center mb-0">
                      Dettagli personali:
                    </h5>

                    <div className="mt-4 text-md-start text-center d-sm-flex">

                      <UserImage/>

                      <div className="mt-md-4 mt-3 mt-sm-0">
                        <a
                          onClick={() => this.setModalUpdateImage(true)}
                          href="javascript:void(0)"
                          className="btn btn-primary mt-2"
                        >
                          Cambia immagine
                        </a>
                        {/*<a
                          href="javascript:void(0)"
                          className="btn btn-outline-primary mt-2 ms-2"
                        >
                          Delete
                        </a>*/}
                      </div>
                    </div>

                    {messageInfo && (
                    <div className="form-group">
                      <div
                        className={
                          successfulInfo
                            ? "alert alert-success alert-dismissible fade show"
                            : "alert alert-danger alert-dismissible fade show "
                        }
                        role="alert"
                      >
                        <button
                          type="button"
                          className="btn-close btn-close-white"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                        ></button>
                        <strong>
                          {successfulInfo ? "Successo - " : "Errore - "}
                        </strong>

                        {messageInfo}
                      </div>
                    </div>
                  )}

                    <Formik
                    enableReinitialize={true}
                    initialValues={initialValuesInfo}
                    validationSchema={this.validationSchemaInfo}
                    onSubmit={this.updateProfileInfo}
                  >
                    {(formik) => (

                    <Form>
                      <div className="row mt-4">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Nome</label>
                            <div className="form-icon position-relative">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="feather feather-user fea icon-sm icons"
                              >
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                              </svg>
                              <Field
                                name="name"
                                type="text"
                                placeholder="nome"
                                className={(formik.touched.name && formik.errors.name) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                /> 
                              {formik.touched.name && formik.errors.name ? (
                                    <div className="invalid-feedback">{formik.errors.name}</div>
                              ) : null} 
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Cognome</label>
                            <div className="form-icon position-relative">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="feather feather-user-check fea icon-sm icons"
                              >
                                <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                <circle cx="8.5" cy="7" r="4"></circle>
                                <polyline points="17 11 19 13 23 9"></polyline>
                              </svg>
                              <Field
                                name="surname"
                                type="text"
                                placeholder="cognome"
                                className={(formik.touched.surname && formik.errors.surname) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                              /> 
                              {formik.touched.surname && formik.errors.surname ? (
                                   <div className="invalid-feedback">{formik.errors.surname}</div>
                              ) : null} 
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <div className="form-icon position-relative">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="feather feather-mail fea icon-sm icons"
                              >
                                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                <polyline points="22,6 12,13 2,6"></polyline>
                              </svg>
                              <Field
                                name="email"
                                type="email"
                                placeholder="Email"
                                className={(formik.touched.email && formik.errors.email) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                /> 
                                {formik.touched.email && formik.errors.email ? (
                                    <div className="invalid-feedback">{formik.errors.email}</div>
                                ) : null} 
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">User</label>
                            <div className="form-icon position-relative">
                              <i
                                data-feather="bookmark"
                                className="fea icon-sm icons"
                              ></i>
                              <Field
                                name="user"
                                placeholder="User"
                                className={(formik.touched.user && formik.errors.user) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                              /> 
                              {formik.touched.user && formik.errors.user ? (
                                 <div className="invalid-feedback">{formik.errors.user}</div>
                              ) : null} 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <button
                            name="send"
                            className="btn btn-primary"
                          >
                            Salva
                          </button>
                        </div>
                      </div>
                    </Form>
                     )}
                   </Formik>
                  </div>
                </div>

                {/*<div className="card border-0 rounded shadow p-4 mt-4">
                                    <h5 className="mb-0">Contact Info :</h5>

                                    <form>
                                        <div className="row mt-4">
                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label className="form-label">Phone No. :</label>
                                                    <div className="form-icon position-relative">
                                                        <i data-feather="phone" className="fea icon-sm icons"></i>
                                                        <input name="number" id="number" type="number" className="form-control ps-5" placeholder="Phone :"/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label className="form-label">Website :</label>
                                                    <div className="form-icon position-relative">
                                                        <i data-feather="globe" className="fea icon-sm icons"></i>
                                                        <input name="url" id="url" type="url" className="form-control ps-5" placeholder="Url :"/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 mt-2 mb-0">
                                                <button className="btn btn-primary">Add</button>
                                            </div>
                                        </div>
                                    </form>
                               </div>*/}
              </div>

              <div className="col-lg-4 mt-4">
                <div className="card border-0 rounded shadow p-4">
                  {message && (
                    <div className="form-group">
                      <div
                        className={
                          successful
                            ? "alert alert-success alert-dismissible fade show"
                            : "alert alert-danger alert-dismissible fade show "
                        }
                        role="alert"
                      >
                        <button
                          type="button"
                          className="btn-close btn-close-white"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                        ></button>
                        <strong>
                          {successful ? "Successo - " : "Errore - "}
                        </strong>

                        {message}
                      </div>
                    </div>
                  )}

                  <h5 className="mb-0">Cambia password :</h5>
                  <Formik
                    initialValues={initialValuesPassword}
                    validationSchema={this.validationSchemaPassword}
                    onSubmit={this.changePassword}
                  >
                    {(formik) => (
                      <Form>
                        <div className="row mt-4">
                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label className="form-label">
                                Password attuale:
                              </label>
                              <div className="form-icon position-relative">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-key fea icon-sm icons"
                                >
                                  <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
                                </svg>
                                <Field
                                  name="oldpassword"
                                  type={
                                    this.state.showPassword
                                      ? "text"
                                      : "password"
                                  }
                                  placeholder="password"
                                  className={
                                    formik.touched.oldpassword &&
                                    formik.errors.oldpassword
                                      ? "form-control ps-5 is-invalid"
                                      : "form-control ps-5"
                                  }
                                />
                                {formik.touched.oldpassword &&
                                formik.errors.oldpassword ? (
                                  <div className="invalid-feedback">
                                    {formik.errors.oldpassword}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label className="form-label">
                                Nuova password :
                              </label>
                              <div className="form-icon position-relative">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-key fea icon-sm icons"
                                >
                                  <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
                                </svg>
                                <Field
                                  name="password"
                                  type={
                                    this.state.showPassword
                                      ? "text"
                                      : "password"
                                  }
                                  placeholder="password"
                                  className={
                                    formik.touched.password &&
                                    formik.errors.password
                                      ? "form-control ps-5 is-invalid"
                                      : "form-control ps-5"
                                  }
                                />
                                {formik.touched.password &&
                                formik.errors.password ? (
                                  <div className="invalid-feedback">
                                    {formik.errors.password}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label className="form-label">
                                Conferma nuova  password :
                              </label>
                              <div className="form-icon position-relative">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-key fea icon-sm icons"
                                >
                                  <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
                                </svg>
                                <Field
                                  name="cpassword"
                                  type={
                                    this.state.showPassword
                                      ? "text"
                                      : "password"
                                  }
                                  placeholder="conferma password"
                                  className={
                                    formik.touched.cpassword &&
                                    formik.errors.cpassword
                                      ? "form-control ps-5 is-invalid"
                                      : "form-control ps-5"
                                  }
                                />
                                {formik.touched.cpassword &&
                                formik.errors.cpassword ? (
                                  <div className="invalid-feedback">
                                    {formik.errors.cpassword}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="mb-3">
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" value="" onClick={this.togglePassword} />
                                  <label className="form-check-label" >mostra password</label>
                                </div>
                            </div>
                          </div>

                          <div className="col-lg-12 mt-2 mb-0">
                            <button className="btn btn-primary">
                              Salva password
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>

                <div className="card border-0 rounded shadow p-4 mt-4">
                  <h5 className="mb-0 text-danger">Elimina account :</h5>

                  <div className="mt-4">
                    <h6 className="mb-0">
                     Vuoi eliminare il tuo account ? 
                    </h6>
                    <div className="mt-4">
                      <button onClick={() => this.setModal(true)} className="btn btn-danger">Elimina account</button>
                    </div>
                  </div>
                </div>

                {/*<div className="card border-0 rounded shadow p-4 mt-4">
                                    <h5 className="mb-0">Account Notifications :</h5>
        
                                    <div className="mt-4">
                                        <div className="d-flex justify-content-between pb-4">
                                            <h6 className="mb-0">When someone mentions me</h6>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="noti1"/>
                                                <label className="form-check-label" htmlFor="noti1"></label>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between py-4 border-top">
                                            <h6 className="mb-0">When someone follows me</h6>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" checked id="noti2"/>
                                                <label className="form-check-label" htmlFor="noti2"></label>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between py-4 border-top">
                                            <h6 className="mb-0">When shares my activity</h6>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="noti3"/>
                                                <label className="form-check-label" htmlFor="noti3"></label>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between py-4 border-top">
                                            <h6 className="mb-0">When someone messages me</h6>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="noti4"/>
                                                <label className="form-check-label" htmlFor="noti4"></label>
                                            </div>
                                        </div>
                                    </div>
                              </div>*/}
              </div>

              <div className="col-lg-4 mt-4">
                {/*<div className="card border-0 rounded shadow p-4">
                                    <h5 className="mb-0">Marketing Notifications :</h5>
        
                                    <div className="mt-4">
                                        <div className="d-flex justify-content-between pb-4">
                                            <h6 className="mb-0">There is a sale or promotion</h6>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="noti5"/>
                                                <label className="form-check-label" htmlFor="noti5"></label>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between py-4 border-top">
                                            <h6 className="mb-0">Company news</h6>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="noti6"/>
                                                <label className="form-check-label" htmlFor="noti6"></label>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between py-4 border-top">
                                            <h6 className="mb-0">Weekly jobs</h6>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" checked id="noti7/"/>
                                                <label className="form-check-label" htmlFor="noti7"></label>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between py-4 border-top">
                                            <h6 className="mb-0">Unsubscribe News</h6>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" checked id="noti8"/>
                                                <label className="form-check-label" htmlFor="noti8"></label>
                                            </div>
                                        </div>
                                    </div>
                              </div>*/}
              </div>
            </div>
          </div>
        </div>


        <Modal
             show={this.state.modal}
             onHide={() => this.setModal(false)}
           >
          <Modal.Header
            className="modal-header modal-colored-header bg-danger text-white"
            closeButton
          >
            <h5 className="modal-title" id="danger-header-modalLabel">
              Elimina account
            </h5>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <p>Sei sicuro di voler eliminare il tuo account? L'operazione è irreversibile.</p>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <Button onClick={() => this.setModal(false)}>Annulla</Button>
            <Button type="primary" danger onClick={() => this.deleteAccount()}>
              Elimina account
            </Button>
          </Modal.Footer>
        </Modal>




            <Modal
                     show={this.state.modalOtp}
                     onHide={() => this.setModalOtp(false)}
                     backdrop="static"
                     keyboard={false}>



                  <Formik
                    initialValues={initialValuesOtp}
                    validationSchema={this.validationSchemaOtp}
                    onSubmit={this.verify}
                  >
      
        {formik => (
          
           <Form>
              <Modal.Header >
                  <h5 className="modal-title" id="createModalLabel">
                    <i className="ri-mark-pen-line align-middle me-2"></i> Verifica nuovo indirizzo email
                  </h5>
             </Modal.Header>
             <Modal.Body className="modal-body">
             <div className="container d-flex align-items-center justify-content-center">
              {messageOtp && (
                <div className="form-group">
                  <div
                    className={
                      successfulOtp ? "alert alert-success  text-center" : "alert alert-danger text-center "
                    }
                    role="alert"
                  >
                    <strong>{
                      successfulOtp ? 'Successo - ': 'Errore - '
                    }</strong> 
                    {messageOtp}
                  </div>
                </div>
              )}
              </div>
          
                  <Tooltip placement="leftTop" title={<span>Se verifichi addesso la nuova email non verrai disconesso altrimenti puoi farlo più tardi ma verrà effettuata la disconnessione</span>}>
                  <div className="mb-3">
                  <label >Verifica</label>
                   <div className="form-icon position-relative">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-user fea icon-sm icons">
                       <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                       <circle cx="12" cy="7" r="4"></circle>
                      </svg>
                      <Field
                        name="otp"
                        type="text"
                        placeholder="Otp"
                        className={(formik.touched.otp && formik.errors.otp) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                        /> 
                        {formik.touched.otp && formik.errors.otp ? (
                              <div className="invalid-feedback">{formik.errors.otp}</div>
                        ) : null} 
                    </div>
                  </div>
                  </Tooltip>
        
                </Modal.Body>
                <Modal.Footer>
                   <Button
                    onClick={() => [this.setModalOtp(false),this.logout()]}
                  >
                    Verifica più tardi
                  </Button>
                  
                  <Button  type="primary" htmlType="submit" >Verifica</Button> 
                </Modal.Footer>
            
             </Form>   
            )}
          </Formik>
        </Modal>



        <Modal
                show={this.state.modalUpdateImage}
                onHide={() => this.setModalUpdateImage(false)}
                backdrop="static"
                keyboard={false}
            >
        
          
                <Formik
                    initialValues={initialValuesUpdateImage}
                    validationSchema={this.validationSchemaUpdateImage}
                    onSubmit={this.updateImage}
                  >
      
           {formik => (
         

          <Form>
            <Modal.Header >
              <h5 className="modal-title" id="createModalLabel">
                <i className="ri-mark-pen-line align-middle me-2"></i> Modifica immagine profilo
              </h5>
            </Modal.Header>
            <Modal.Body className="modal-body">
              <div className="container d-flex align-items-center justify-content-center">
                {messageUpdateImage && (
                  <div className="form-group">
                    <div
                      className={
                        successfulUpdateImage
                          ? "alert alert-success  text-center"
                          : "alert alert-danger text-center "
                      }
                      role="alert"
                    >
                      <strong>
                        {successfulUpdateImage ? "Successo - " : "Errore - "}
                      </strong>
                      {messageUpdateImage}
                    </div>
                  </div>
                )}
              </div>

                                <div className="d-grid me-md-4">
                                    <p className="text-muted">Carica un'immagine qui. Clicca sul pulsante "Carica immagine".</p>
                                    {formik.values.image != '' ? <PreviewImage file={formik.values.image}/> : null}
                                    <input type="file" id="input-file" name="image" accept="image/*"  hidden
                                    onChange={(event) => {
                                      //console.log(event.target.files)
                                      formik.setFieldValue("image", event.target.files![0] )
                                    }}
                                    className={(formik.touched.image && formik.errors.image) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.image && formik.errors.image ? (
                                        <div className="invalid-feedback">{formik.errors.image}</div>
                                    ) : null}
                                    <label className="btn-upload btn btn-primary mt-4" htmlFor="input-file">Carica immagine</label>
                                </div>

  
              
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.setModalUpdateImage(false)}>Chiudi</Button>
              
                <Button type="primary" htmlType="submit">
                  Salva
                </Button>
             
            </Modal.Footer>
          </Form>
        )}
      </Formik>
        </Modal>



      </>
    );
  }
}
