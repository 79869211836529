import React, { useEffect, useState } from "react";
import aboutusService from "../../api/page/aboutus/aboutus.service";

const MemberImage = ({ id }) => {
  const [preview, setPreview] = useState<any>()

  useEffect(() => {
    aboutusService.getImageMember(
      id
    ).then(
      response => {
  
        const reader = new FileReader()
        reader.readAsDataURL(response.data)
        reader.onload = () => {
          setPreview(reader.result)
        }
  
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
      }
  
    );
  }, [id]);


  



  return (
    <div style={{ width: '250px', height: '250px', overflow: 'hidden', margin: '0px', position: 'relative', borderRadius: '6px' }}>
      <img src={preview} className="img-fluid rounded shadow" style={{ position: 'absolute', left: '-1000%', right: '-1000%', top: '-1000%', bottom: '-1000%', margin: 'auto', minHeight: '100%', minWidth: '100%' }} alt="" />
    </div>
  )
}

export default MemberImage