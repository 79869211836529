import React from "react";
import { Link, useNavigate, useLocation, Outlet, NavLink, Navigate } from 'react-router-dom';
import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import footerService from "../../api/page/footer/footer.service";
import Logo from "../../assets/landing/img/phoenix-sign-light.png"



interface Props extends WithTranslation {
    prop: any
}


type State = {
    map: any[],
    info: {
        facebookinfo: boolean,
        facebooklink: string,
        instagraminfo: boolean,
        instagramlink: string,
        twitterinfo: boolean,
        twitterlink: string,
        linkedininfo: boolean,
        linkedinlink: string,
        newsletter: boolean,
        textbelow: string
    }
    logout: boolean
};

class Footer extends Component<Props, State> {

    constructor(props) {
        super(props);


        this.state = {
            map: [],
            info: {
                facebookinfo: false,
                facebooklink: '',
                instagraminfo: false,
                instagramlink: '',
                twitterinfo: false,
                twitterlink: '',
                linkedininfo: false,
                linkedinlink: '',
                newsletter: false,
                textbelow: ''
            },
            logout: false
        }
    }




    renderData = async (data) => {

        const array = JSON.parse(data)
        const item = array[0]

        this.setState({
            info: {
                facebookinfo: item.facebookinfo,
                facebooklink: item.facebooklink,
                instagraminfo: item.instagraminfo,
                instagramlink: item.instagramlink,
                twitterinfo: item.twitterinfo,
                twitterlink: item.twitterlink,
                linkedininfo: item.linkedininfo,
                linkedinlink: item.linkedinlink,
                newsletter: item.newsletter,
                textbelow: item.textbelow
            }
        })
    }

    getAllSettings = async () => {

        footerService.findAll(
        ).then(
            response => {

                if (response.data[0]) {
                    this.renderData(JSON.stringify(response.data))
                }

            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

            }

        );
    }

    componentDidMount() {
        this.getAllSettings()
    }

    render() {

        const { t } = this.props


        return (

            <>
                <footer className="land-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="footer-py-60">
                                    <div className="row">
                                        <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                                            <a href="#" className="logo-footer">
                                                <img src={Logo} height="24" alt="" />
                                            </a>
                                            <p className="mt-4">{t('landing.footer.desc_company')}</p>
                                            <ul className="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                                                {this.state.info.facebookinfo ? <li className="list-inline-item"><a href={this.state.info.facebooklink} className="rounded"><i className="uil uil-facebook-f"></i></a></li> : null}
                                                {this.state.info.instagraminfo ? <li style={{ marginLeft: '10px' }} className="list-inline-item"><a href={this.state.info.instagramlink} className="rounded"><i className="uil uil-instagram"></i></a></li> : null}
                                                {this.state.info.twitterinfo ? <li style={{ marginLeft: '10px' }} className="list-inline-item"><a href={this.state.info.twitterlink} className="rounded"><i className="uil uil-twitter-alt"></i></a></li> : null}
                                                {this.state.info.linkedininfo ? <li style={{ marginLeft: '10px' }} className="list-inline-item"><a href={this.state.info.linkedinlink} className="rounded"><i className="uil uil-linkedin-alt"></i></a></li> : null}
                                            </ul>
                                        </div>

                                        <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                            <h5 className="footer-head">{t('landing.footer.company_links.title')}</h5>
                                            <ul className="list-unstyled footer-list mt-4">
                                                <li><NavLink to={'about-us'} className="text-foot"><i className="uil uil-angle-right-b me-1"></i> {t('landing.footer.company_links.about_us')}</NavLink></li>
                                                <li><NavLink to={'portfolio'} className="text-foot"><i className="uil uil-angle-right-b me-1"></i> {t('landing.footer.company_links.portfolio')}</NavLink></li>
                                            </ul>
                                        </div>

                                        <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                            <h5 className="footer-head">{t('landing.footer.useful_links.title')}</h5>
                                            <ul className="list-unstyled footer-list mt-4">
                                                <li><NavLink to={'terms-of-services'} className="text-foot"><i className="uil uil-angle-right-b me-1"></i> {t('landing.footer.useful_links.terms_and_conditions')}</NavLink></li>
                                                <li><a href="https://www.iubenda.com/privacy-policy/58804541" className="text-foot" title="Privacy Policy " ><i className="uil uil-angle-right-b me-1"></i> {t('landing.footer.useful_links.privacy_policy')}</a></li>
                                                <li><a href="https://www.iubenda.com/privacy-policy/58804541/cookie-policy" className="text-foot" title="Cookie Policy "><i className="uil uil-angle-right-b me-1"></i> {t('landing.footer.useful_links.cookie_policy')}</a></li>
                                            </ul>
                                        </div>

                                        {this.state.info.newsletter ?
                                            <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                                <h5 className="footer-head">Newsletter</h5>
                                                <p className="mt-4">Sign up and receive the latest tips via email.</p>
                                                <form>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="foot-subscribe mb-3">
                                                                <label className="form-label">Write your email <span className="text-danger">*</span></label>
                                                                <div className="form-icon position-relative">
                                                                    <i data-feather="mail" className="fea icon-sm icons"></i>
                                                                    <input type="email" name="email" id="emailsubscribe" className="form-control ps-5 rounded" placeholder="Your email : " required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="d-grid">
                                                                <input type="submit" id="submitsubscribe" name="send" className="btn btn-soft-primary" value="Subscribe" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer-py-30 footer-bar">
                        <div className="container text-center">
                            <div className="row align-items-center">
                                <div className="col-sm-6">
                                    <div className="text-sm-start">
                                        <p className="mb-0">{this.state.info.textbelow}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

            </>
        )
    }
}

export default withTranslation()(Footer)