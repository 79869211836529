import React from "react";
import { Navigate, NavLink, redirect } from "react-router-dom";
import { Component } from "react";
import Loginimg from "../../assets/landing/img/login.svg";
import * as yup from "yup";
import { Field, Formik } from "formik";
import pageService from "../../../api/page/page.service";
import { Button, Input, Switch, Form, Tabs, TabsProps, Skeleton } from "antd";
import footerService from "../../../api/page/footer/footer.service";

type Props = {};

type State = {
  successful: boolean;
  message: string;
  loading: boolean,
  showfacebookinfo: boolean,
  showinstagraminfo: boolean,
  showtwitterinfo: boolean,
  showlinkedininfo: boolean,
  updateId: number,
  facebookinfo: boolean,
  facebooklink: string,
  instagraminfo: boolean,
  instagramlink: string,
  twitterinfo: boolean,
  twitterlink: string,
  linkedininfo: boolean,
  linkedinlink: string,
  newsletter: boolean,
  textbelow: string

};

export default class InfoFooter extends Component<Props, State> {
  constructor(props: Props) {
    super(props);


    this.state = {
      successful: false,
      message: "",
      loading: true,
      showfacebookinfo: false,
      showinstagraminfo: false,
      showtwitterinfo: false,
      showlinkedininfo: false,
      updateId: 1,
      facebookinfo: false,
      facebooklink: '',
      instagraminfo: false,
      instagramlink: '',
      twitterinfo: false,
      twitterlink: '',
      linkedininfo: false,
      linkedinlink: '',
      newsletter: false,
      textbelow: ''
    };
  }




  renderData = async (data) => {

    const array = JSON.parse(data)
    const item = array[0]


    this.setState({

      facebookinfo: item.facebookinfo,
      facebooklink: item.facebooklink,
      instagraminfo: item.instagraminfo,
      instagramlink: item.instagramlink,
      twitterinfo: item.twitterinfo,
      twitterlink: item.twitterlink,
      linkedininfo: item.linkedininfo,
      linkedinlink: item.linkedinlink,
      newsletter: item.newsletter,
      textbelow: item.textbelow,
      showfacebookinfo: item.facebookinfo,
      showinstagraminfo: item.instagraminfo,
      showtwitterinfo: item.twitterinfo,
      showlinkedininfo: item.linkedininfo
    })

  }


  getAllSettings = async () => {

    footerService.findAll(
    ).then(
      response => {


        this.renderData(JSON.stringify(response.data))
         
        setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 500);

      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

      }

    );
  }


  updateSettings = async (values) => {

    this.setState({
      message: '',
      successful: false
    })


    footerService.update(
      this.state.updateId,
      values.facebookinfo,
      values.facebooklink,
      values.instagraminfo,
      values.instagramlink,
      values.twitterinfo,
      values.twitterlink,
      values.linkedininfo,
      values.linkedinlink,
      values.newsletter,
      values.textbelow
    ).then(
      response => {

        this.setState({
          message: 'Impostazioni salvate',
          successful: true
        })

      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

      }

    );

  }

  componentDidMount() {
    this.getAllSettings()
  }


  render() {
    const { successful, message } = this.state;

    const items: TabsProps["items"] = [
      {
        key: "1",
        label: `Impostazioni`,
        children: (
          <>
            {this.state.loading ? (
              <Skeleton loading={this.state.loading} active></Skeleton>
            ) : (
              <Form
                onFinish={this.updateSettings}
                initialValues={{
                  facebookinfo: this.state.facebookinfo,
                  facebooklink: this.state.facebooklink,
                  instagraminfo: this.state.instagraminfo,
                  instagramlink: this.state.instagramlink,
                  twitterinfo: this.state.twitterinfo,
                  twitterlink: this.state.twitterlink,
                  linkedininfo: this.state.linkedininfo,
                  linkedinlink: this.state.linkedinlink,
                  newsletter: this.state.newsletter,
                  textbelow: this.state.textbelow
                }}
              >
                {message && (
                  <div className="form-group">
                    <div
                      className={
                        successful
                          ? "alert alert-success alert-dismissible fade show"
                          : "alert alert-danger alert-dismissible fade show "
                      }
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close btn-close-white"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                      <strong>
                        {successful ? "Successo - " : "Errore - "}
                      </strong>

                      {message}
                    </div>
                  </div>
                )}
                <div className="mb-3">
                  <Form.Item name="facebookinfo" label="Info facebook">
                    <Switch
                      checked={this.state.facebookinfo}
                      onChange={() => {
                        this.setState({
                          facebookinfo: !this.state.facebookinfo,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                {this.state.facebookinfo ? (
                  <div className="mb-3" style={{ width: "300px" }}>
                    <label>Link facebook</label>
                    <Form.Item
                      name="facebooklink"
                      rules={[
                        {
                          required: true,
                          message: "Inserisci un link",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="Inserisci un link" />
                    </Form.Item>
                  </div>
                ) : null}
                <div className="mb-3">
                  <Form.Item name="instagraminfo" label="Instagram link">
                    <Switch
                      checked={this.state.instagraminfo}
                      onChange={() => {
                        this.setState({
                          instagraminfo: !this.state.instagraminfo,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                {this.state.instagraminfo ? (
                  <div className="mb-3" style={{ width: "300px" }}>
                    <label>Link instagram</label>
                    <Form.Item
                      name="instagramlink"
                      rules={[
                        {
                          required: true,
                          message: "Inserisci un link",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="Inserisci un link" />
                    </Form.Item>
                  </div>
                ) : null}
                <div className="mb-3">
                  <Form.Item name="twitterinfo" label="Info twitter">
                    <Switch
                      checked={this.state.twitterinfo}
                      onChange={() => {
                        this.setState({
                          twitterinfo: !this.state.twitterinfo,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                {this.state.twitterinfo ? (
                  <div className="mb-3" style={{ width: "300px" }}>
                    <label>Link twitter</label>
                    <Form.Item
                      name="twitterlink"
                      rules={[
                        {
                          required: true,
                          message: "Inserisci un link",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="Inserisci una link" />
                    </Form.Item>
                  </div>
                ) : null}
                <div className="mb-3">
                  <Form.Item name="linkedininfo" label="Info linkedin">
                    <Switch
                      checked={this.state.linkedininfo}
                      onChange={() => {
                        this.setState({
                          linkedininfo: !this.state.linkedininfo,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                {this.state.linkedininfo ? (
                  <div className="mb-3" style={{ width: "300px" }}>
                    <label>link linkedin</label>
                    <Form.Item
                      name="linkedinlink"
                      rules={[
                        {
                          required: true,
                          message: "Inserisci un link",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input placeholder="Inserisci un link" />
                    </Form.Item>
                  </div>
                ) : null}
                <div className="mb-3">
                  <Form.Item name="newsletter" label="Abilita newsletter">
                    <Switch
                      checked={this.state.newsletter}
                      onChange={() => {
                        this.setState({
                          newsletter: !this.state.newsletter,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="mb-3" style={{ width: "300px" }}>
                  <label>Testo footer:</label>
                  <Form.Item
                    name="textbelow"
                    rules={[
                      {
                        required: true,
                        message: "Inserisci un testo",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.TextArea placeholder="Inserisci un testo" />
                  </Form.Item>
                </div>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginTop: "30px" }}
                  >
                    Salva
                  </Button>
                </Form.Item>
              </Form>
            )}
          </>
        ),
      },
    ];

    return (
      <>

        <div className="container-fluid">
          <div className="layout-specing">
            <div className="d-md-flex justify-content-between align-items-center">
              <h5 className="mb-0">Info Footer</h5>

              <nav aria-label="breadcrumb" className="d-inline-block mt-2 mt-sm-0">
                <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                  <li className="breadcrumb-item text-capitalize"><a href="index.html">Dashboard</a></li>
                  <li className="breadcrumb-item text-capitalize"><a href="#">Contenuto pagine</a></li>
                  <li className="breadcrumb-item text-capitalize active" aria-current="page">Info Footer</li>
                </ul>
              </nav>
            </div>

            <div className="row">
              <Tabs defaultActiveKey="1" items={items} />
            </div>
          </div>
        </div>
      </>
    );
  }
}
