import React from "react";
import { Component } from "react";
import * as yup from "yup";
import { ColumnsType, ColumnType, FilterConfirmProps, SorterResult, TableRowSelection } from "antd/es/table/interface";
import { Button, Input, Space, Table, TableProps, Tag } from "antd";
import { DeleteOutlined, EditOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import PreviewImage from "./element/previewImage";
import SkeletonTable, { SkeletonTableColumnsType } from "../../common/dashboard/animation/skeletonTable";
import portfolioService from "../../../api/page/portfolio/portfolio.service";

type Props = {};

type State = {
  successful: boolean;
  message: string;
  successfulAdd:boolean,
  messageAdd:string,
  successfulDelete:boolean,
  messageDelete:string,
  successfulUpdate:boolean,
  messageUpdate:string,
  projectInfo:{
    image:string,
    project_name:string,
    description:string,
    customer:string,
    category:string,
    date:string,
    website:string,
    locationinfo:boolean,
    location:string
  },
  loading:boolean,
  projects:any[],
  file:string,
  deleteId:number,
  updateId:number,
  modalAdd:boolean,
  modalUpdate:boolean,
  modalDelete:boolean,
  searchText:string;
  searchColumn:string;
  searchTimeText;
  searchTimeRange;
  searchTimeRangeColumn: string;
  selectedRowKeys: React.Key[];
  filteredInfo;
  sortedInfo;
  columnIndex;
};

export default class InfoPortfolio extends Component<Props, State> {
  searchInput: any;

  constructor(props: Props) {
    super(props);
    

    this.state = {
      successful: false,
      message: "",
      successfulAdd:false,
      messageAdd:'',
      successfulDelete:false,
      messageDelete:'',
      successfulUpdate:false,
      messageUpdate:'',
      projectInfo:{
        image:"",
        project_name:'',
        description:'',
        customer:'',
        category:'',
        date:'',
        website:'',
        locationinfo:false,
        location:''
      },
      loading:true,
      projects:[],
      file:'',
      deleteId: 0,
      updateId: 0,
      modalAdd:false,
      modalUpdate:false,
      modalDelete:false,
      searchText: "",
      searchColumn: "",
      searchTimeText: null,
      searchTimeRange: null,
      searchTimeRangeColumn: "",
      selectedRowKeys: [],
      filteredInfo: null,
      sortedInfo: null,
      columnIndex: null,
    };
  }


  
  setDeleteId = (id) =>{
    this.setState({
      deleteId: id
    })
  }

  setUpdateId = (id) =>{
    this.setState({
      updateId: id
    })
  }


  setProjectInfo = (record) =>{
    this.setState({
      projectInfo:{
        image:'',
        project_name:record.project_name,
        description:record.description,
        customer:record.customer,
        category:record.category,
        date:record.date,
        website:record.website,
        locationinfo:record.locationinfo,
        location:record.location

      }
    })
  }



  setModalAdd = (state) =>{
    this.setState({
      modalAdd:state
    })
  }

  setModalUpdate = (state) =>{
    this.setState({
      modalUpdate:state
    })
  }

  setModalDelete = (state) =>{
    this.setState({
      modalDelete:state
    })
  }


  validationSchemaAdd() {
    const supported_formats = ["image/jpg" , "image/jpeg" , "image/png" , "image/svg" , "image/ips" , "image/psd" , "image/ai"]
    return yup.object().shape({
      image: yup.mixed().nullable().required("inserisci un file di immagine").test("type","Il file caricato non è un'immaggine", (values) => !values|| (values && supported_formats.includes(values['type']))),
      project_name: yup.string().required("inserisci un nome"),
      description: yup.string().required("inserisci una descrizione"),
      customer: yup.string().required("inserisci il cliente"),
      category: yup.string().required("inserisci una categoria"),
      date: yup.string().required("inserisci una data"),
      website: yup.string().required("inserisci un sito web"),
      location: yup.string().required("inserisci una località"),
    });
  }

  validationSchemaUpdate() {
    const supported_formats = ["image/jpg" , "image/jpeg" , "image/png" , "image/svg" , "image/ips" , "image/psd" , "image/ai"]
    return yup.object().shape({
      image: yup.mixed().nullable().required("inserisci un file di immagine").test("type","Il file caricato non è un'immaggine", (values) => !values|| (values && supported_formats.includes(values['type']))),
      project_name: yup.string().required("inserisci un nome"),
      description: yup.string().required("inserisci una descrizione"),
      customer: yup.string().required("inserisci il cliente"),
      category: yup.string().required("inserisci una categoria"),
      date: yup.string().required("inserisci una data"),
      website: yup.string().required("inserisci un sito web"),
      location: yup.string().required("inserisci una località"),
    });
  }

  getAllProjects = async() =>{

    portfolioService.findAll(
      ).then(
        response => {
  

         this.setState({
           projects:response.data,
         })

         setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 500);

          
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
      }
  
      );
  }

  getImageProject = async(id)=>{

    this.setState({
      file:''
    })


    portfolioService.getImage(
      id
      ).then(
        response => {

         this.setState({
           file:response.data
         })

        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
      }
  
      );

  }

  addProject = async(formValue:{image,project_name:string,description:string,customer:string,category:string,date:string,website:string,locationinfo:boolean,location:string})  =>{

    const { 
      image,
      project_name,
      description,
      customer,
      category,
      date,
      website,
      locationinfo,
      location
       } = formValue

       this.setState({
        messageAdd:'',
        successfulAdd:false
      })
       
      portfolioService.create(
         project_name,
         description,
         customer,
         category,
         date,
         website,
         locationinfo,
         location
        ).then(
          response => {
  
            portfolioService.createImage(
              image,
              response.data.id
             ).then(
               response => {
         
                 this.setState({
                   successfulAdd:true
                 })
     
                 this.getAllProjects()
     
                 this.setModalAdd(false)
                 
               },
               error => {
                 const resMessage =
                   (error.response &&
                     error.response.data &&
                     error.response.data.message) ||
                   error.message ||
                   error.toString();
         
             }
         
             );
            
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
    
        }
    
        )


  }

  
  updateProject = async(formValue:{image,project_name:string,description:string,customer:string,category:string,date:string,website:string,locationinfo:boolean,location:string})  =>{

    const { 
      image,
      project_name,
      description,
      customer,
      category,
      date,
      website,
      locationinfo,
      location
       } = formValue

       this.setState({
        messageUpdate:'',
        successfulUpdate:false
      })
       
      portfolioService.update(
        this.state.updateId,
         project_name,
         description,
         customer,
         category,
         date,
         website,
         locationinfo,
         location
        ).then(
          response => {
  
            portfolioService.updateImage(
              image,
              this.state.updateId,
             ).then(
               response => {
         
                 this.setState({
                   successfulUpdate:true
                 })
     
                 this.getAllProjects()
     
                 this.setModalUpdate(false)
                 
               },
               error => {
                 const resMessage =
                   (error.response &&
                     error.response.data &&
                     error.response.data.message) ||
                   error.message ||
                   error.toString();
         
             }
         
             );
            
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
    
        }
    
        )


  }

  deleteProject = async() => {


    this.setState({
      messageDelete:'',
      successfulDelete:false
    })

        
          portfolioService.delete(
            this.state.deleteId
           ).then(
             response => {
       
               this.setState({
                 successfulDelete:true
               })
   
               this.getAllProjects()
   
               this.setModalDelete(false)
               
             },
             error => {
               const resMessage =
                 (error.response &&
                   error.response.data &&
                   error.response.data.message) ||
                 error.message ||
                 error.toString();
       
           }
       
           );
          
      
  }



  componentDidMount(){
    this.getAllProjects()
  }

  render(){


    const {
      successful,
      message,
      successfulAdd,
      messageAdd,
      successfulUpdate,
      messageUpdate,
      selectedRowKeys,
      filteredInfo,
      sortedInfo,
    } = this.state;

    const initialValuesAdd = {
      image:"",
      project_name:'',
      description: '',
      customer: '',
      category:'',
      date:'',
      website:'',
      locationinfo:false,
      location:''
    };

    const initialValuesUpdate = {
      image:this.state.file,
      project_name:this.state.projectInfo.project_name,
      description: this.state.projectInfo.description,
      customer: this.state.projectInfo.customer,
      category:this.state.projectInfo.category,
      date:this.state.projectInfo.date,
      website:this.state.projectInfo.website,
      locationinfo:this.state.projectInfo.locationinfo,
      location:this.state.projectInfo.location,
    };


    interface DataType {
      key: string;
      id: number;
      project_name:string,
      description:string,
      customer:string,
      category:string,
      date:string,
      website:string,
      locationinfo:boolean,
      locationstatus:string,
      location:string
      projectss: any[];
    }

    type DataIndex = keyof DataType;

    const handleChange: TableProps<DataType>["onChange"] = (
      pagination,
      filters,
      sorter
    ) => {
      //console.log('Various parameters', pagination, filters, sorter)
      this.setState({
        filteredInfo: filters,
        sortedInfo: sorter as SorterResult<DataType>,
      });
    };

    const clearAll = () => {
      this.setState({ filteredInfo: {}, sortedInfo: {} });
    };

    const handleSearch = (
      selectedKeys: string[],
      confirm: (param?: FilterConfirmProps) => void,
      dataIndex: DataIndex
    ) => {
      confirm();
      this.setState({ searchText: selectedKeys[0], searchColumn: dataIndex });
    };

    const handleReset = (clearFilters: () => void) => {
      clearFilters();
      this.setState({ searchText: "" });
    };

    const getColumnSearchProps = (dataIndex): ColumnType<DataType> => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={this.searchInput}
            placeholder={`Cerca ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Cerca
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Resetta
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                this.setState({
                  searchText: (selectedKeys as string[])[0],
                  searchColumn: dataIndex,
                });
              }}
            >
              Filtra
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              Chiudi
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filteredValue: filteredInfo != null ? filteredInfo.body || null : null,
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => this.searchInput.current?.select(), 100);
        }
      },
      sortOrder:
        sortedInfo != null
          ? sortedInfo.columnKey === dataIndex
            ? sortedInfo.order
            : null
          : null,
      render: (text) =>
        this.state.searchColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    });

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
      //console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      
    };

    const rowSelection: TableRowSelection<DataType> = {
      selectedRowKeys,
      onChange: onSelectChange,
      selections: [
        Table.SELECTION_ALL,
        Table.SELECTION_INVERT,
        Table.SELECTION_NONE,
      ],
    };


    const columns: ColumnsType<DataType> = [
      {
        key: "project_name",
        title: "Nome progetto",
        dataIndex: ['project_name'],
        align: "center",
        responsive: ["md", "sm", "xs"],
        sorter: (a, b) => a.project_name.length - b.project_name.length,
        sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "project_name"  ? sortedInfo.order : null : null,
      },
      {
        key: "description",
        title: "Descrizione",
        dataIndex: ['description'],
        align: "center",
        responsive: ["md", "sm", "xs"],
        sorter: (a, b) => a.description.length - b.description.length,
        sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "description"  ? sortedInfo.order : null : null,
      },
      {
        key: "customer",
        title: "Cliente",
        dataIndex: ['customer'],
        align: "center",
        responsive: ["md", "sm", "xs"],
        sorter: (a, b) => a.customer.length - b.customer.length,
        sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "customer"  ? sortedInfo.order : null : null,
      },
      {
        key:"category",
        title: "Categoria",
        dataIndex: ['category'],
        align: "center",
        responsive: ["md", "sm", "xs"],
        sorter: (a, b) => a.category.length - b.category.length,
        sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "category"  ? sortedInfo.order : null : null,
      },
      {
        key:"date",
        title: "Data",
        dataIndex: ['date'],
        align: "center",
        responsive: ["md", "sm", "xs"],
        sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
        sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "date"  ? sortedInfo.order : null : null,
      },
      {
        key:"website",
        title: "Sito web",
        dataIndex: ['website'],
        align: "center",
        responsive: ["md", "sm", "xs"],
        sorter: (a, b) => a.website.length - b.website.length,
        sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "website"  ? sortedInfo.order : null : null,
      },
      {
        key:"locationnfo",
        title: "Info locazione",
        dataIndex: ['linkedininfo'],
        align: "center",
        responsive: ["md", "sm", "xs"],
        render: (_, record) => {
          
          var color: string = ''

          if(record.locationinfo){
            record.locationstatus = 'Attivo'
            color = 'green'
          }else{
            record.locationstatus = 'Disattivo'
            color = 'red'
          }

          return(
            <>
             <Tag color={color}>{record.locationstatus}</Tag>
            </>
          )
        }
      },
      {
        key:"location",
        title: "Locazione",
        dataIndex: ['location'],
        align: "center",
        responsive: ["md", "sm", "xs"],
        sorter: (a, b) => a.location.length - b.location.length,
        sortOrder: sortedInfo != null  ? sortedInfo.columnKey === "location"  ? sortedInfo.order : null : null,
      },
      {
        title: "Azione",
        dataIndex: "azione",
        align: "center",
        responsive: ["md", "sm", "xs"],
        render: (_, record) => {
          return <>
          <Button
          onClick={() => [
            this.setModalUpdate(true),
            this.setUpdateId(record.id),
            this.setProjectInfo(record),
            this.getImageProject(record.id)
          ]}
          type="primary"
          style={{
            width: "40px",
            height: "40px",
            alignContent: "center",
          }}
          icon={<EditOutlined />}
        />
        <Button
          onClick={() => [
            this.setModalDelete(true),
            this.setDeleteId(record.id),
          ]}
          danger
          type="primary"
          style={{
            width: "40px",
            height: "40px",
            marginLeft: "20px",
            alignContent: "center",
          }}
          icon={<DeleteOutlined />}
          />
        </>;
        },
      },
    ];


    return (

     <>
            
            <div className="container-fluid">
          <div className="layout-specing">
            <div className="d-md-flex justify-content-between align-items-center">
              <h5 className="mb-0">Info Portfolio</h5>

              <nav aria-label="breadcrumb" className="d-inline-block">
                <ul className="breadcrumb bg-transparent rounded mb-0 p-0">
                  <li className="breadcrumb-item text-capitalize">
                    <a href="index.html">Contenuto pagine</a>
                  </li>
                  <li
                    className="breadcrumb-item text-capitalize active"
                    aria-current="page"
                  >
                    Info Portfolio
                  </li>
                </ul>
              </nav>
            </div>

            <div className="row">
              <div className="col-12 mt-4"></div>
            </div>

            <div className="row text-center">

            <Space style={{ marginBottom: 16 }}>
              <div className="mobile-sm">
                <Button onClick={clearAll}>
                  Resetta ordinamenti,filtri e ricerca
                </Button>
              </div>
              <div className="mobile-sm">
                <Button type="primary" onClick={() => this.setModalAdd(true)}>
                  Aggiungi progetto
                </Button>
              </div>
          </Space>

              <SkeletonTable
                loading={this.state.loading}
                columns={columns as SkeletonTableColumnsType[]}
              >
                <Table
                  columns={columns}
                  dataSource={this.state.projects}
                  onChange={handleChange}
                  rowKey={(record) => record.id}
                  //rowSelection={rowSelection}
                  pagination={{
                    pageSize: 10,
                  }}
                  scroll={{ x: 600 }}
                />
              </SkeletonTable>
            </div>


     


           <Modal
             show={this.state.modalDelete}
             onHide={() => this.setModalDelete(false)}
           >
          <Modal.Header
            className="modal-header modal-colored-header bg-danger text-white"
          >
            <h5 className="modal-title" id="danger-header-modalLabel">
              Elimina progetto
            </h5>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <p>Sei sicuro di voler eliminare questo progetto dal portfolio?</p>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <Button onClick={() => this.setModalDelete(false)}>Annulla</Button>
            <Button danger onClick={() => this.deleteProject()}>
              Elimina progetto
            </Button>
          </Modal.Footer>
         </Modal> 



            <Modal
                show={this.state.modalAdd}
                onHide={() => this.setModalAdd(false)}
                backdrop="static"
                keyboard={false}
            >
        
          
                <Formik
                    initialValues={initialValuesAdd}
                    validationSchema={this.validationSchemaAdd}
                    onSubmit={this.addProject}
                  >
      
           {formik => (
         

          <Form>
            <Modal.Header >
              <h5 className="modal-title" id="createModalLabel">
                <i className="ri-mark-pen-line align-middle me-2"></i> Aggiungi nuovo progetto
              </h5>
            </Modal.Header>
            <Modal.Body className="modal-body">
              <div className="container d-flex align-items-center justify-content-center">
                {messageAdd && (
                  <div className="form-group">
                    <div
                      className={
                        successfulAdd
                          ? "alert alert-success  text-center"
                          : "alert alert-danger text-center "
                      }
                      role="alert"
                    >
                      <strong>
                        {successfulAdd ? "Successo - " : "Errore - "}
                      </strong>
                      {messageAdd}
                    </div>
                  </div>
                )}
              </div>

                                <div className="d-grid me-md-4">
                                    <p className="text-muted">Carica un'immagine qui. Clicca sul pulsante "Carica immagine".</p>
                                    {formik.values.image != '' ? <PreviewImage file={formik.values.image}/> : null}
                                    <input type="file" id="input-file" name="image" accept="image/*"  hidden
                                    onChange={(event) => {
                                      //console.log(event.target.files)
                                      formik.setFieldValue("image", event.target.files![0] )
                                    }}
                                    className={(formik.touched.image && formik.errors.image) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.image && formik.errors.image ? (
                                        <div className="invalid-feedback">{formik.errors.image}</div>
                                    ) : null}
                                    <label className="btn-upload btn btn-primary mt-4" htmlFor="input-file">Carica immagine</label>
                                </div>

                              <div className="mb-3">
                                  <label className="form-label">
                                    Nome progetto{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="project_name"
                                      type="text"
                                      placeholder="nome progetto"
                                      className={(formik.touched.project_name && formik.errors.project_name) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.project_name && formik.errors.project_name ? (
                                        <div className="invalid-feedback">{formik.errors.project_name}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                   Descrizione{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="description"
                                      component="textarea"
                                      rows="4"
                                      placeholder="descrizione"
                                      className={(formik.touched.description && formik.errors.description) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.description && formik.errors.description ? (
                                        <div className="invalid-feedback">{formik.errors.description}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                   Cliente{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="customer"
                                      type="text"
                                      placeholder="cliente"
                                      className={(formik.touched.customer && formik.errors.customer) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.customer && formik.errors.customer ? (
                                        <div className="invalid-feedback">{formik.errors.customer}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                    Categoria{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="category"
                                      type="text"
                                      placeholder="Categoria"
                                      className={(formik.touched.category && formik.errors.category) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.category && formik.errors.category ? (
                                        <div className="invalid-feedback">{formik.errors.category}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                    Data{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="date"
                                      type="date"
                                      placeholder="data"
                                      className={(formik.touched.date && formik.errors.date) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.date && formik.errors.date ? (
                                        <div className="invalid-feedback">{formik.errors.date}</div>
                                    ) : null} 
                             </div>
                          </div>

                          <div className="mb-3">
                                  <label className="form-label">
                                    Sito web{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="website"
                                      type="text"
                                      placeholder="Sito web"
                                      className={(formik.touched.website && formik.errors.website) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.website && formik.errors.website ? (
                                        <div className="invalid-feedback">{formik.errors.website}</div>
                                    ) : null} 
                             </div>
                          </div>

                          <div style={{marginTop:'25px'}} className="form-check form-switch">
                                            <div >
                                              <Field 
                                                name="locationinfo"
                                                className="form-check-input" 
                                                type="checkbox"
                                                />
                                              </div>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Abilita info locazione</label>
                          </div>

                          <div className="col-12">
                                                <label  className="form-label">Locazione</label>
                                                <Field
                                                name="location"
                                                type="text" 
                                                placeholder="inserisci un locazione valida" 
                                                className={(formik.touched.location && formik.errors.location) ? 'form-control is-invalid' : 'form-control'} 
                                                /> 
                                              {formik.touched.location && formik.errors.location ? (
                                                   <div className="invalid-feedback">{formik.errors.location}</div>
                                                ) : null} 
                          </div>
              
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.setModalAdd(false)}>Chiudi</Button>
              
                <Button type="primary" htmlType="submit">
                  Aggiungi
                </Button>
             
            </Modal.Footer>
          </Form>
        )}
      </Formik>
        </Modal>



        <Modal
                show={this.state.modalUpdate}
                onHide={() => this.setModalUpdate(false)}
                backdrop="static"
                keyboard={false}
            >
        
          
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValuesUpdate}
                    validationSchema={this.validationSchemaUpdate}
                    onSubmit={this.updateProject}
                  >
      
           {formik => (
         

          <Form>
            <Modal.Header >
              <h5 className="modal-title" id="createModalLabel">
                <i className="ri-mark-pen-line align-middle me-2"></i> Modifica progetto
              </h5>
            </Modal.Header>
            <Modal.Body className="modal-body">
              <div className="container d-flex align-items-center justify-content-center">
                {messageUpdate && (
                  <div className="form-group">
                    <div
                      className={
                        successfulUpdate
                          ? "alert alert-success  text-center"
                          : "alert alert-danger text-center "
                      }
                      role="alert"
                    >
                      <strong>
                        {successfulUpdate ? "Successo - " : "Errore - "}
                      </strong>
                      {messageUpdate}
                    </div>
                  </div>
                )}
              </div>

                        <div className="d-grid me-md-4">
                                    <p className="text-muted">Carica un'immagine qui. Clicca sul pulsante "Carica immagine".</p>
                                    {formik.values.image != '' ? <PreviewImage file={formik.values.image}/> : null}
                                    <input type="file" id="input-file" name="image" accept="image/*"  hidden
                                    onChange={(event) => {
                                      //console.log(event.target.files)
                                      formik.setFieldValue("image", event.target.files![0] )
                                    }}
                                    className={(formik.touched.image && formik.errors.image) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.image && formik.errors.image ? (
                                        <div className="invalid-feedback">{formik.errors.image}</div>
                                    ) : null}
                                    <label className="btn-upload btn btn-primary mt-4" htmlFor="input-file">Carica immagine</label>
                                </div>

                              <div className="mb-3">
                                  <label className="form-label">
                                    Nome progetto{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="project_name"
                                      type="text"
                                      placeholder="nome progetto"
                                      className={(formik.touched.project_name && formik.errors.project_name) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.project_name && formik.errors.project_name ? (
                                        <div className="invalid-feedback">{formik.errors.project_name}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                   Descrizione{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="description"
                                      component="textarea"
                                      rows="4"
                                      placeholder="descrizione"
                                      className={(formik.touched.description && formik.errors.description) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.description && formik.errors.description ? (
                                        <div className="invalid-feedback">{formik.errors.description}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                   Cliente{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="customer"
                                      type="text"
                                      placeholder="cliente"
                                      className={(formik.touched.customer && formik.errors.customer) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.customer && formik.errors.customer ? (
                                        <div className="invalid-feedback">{formik.errors.customer}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                    Categoria{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="category"
                                      type="text"
                                      placeholder="Categoria"
                                      className={(formik.touched.category && formik.errors.category) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.category && formik.errors.category ? (
                                        <div className="invalid-feedback">{formik.errors.category}</div>
                                    ) : null} 
                             </div>
                          </div>
                          <div className="mb-3">
                                  <label className="form-label">
                                    Data{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="date"
                                      type="date"
                                      placeholder="data"
                                      className={(formik.touched.date && formik.errors.date) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.date && formik.errors.date ? (
                                        <div className="invalid-feedback">{formik.errors.date}</div>
                                    ) : null} 
                             </div>
                          </div>

                          <div className="mb-3">
                                  <label className="form-label">
                                    Sito web{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-user fea icon-sm icons"
                                    >
                                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                      <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                    <Field
                                      name="website"
                                      type="text"
                                      placeholder="Sito web"
                                      className={(formik.touched.website && formik.errors.website) ? 'form-control ps-5 is-invalid' : 'form-control ps-5'} 
                                    /> 
                                  {formik.touched.website && formik.errors.website ? (
                                        <div className="invalid-feedback">{formik.errors.website}</div>
                                    ) : null} 
                             </div>
                          </div>

                          <div style={{marginTop:'25px'}} className="form-check form-switch">
                                            <div >
                                              <Field 
                                                name="locationinfo"
                                                className="form-check-input" 
                                                type="checkbox"
                                                />
                                              </div>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Abilita info locazione</label>
                          </div>

                          <div className="col-12">
                                                <label  className="form-label">Locazione</label>
                                                <Field
                                                name="location"
                                                type="text" 
                                                placeholder="inserisci un locazione valida" 
                                                className={(formik.touched.location && formik.errors.location) ? 'form-control is-invalid' : 'form-control'} 
                                                /> 
                                              {formik.touched.location && formik.errors.location ? (
                                                   <div className="invalid-feedback">{formik.errors.location}</div>
                                                ) : null} 
                          </div>
              
              
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => [this.setModalUpdate(false)]}>Chiudi</Button>
              
                <Button type="primary" htmlType="submit">
                  Modifica
                </Button>
             
            </Modal.Footer>
          </Form>
        )}
      </Formik>
        </Modal>

          </div>
        </div>

      </>

    )
  }
}