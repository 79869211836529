import React from "react";
import jwt_decode from "jwt-decode";
import { Route, Navigate, JsonFunction,redirect,useNavigate } from "react-router-dom";
import TokenService from "../token.service";

 
  function ProtectResetPassword({ children }) {

    if(TokenService.getAccessToken()){


        return children
    
    }
   return <Navigate to={'../../login'} replace={true}/>
  }

export default ProtectResetPassword
