import React from "react";
import { Component } from "react";
import Navbar from "./navBar";
import Footer from "./footer";
import { AnimatedOutlet } from "./elements/animatedOutlet";

type Props = {};

type State = {
  
};

export default class AppLayout extends Component<Props, State> {

  constructor(props:Props) {
    super(props);

    

    this.state = {
    }
  }

  componentDidMount(){
    
  }

  render(){


    return (
      <div>
         <Navbar/>
          <AnimatedOutlet/>
         <Footer/>
      </div> 
   
    )
  }
}